#JoinTeamModal{
    padding-top: 0;
    .UserForm{
        .Button{
            display: block;
            float: left;
        }
    }
}

.JoinTeamForm{
    padding-top: 20px;
    display: flex;
    .Button{
        width: 50%;
        padding:7px;
        margin-top: 30px;
    }
}



.JoinTeamImg{
    width: 180px;
    padding-left: 30px;
}

.JoinTeamModalDesc{
    margin-top: 20px;
    font-size: 16px;
    line-height: 26px;
}

@media all and (max-width: 700px){
    .JoinTeamModalDesc {
        font-size: 15px;
    }
}
