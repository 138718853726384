.HeaderMainMobile {
    display: none;
}

.HeaderMain{
    position: absolute;
    width: 100%;
    z-index: 10000;
    top: 0;

}

/* top */

.HeaderTopLine {
    width: 100%;
    background-color: white;
}

.HeaderTopPart{
    background-color: #FFFFFF;
    align-items: center;
    display: flex;
    height: 100px;
    flex-direction: row;
    justify-content: space-around;
    max-width: 1920px;
    margin: 0 auto;
    min-width: 1000px;
    position: relative;
   
}

.HeaderTopPartMobile{
    background-color: #121212;
}
.HeaderTopPartMobile .LanguageSwitch a{
    color:white!important;
}

.HeaderTopPart .SocialBlock{
    display: flex;
    margin-left: 50px;
    width: 400px;
}
.HeaderTopPart .SocialBlock .LinkIcons{
    display: flex;
    align-items:center;
    flex: 1 0 25%;
}

.HeaderTopPart .LogoLine {
    width: 100%;
    max-width: 1920px;
    margin: 0 auto;
    padding: 0 20px;
}

.HeaderTopPart .Logo{

}

.HeaderTopPart .Logo img{
    padding-top: 10px;
    width: 350px;
}


.HeaderTopPart .LanguageSwitch{

    display: flex;
    align-items: center;
    margin-right: 12px;
    justify-content: flex-end;
    width: 150px;
    align-self: self-start;
    margin-top: 9px;
}

.HeaderTopPart .PoweredBy{
    cursor: pointer;
}

.HeaderTopPart .LanguageSwitch > a{
    color:black;
   padding: 5px;
}

.HeaderTopPart .LanguageSwitch .Current{
   font-weight: bolder;
}

.HeaderTopPart .SocialBlock .FindMyDealButton {
    vertical-align: middle;
    display: flex;
    align-items: center;
}

.HeaderTopPart .SocialBlock .Button{
    background-color:#D19F46;
    border: solid 1px #D19F46;
    border-radius: 23px;
    padding: 5px 30px 5px 30px;
    font-weight: bolder;
    font-size: 17px;
    color: white;
}

.SocialBlock .LinkIcons > svg{
    margin: 15px;
}

.HeaderTopPart .SocialLinks{
    display: flex;
    margin-top: 14px;
    justify-content: center;
    align-self: flex-start;
}


.HeaderTopPart .SocialLinks .SocialItem img{
        width: 19px;
        height: 20px;
        margin: 0;
}

.HeaderTopPart .SocialLinks .SocialItem .svgIcon{
    filter:  invert(0%) sepia(6%) saturate(2617%) hue-rotate(37deg) brightness(0%) contrast(103%);
}




/* bottom */

 .HeaderBottomPart{
    height: 30px;
    display: grid;
    color: white;
    background-color:#121212;
}

.HeaderBottomPart .MenuHolder{
    max-width: 1920px;
    width: 100%;
    margin: 0 auto;
}

.HeaderBottomPart .Menu{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: right;
    padding: 0;
    width: max-content;
    float: right;
}

.HeaderBottomPart .Menu >div,
.HeaderBottomPart .Menu >a
{
    font-weight: 500;
    font-size: 16px;
    height: 30px;
    display: block;
    padding: 0 15px 0 15px;
    cursor: pointer;
    color: white;
    vertical-align: middle;
    line-height: 30px;
    font-family: gravesend-sans, sans-serif;
}

.HeaderBottomPart .Menu .Separator{
    height: 10px;
    /*background-color: #4A4A4A;*/
    /*border-left: solid 1px #4A4A4A;*/
    border-right: solid 1px #4A4A4A;
}

.HeaderBottomPart .Menu >div:hover,
.HeaderBottomPart .Menu >a:hover
{
 background-color:#707070;
 color:white;
}


.HeaderTopPart .PoweredBy {
    position: absolute;
    right: 3px;
    bottom: 0;
    font-size: 15px;
    text-align: right;
    padding: 0 10px 8px 0;
}
#DynamicHeader .DynamicDiv .Menu .Item, #DynamicHeader .DynamicDiv .Menu a {
    padding: 0 20px 0 20px;
}

/* Mobile */

.HeaderMainMobile {
    position: fixed;
    width: 100%;
    z-index: 10000;
    background-color: white;

}

.HeaderMainMobile .HeaderTop {
    display: flex;
    flex-direction: row;
    width: 100%;
    justify-content: space-between;
    box-shadow: 0px 10px 13px -7px #c3caca, -3px 23px 25px 1px rgb(0 0 0 / 0%);

}


.HeaderMainMobile .HeaderTopPart .LanguageSwitch {
    width: auto;
}

.HeaderMainMobile .HeaderTopPart {
    align-items: center;
    display: flex;
    height: 58px;
    flex-direction: row;
    justify-content: flex-end;
    max-width: inherit;
    margin: 0 auto;
    min-width: inherit;
}

.HeaderMainMobile .HeaderTopPart .Button{
    background-color:#D19F46;
    border: solid 1px #D19F46;
    border-radius: 23px;
    padding: 5px 20px 5px 20px;
    font-weight: bolder;
    font-size: 13px;
    color: white;
}

.HeaderMainMobile .HeaderTop .Logo img {
    height: auto;
    margin: 16px 0 16px 20px;
    max-width: 250px;
}


.HeaderMainMobile .HeaderTop .MenuButton {
    width: 83px;
    background-color: #121212;
    color: white;
    height: 83px;
    text-align: center;
    line-height: 83px;
    cursor: pointer;
    font-size: 16px;
    font-family: gravesend-sans, sans-serif;
    font-weight: 700;
    text-transform: uppercase;
}
.HeaderMainMobile .HeaderTop .MenuButtonActive{
    background-color: #4A4A4A;
}

.HeaderMainMobile .MobileMenu {
    background-color: #121212;
    color: white;
}

.HeaderMainMobile .MobileMenu .LanguageSwitch {
    float: right;
}


.MobileMenu .Menu{
    display: flex;
    flex-direction: column;
    align-items:stretch;
    justify-content: center;
    padding: 0;
}

.MobileMenu .Menu .Item{
    color: white;
    font-size: 23px;
    height: 100%;
    padding: 12px;
    cursor: pointer;
    text-align: center;
}

.MobileMenu .Menu .Separator{
    width: 100%;
    border-bottom: 1px solid #707070;
}

.MobileMenu .Menu >div:hover{
    background-color:#4A4A4A;
    color:white;
}

.MainPage .Slogan .SloganPart1 {
    display: flex;
    overflow: hidden;
    padding-right: 370px;
    line-height: 80px;
}

.MainPage .Slogan .SloganPart2 {
    display: flex;
    overflow: hidden;
    padding-left: 370px;
    line-height: 80px;
}


@media all and (max-width: 1000px) {

    #root {
        padding-top: 0!important;
    }

    .HeaderTopPart .Logo img{
        width: 300px;
    }

    .HeaderMain {
        display: none;
    }
    .HeaderMainMobile {
        display: block;
    }
   
    .HeaderTopPart .SocialLinks .SocialItem .svgIcon{
        width: 14px;
        filter: brightness(0) saturate(100%) invert(100%) sepia(100%) saturate(0%) hue-rotate(174deg) brightness(102%) contrast(102%);
    }
}

@media all and (max-width: 1300px) {

    /*.HeaderTopPart .Logo img{*/
    /*    width: 300px;*/
    /*}*/

    .HeaderTopPart .SocialBlock{
        margin-left: 10px;
    }

    .HeaderTopPart .LanguageSwitch{
        margin-right: 10px;
    }

    .HeaderTopPart .SocialBlock .Button {
        padding: 5px 10px 5px 10px;
        font-size: 13px;
    }
}


.CurrentMenuItem{
    background-color:#4A4A4A;
}

#DynamicHeader{
    width: 100%;
    position: fixed;
    align-items:center;
    top:0px;
    height: 40px;
    animation: show 0.5s 1;
    transition: 1s;
    right: 0;
}

@keyframes show{
    0%{ top:-60px; }
    100% { top:0px; }
   }

#DynamicHeader .DynamicDiv{
    max-width: 1400px;
    width: 100%;
    margin: 0 auto;
    display: flex;
    justify-content: space-between;
    align-items:center;
    padding: 1px 0;
}

#DynamicHeader .DynamicDiv .LogoImg{
    width: 200px;
    height: auto;
}

#DynamicHeader .LanguageSwitch{
    display: flex;
    align-items: center;
    margin-right: 50px;
    justify-content: flex-end;
    width: 400px;
    flex: 0 0 0;
    margin-left: 20px;
}


#DynamicHeader .LanguageSwitch > a{
    color: white;
   padding: 5px;
}

#DynamicHeader .LanguageSwitch .Current{
   font-weight: bolder;
}

#DynamicHeader .Logo img{
    width: 250px;
    margin-left: 20px;
}


@media all and (max-width: 1540px){

    #DynamicHeader .LanguageSwitch {
        display: none;
    }

    #DynamicHeader  .Logo {
        padding-right: 100px;
    }

}

@media all and (max-width: 1240px){

    #DynamicHeader .LanguageSwitch {
        display: none;
    }

    #DynamicHeader  .Logo {
        padding-right: 10px;
    }

    /* .MainPage .MainPageVideoDiv .Slogan {
        font-size: 45px;
    } */

    .MainPage .Slogan .SloganPart1 {
    padding-right: 370px;
    line-height: 80px;
    }

.MainPage .Slogan .SloganPart2 {
    padding-left: 370px;
    line-height: 80px;
}


    .MainPageVideoDiv .SloganSmall {
        display: none;
    }

}

@media all and (max-width: 1000px){

    .MainPage {
        padding-top: 83px!important;
    }
}

@media all and (max-width: 950px){

    /* .MainPage .MainPageVideoDiv .Slogan {
        font-size: 45px;
    }

    .MainPage .Slogan .SloganPart1 {
        padding-right: 250px;
        line-height: 65px;
    }
    
    .MainPage .Slogan .SloganPart2 {
        padding-left: 250px;
        line-height: 65px;
    } */
    


}

@media all and (max-width: 750px){

    /* .MainPage .MainPageVideoDiv .Slogan {
        font-size: 40px;
    }

    .MainPage .Slogan .SloganPart1 {
        padding-right: 150px;
        line-height: 55px;
    }
    
    .MainPage .Slogan .SloganPart2 {
        padding-left: 150px;
        line-height: 55px;
    } */
 
}

@media all and (max-width: 700px){

    /* .MainPage .MainPageVideoDiv .Slogan {
        font-size: 35px;
    }

    .MainPage .Slogan .SloganPart1 {
        padding-right: 150px;
        line-height: 55px;
    }
    
    .MainPage .Slogan .SloganPart2 {
        padding-left: 150px;
        line-height: 55px;
    } */
 
}

@media all and (max-width: 550px){

    /* .MainPage .MainPageVideoDiv .Slogan {
        font-size: 30px;
    }

    .MainPage .Slogan .SloganPart1 {
        padding-right: 150px;
        line-height: 55px;
    }
    
    .MainPage .Slogan .SloganPart2 {
        padding-left: 150px;
        line-height: 55px;
    }
  */
}

@media all and (max-width: 500px){

    /* .MainPage .MainPageVideoDiv .Slogan {
        font-size: 25px;
    }

    .MainPage .Slogan .SloganPart1 {
        padding-left: 40px;
        line-height: 45px;
        justify-content: right;
    }
    
    .MainPage .Slogan .SloganPart2 {
        padding-left: 140px;
        line-height:45px;
        justify-content: right;
    }
  */
}



@media all and (max-width: 450px){

    /* .MainPage .MainPageVideoDiv .Slogan {
        font-size: 20px;
    }

    .MainPage .Slogan .SloganPart1 {
        padding-right: 70px;
        line-height: 30px;
    }
    
    .MainPage .Slogan .SloganPart2 {
        padding-left: 130px;
        line-height: 30px;
    } */
 
}

@media all and (max-width: 1140px){

    #DynamicHeader .HeaderBottomPart .Menu >div, #DynamicHeader .HeaderBottomPart .Menu >a {
        padding: 0 10px 0 10px;

    }



}