.JoinTeamForm .TopPartForm {
  display: flex;
  padding: 0 2px;
}
#ButtonLight {
  background-color: white;
  color: #D19F46;
  border: 1px solid #D19F46;
}
.CustomOption {
  text-align: left;
  font-size: 12px;
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-top: 3px;
}
.CustomOption .Image {
  width: 20px;
  margin-right: 10px;
}
.CustomInput {
  padding: 0 5px 0 5px;
  max-height: 30px;
  display: inline-flex;
  font-size: 12px;
  flex-direction: row;
  align-items: center;
}
.CustomInput .Select {
  min-width: 53px;
  border: none;
}
.CustomInput .Select svg {
  margin-top: -4px;
}
.CustomInput .Input {
  border: 0;
  margin-right: 5px;
  font-size: 12px;
}
.CustomInput .Input::placeholder {
  font-size: 12px;
}
.CustomInput .Input:focus {
  border: none;
  box-shadow: none;
}
.CustomInput .CountryNumber {
  margin: auto;
  width: 21px;
  min-width: 21px;
  margin-right: 10px;
  font-size: 12px;
  font-weight: normal;
}
