.MobileMenu .Item {
    text-transform: uppercase;
}


#MobileNeighborhoodsItems:hover {
    background-color: #292929;
}

#MobileNeighborhoodsItems {
    background-color: #292929;
    padding: 10px;
    display: flex;
    flex-wrap: wrap;
}

#MobileNeighborhoodsItems .Item{
    color: white;
    font-size: 17px;
    display: block;
    padding: 6px 10px;
    width: 45%;
    text-align: left;

}

#MobileNeighborhoodsItems .Item:hover{
    background-color: #4A4A4A;
}


@media all and (max-width: 700px){
    #MobileNeighborhoodsItems .Item{
        width: 100%;
        text-align: center;
    }

}

