.CompanyProject {
  font-family: "Montserrat", sans-serif;
  max-width: 1920px;
  position: relative;
}
.CompanyProject .MessageModal .Schedule .ScheduleType {
  display: flex;
}
.CompanyProject .MessageModal .Schedule .ScheduleType .RadioBtn input[type=radio] {
  display: none;
}
.CompanyProject .MessageModal .Schedule .ScheduleType .RadioBtn label {
  display: inline-block;
  cursor: pointer;
  min-width: 150px;
  text-align: center;
  padding: 0px 15px;
  line-height: 34px;
  border: 1px solid #999;
  border-radius: 6px;
  user-select: none;
}
.CompanyProject .MessageModal .Schedule .ScheduleType .RadioBtn input[type=radio]:checked + label {
  background: #ffe0a6;
}
.CompanyProject .MessageModal .Schedule .ScheduleType .RadioBtn label:hover {
  color: #666;
}
.CompanyProject .TopPart {
  display: flex;
  flex-direction: row;
}
.CompanyProject .TopPart .TopSlider {
  width: 100%;
  max-width: 1700px;
  margin: auto;
  position: relative;
}
.CompanyProject .TopPart .TopSlider .ShortTermIcon img {
  bottom: 30px;
  position: absolute;
  right: 10px;
  width: 140px;
}
@media all and (max-width: 700px) {
  .CompanyProject .TopPart .TopSlider .ShortTermIcon img {
    bottom: 20px;
    width: 100px;
  }
}
@media all and (max-width: 551px) {
  .CompanyProject .TopPart .TopSlider .ShortTermIcon img {
    bottom: 15px;
  }
}
.CompanyProject .TopPart .TopSlider .MiamiFl {
  position: absolute;
  padding: 5px 10px;
  top: 0;
  right: 0;
  background-color: rgba(0, 0, 0, 0.1);
  z-index: 100;
  font-size: 20px;
  font-weight: normal;
}
.CompanyProject .TopPart .TopSlider .Item {
  width: 100%;
  height: auto;
  cursor: pointer;
}
@media all and (max-width: 1450px) {
  .CompanyProject .TopPart .TopSlider .Item {
    width: 1038px;
  }
}
@media all and (max-width: 1000px) {
  .CompanyProject .TopPart .TopSlider .Item {
    width: 100%;
  }
}
.CompanyProject .TopPart .TopSlider .TopHeader {
  position: absolute;
  z-index: 100;
  left: 5%;
  top: 20%;
  margin: 30px;
  background-color: rgba(0, 0, 0, 0.2);
  padding: 30px;
  border-radius: 5px;
}
@media all and (max-width: 1450px) {
  .CompanyProject .TopPart .TopSlider .TopHeader {
    left: 3%;
    top: 8%;
  }
}
.CompanyProject .TopPart .TopSlider .TopHeader .HOne h2 {
  font-family: Montserrat, Arial, sans-serif;
  font-size: 50px;
  margin-bottom: 10px;
  margin-top: 0;
  padding-top: 0;
}
.CompanyProject .TopPart .TopSlider .TopHeader .Slogan {
  font-size: 29px;
  font-family: Montserrat, Arial, sans-serif;
  font-weight: 400;
  margin-top: 20px;
}
@media all and (max-width: 1200px) {
  .CompanyProject .TopPart .TopSlider .TopHeader .Slogan {
    font-size: 19px;
  }
}
.CompanyProject .TopPart .TopSlider .TopHeaderMobile {
  position: absolute;
  display: flex;
  width: 100%;
  height: 100%;
  flex-direction: column;
  justify-content: space-between;
}
.CompanyProject .TopPart .TopSlider .TopHeaderMobile .Slogan {
  margin: 0px;
  display: block;
  z-index: 101;
  padding-bottom: 20px;
  background-color: rgba(0, 0, 0, 0.3);
  margin-top: 50px;
}
@media all and (max-width: 650px) {
}
.CompanyProject .TopPart .TopSlider .TopHeaderMobile .Slogan .HOne {
  z-index: 100;
}
@media all and (max-width: 1000px) {
  .CompanyProject .TopPart .TopSlider .TopHeaderMobile .Slogan .HOne h2 {
    font-size: 22px;
    line-height: 24px;
  }
}
@media all and (max-width: 550px) {
  .CompanyProject .TopPart .TopSlider .TopHeaderMobile .Slogan {
    align-self: center;
    padding-left: 30px;
    padding-right: 30px;
    margin-top: 150px;
  }
}
@media all and (max-width: 650px) {
  .CompanyProject .TopPart .TopSlider .TopHeaderMobile .StickyButtons {
    position: fixed;
    z-index: 10000;
    bottom: 1px;
  }
}
.CompanyProject .TopPart .TopSlider .TopHeaderMobile .Buttons {
  width: 100%;
  max-width: 500px;
  align-self: center;
  margin-bottom: 40px;
  display: flex;
  justify-content: space-evenly;
}
.CompanyProject .TopPart .TopSlider .TopHeaderMobile .Buttons .ContactWAButton {
  flex: 1;
  z-index: 100;
  display: flex;
  justify-content: center;
  align-items: center;
  max-width: 190px;
  padding: 5px 14px;
  font-size: 20px;
  font-family: Montserrat, Arial, sans-serif;
  font-weight: bold;
  margin: 0 5px;
}
.CompanyProject .TopPart .TopSlider .TopHeaderMobile .Buttons .ContactWAButton .ContactWA {
  width: 400px;
}
.CompanyProject .TopPart .TopSlider .TopHeaderMobile .Buttons .Button {
  white-space: nowrap;
  z-index: 100;
  display: flex;
  justify-content: center;
  align-items: center;
  max-width: 300px;
  padding: 5px 14px;
  font-size: 20px;
  font-family: Montserrat, Arial, sans-serif;
  font-weight: bold;
  margin: 0 5px;
}
@media all and (max-width: 650px) {
  .CompanyProject .TopPart .TopSlider .TopHeaderMobile .Buttons .Button {
    padding: 3px 16px;
  }
}
.CompanyProject .TopPart .TopSlider .TopHeaderMobile .Buttons .Button .Icon {
  margin-left: 20px;
  width: 20px;
}
@media all and (max-width: 650px) {
  .CompanyProject .TopPart .TopSlider .TopHeaderMobile .HOne h2 {
    font-size: 40px;
    line-height: 45px;
  }
}
.CompanyProject .TopPart .TopSlider .slick-slider .slick-list .slick-track .slick-slide > div {
  display: flex;
}
.CompanyProject .TopPart .TopSlider .slick-slider .slick-dots .slick-active .CustomDot {
  border: 1px solid white;
  background-color: #D19F46;
}
.CompanyProject .TopPart .TopSlider .slick-slider .slick-dots .CustomDot {
  width: 15px;
  height: 15px;
  border-radius: 100%;
  background-color: white;
  margin: -65px 0;
}
@media all and (max-width: 1200px) {
  .CompanyProject .TopPart .TopSlider .TopHeader {
    z-index: 100;
  }
  .CompanyProject .TopPart .TopSlider .TopHeader .HOne h2 {
    font-size: 30px;
  }
}
.CompanyProject .TopPart .Form {
  min-width: 340px;
  max-width: 340px;
  padding: 20px 40px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  -webkit-box-shadow: 0px 22px 19px -4px rgba(34, 60, 80, 0.1) inset;
  -moz-box-shadow: 0px 22px 19px -4px rgba(34, 60, 80, 0.1) inset;
  box-shadow: 0px 22px 19px -4px rgba(34, 60, 80, 0.1) inset;
}
.CompanyProject .TopPart .Form .ProjectLogo {
  max-width: 260px;
  max-height: 70px;
}
.CompanyProject .TopPart .Form .FormText {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 15px 0 0 0;
}
.CompanyProject .TopPart .Form .FormText .Urgency {
  margin-top: 30px;
  text-align: center;
  font-weight: 700;
  font-size: 16px;
}
.CompanyProject .TopPart .Form .HOne h2 {
  font-family: gravesend-sans, Arial, sans-serif;
  font-size: 24px;
  margin-bottom: 5px;
  line-height: 28px;
}
@media all and (max-width: 1000px) {
  .CompanyProject .TopPart {
    flex-direction: column;
    align-items: center;
  }
}
.CompanyProject .WhatWeOfferLine {
  background-color: #FAFAFA;
  padding: 20px 0;
  display: flex;
  justify-content: center;
}
@media all and (max-width: 800px) {
  .CompanyProject .WhatWeOfferLine {
    padding: 0 0;
  }
}
.CompanyProject .WhatWeOfferLine .WhatWeOffer {
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.15);
  background-color: white;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  max-width: 1320px;
  padding: 68px 100px;
  margin: 62px 0 79px 0;
  align-items: center;
}
@media all and (max-width: 1350px) {
  .CompanyProject .WhatWeOfferLine .WhatWeOffer {
    flex-direction: column;
    align-items: center;
  }
}
@media all and (max-width: 800px) {
  .CompanyProject .WhatWeOfferLine .WhatWeOffer {
    padding: 40px 20px;
    margin: 0;
    width: 100%;
  }
}
@media all and (max-width: 800px) {
  .CompanyProject .WhatWeOfferLine .WhatWeOffer .HOne {
    text-align: center!important;
  }
}
@media all and (max-width: 800px) {
  .CompanyProject .WhatWeOfferLine .WhatWeOffer .HOne h2 {
    font-size: 28px;
  }
}
@media all and (max-width: 1350px) {
  .CompanyProject .WhatWeOfferLine .WhatWeOffer .WhatWeOfferRt {
    margin-top: 40px;
    margin-left: 0;
  }
}
@media all and (max-width: 1350px) {
  .CompanyProject .WhatWeOfferLine .WhatWeOffer .WhatWeOfferRt .Video {
    width: 640px!important;
    height: 480px!important;
  }
}
@media all and (max-width: 800px) {
  .CompanyProject .WhatWeOfferLine .WhatWeOffer .WhatWeOfferRt .Video {
    width: 500px!important;
    height: 380px!important;
  }
}
@media all and (max-width: 560px) {
  .CompanyProject .WhatWeOfferLine .WhatWeOffer .WhatWeOfferRt .Video {
    width: 310px!important;
    height: 190px!important;
  }
}
.CompanyProject .WhatWeOfferLine .WhatWeOffer h2 {
  font-size: 40px;
  font-weight: bold;
  font-family: gravesend-sans, Arial, sans-serif;
}
.CompanyProject .WhatWeOfferLine .WhatWeOffer .WhatWeOfferLt {
  margin-right: 50px;
  min-width: 250px;
}
@media all and (max-width: 800px) {
  .CompanyProject .WhatWeOfferLine .WhatWeOffer .WhatWeOfferLt {
    margin-right: 0;
  }
}
.CompanyProject .WhatWeOfferLine .WhatWeOffer .WhatWeOfferLt .Title {
  margin-top: 0;
}
.CompanyProject .WhatWeOfferLine .WhatWeOffer .WhatWeOfferLt .Offers {
  margin-top: 50px;
}
.CompanyProject .WhatWeOfferLine .WhatWeOffer .WhatWeOfferLt .Offers .Offer {
  display: flex;
  margin: 10px 0 0 0;
  align-items: center;
}
@media all and (max-width: 800px) {
  .CompanyProject .WhatWeOfferLine .WhatWeOffer .WhatWeOfferLt .Offers .Offer .Vimg {
    width: 20px;
    height: auto;
  }
}
.CompanyProject .WhatWeOfferLine .WhatWeOffer .WhatWeOfferLt .Offers .Offer .TextBox {
  display: flex;
  flex-direction: column;
}
.CompanyProject .WhatWeOfferLine .WhatWeOffer .WhatWeOfferLt .Offers .Offer .TextBox .Text {
  font-size: 23px;
}
@media all and (max-width: 800px) {
  .CompanyProject .WhatWeOfferLine .WhatWeOffer .WhatWeOfferLt .Offers .Offer .TextBox .Text {
    font-size: 16px;
  }
}
.CompanyProject .WhatWeOfferLine .WhatWeOffer .WhatWeOfferLt .Offers .Separator {
  height: 1px;
  background-color: #E0E0E0;
  margin: 28px 0 23px 47px;
}
@media all and (max-width: 800px) {
  .CompanyProject .WhatWeOfferLine .WhatWeOffer .WhatWeOfferLt .Offers .Separator {
    margin: 15px 0 15px 0;
  }
}
.CompanyProject .WhatWeOfferLine .WhatWeOffer .WhatWeOfferRt {
  display: flex;
  align-items: center;
}
.CompanyProject .WhatWeOfferLine .WhatWeOffer .WhatWeOfferRt .Video {
  width: 350px;
}
.CompanyProject .WhatWeOfferLine .WhatWeOffer .WhatWeOfferRt #PhotoHighLight {
  width: auto;
  max-width: 600px;
}
@media all and (max-width: 700px) {
  .CompanyProject .WhatWeOfferLine .WhatWeOffer .WhatWeOfferRt #PhotoHighLight {
    width: 500px;
  }
}
@media all and (max-width: 500px) {
  .CompanyProject .WhatWeOfferLine .WhatWeOffer .WhatWeOfferRt #PhotoHighLight {
    width: 300px;
  }
}
.CompanyProject .FeaturesLine {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: #FAFAFA;
  padding-bottom: 70px;
}
@media all and (max-width: 800px) {
  .CompanyProject .FeaturesLine {
    padding: 30px 0;
  }
}
@media all and (max-width: 800px) {
  .CompanyProject .FeaturesLine .HOne {
    text-align: center!important;
  }
}
@media all and (max-width: 800px) {
  .CompanyProject .FeaturesLine .HOne h2.Title {
    font-size: 28px;
  }
}
.CompanyProject .FeaturesLine .BuildingFeatures {
  padding: 20px 140px;
  max-width: 1520px;
  width: 100%;
}
@media all and (max-width: 1000px) {
  .CompanyProject .FeaturesLine .BuildingFeatures {
    padding: 20px 20px;
  }
}
.CompanyProject .FeaturesLine .BuildingFeatures .Title {
  margin: 0 ;
  font-family: gravesend-sans, Arial, sans-serif;
  font-size: 40px;
  font-weight: bold;
}
.CompanyProject .FeaturesLine .BuildingFeatures .ReadMore {
  padding-top: 20px;
}
@media all and (max-width: 800px) {
  .CompanyProject .FeaturesLine .BuildingFeatures .ReadMore {
    text-align: center;
  }
}
.CompanyProject .FeaturesLine .BuildingFeatures .FeaturesList {
  padding-top: 40px;
  margin: 0;
  -moz-column-count: 3;
  -moz-column-gap: 20px;
  -webkit-column-count: 3;
  -webkit-column-gap: 20px;
  column-count: 3;
  column-gap: 20px;
  list-style: '—';
  color: grey;
  font-weight: 500;
}
.CompanyProject .FeaturesLine .BuildingFeatures .FeaturesList li {
  break-inside: avoid-column;
  color: black;
  padding-left: 1em;
}
.CompanyProject .FeaturesLine .BuildingFeatures .FeaturesList li:not(:first-child) {
  margin: 30px 0;
}
.CompanyProject .FeaturesLine .BuildingFeatures .FeaturesList li::marker {
  color: #B4B4B4;
}
@media all and (max-width: 800px) {
  .CompanyProject .FeaturesLine .BuildingFeatures .FeaturesList {
    -moz-column-count: 1;
    -webkit-column-count: 1;
    column-count: 1;
    margin: 0 40px;
  }
}
.CompanyProject .FeaturesLine .SeparatorBox {
  display: flex;
  align-self: stretch;
  padding: 40px 200px 20px 200px;
}
.CompanyProject .FeaturesLine .SeparatorBox .Separator {
  display: flex;
  height: 1px;
  background-color: #E0E0E0;
  width: 100%;
}
@media all and (max-width: 800px) {
  .CompanyProject .FeaturesLine .SeparatorBox {
    padding: 20px;
  }
}
.CompanyProject .SliderAndMap {
  display: flex;
  flex-direction: row;
  background-color: #FAFAFA;
  margin-top: 30px;
}
.CompanyProject .SliderAndMap .Slider {
  position: relative;
}
.CompanyProject .SliderAndMap .Slider .Counter {
  left: 10px;
  bottom: 10px;
}
.CompanyProject .SliderAndMap .Slider .slick-list {
  max-height: 400px;
}
.CompanyProject .SliderAndMap .Slider .ProjectSliderButImg {
  height: 400px;
}
.CompanyProject .SliderAndMap .Slider .ProjectCompanyPageSlider .slick-slide {
  cursor: pointer;
}
.CompanyProject .SliderAndMap .Slider .ProjectCompanyPageSlider .slick-prev,
.CompanyProject .SliderAndMap .Slider .ProjectCompanyPageSlider .slick-next {
  font-size: 15px !important;
  z-index: 100;
}
.CompanyProject .SliderAndMap .Slider .ProjectCompanyPageSlider .slick-prev:before,
.CompanyProject .SliderAndMap .Slider .ProjectCompanyPageSlider .slick-next:before {
  content: '' !important;
}
.CompanyProject .SliderAndMap .Slider .ProjectCompanyPageSlider .slick-next {
  right: 47px;
}
.CompanyProject .SliderAndMap .Slider .ProjectCompanyPageSlider .slick-prev {
  left: 17px;
}
.CompanyProject .SliderAndMap .Slider .ProjectCompanyPageSliderMobile .slick-prev {
  z-index: 1000;
  left: 5px;
}
.CompanyProject .SliderAndMap .Slider .ProjectCompanyPageSliderMobile .slick-prev img {
  width: 40px;
}
.CompanyProject .SliderAndMap .Slider .ProjectCompanyPageSliderMobile .slick-next {
  z-index: 1000;
  right: 30px;
}
.CompanyProject .SliderAndMap .Slider .ProjectCompanyPageSliderMobile .slick-next img {
  width: 40px;
}
.CompanyProject .SliderAndMap .Slider .ProjectCompanyPageSliderMobile .slick-prev:before,
.CompanyProject .SliderAndMap .Slider .ProjectCompanyPageSliderMobile .slick-next:before {
  content: '' !important;
}
.CompanyProject .SliderAndMap .Slider .ProjectCompanyPageSliderMobile .ProjectSliderItemMobile {
  width: 100%;
  height: 400px;
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  cursor: pointer;
}
.CompanyProject .SliderAndMap .Map {
  min-width: 500px;
  height: auto;
}
@media all and (max-width: 1210px) {
  .CompanyProject .SliderAndMap .Map {
    margin-top: 10px;
    min-width: auto;
  }
}
@media all and (max-width: 800px) {
  .CompanyProject .SliderAndMap .Map {
    min-width: 300px;
  }
}
@media all and (max-width: 1210px) {
  .CompanyProject .SliderAndMap {
    padding: 0 20px;
    flex-direction: column;
  }
}
@media all and (max-width: 800px) {
  .CompanyProject .SliderAndMap {
    padding: 0;
    flex-direction: column;
  }
  .CompanyProject .SliderAndMap .Slider .ProjectSliderImg {
    height: 300px;
  }
}
.CompanyProject .ButtomForm {
  background-color: #FAFAFA;
  padding: 89px 20px 70px 20px;
  align-items: center;
  display: flex;
  flex-direction: column;
}
.CompanyProject .ButtomForm .ProjectLogo {
  padding: 0 0 10px 0 ;
  max-width: 350px;
  max-height: 85px;
}
@media all and (max-width: 800px) {
  .CompanyProject .ButtomForm .ProjectLogo {
    max-width: 280px;
  }
}
.CompanyProject .ButtomForm .HOne {
  margin-bottom: 10px;
}
.CompanyProject .ButtomForm .HOne h2 {
  max-width: 750px;
  line-height: 56px;
  font-family: gravesend-sans, Arial, sans-serif;
  font-size: 40px;
  font-weight: bold;
}
@media all and (max-width: 800px) {
  .CompanyProject .ButtomForm .HOne h2 {
    font-size: 22px;
    line-height: 22px;
  }
}
.CompanyProject .ButtomForm .JoinTeamForm {
  max-width: 500px;
  display: block;
  justify-content: center;
}
.CompanyProject .ButtomForm .FormText {
  margin: 35px 0 0 0;
  text-align: center;
  font-size: 16px;
  font-weight: 700;
}
.ProjectCompanySlider .slick-prev:before,
.ProjectCompanySlider .slick-next:before {
  content: '' !important;
  width: 30px;
}
.ProjectCompanySlider .slick-next {
  right: 40px;
}
.ProjectCompanySlider .slick-next > img {
  width: 50px;
}
.ProjectCompanySlider .slick-prev {
  z-index: 1000;
  left: 10px;
}
.ProjectCompanySlider .slick-prev > img {
  width: 50px;
}
