.OurTeamPage{
    margin-bottom: 40px;
}

.OurTeamPageBody {
    background-image: url('../../elements/images/bgr-lines-1.png');
    background-position: top;
    background-repeat: repeat-y;
}

.OurTeamPageTitle{
    text-align: center;
    padding: 20px 20px 50px 20px;
}

.OurTeamPageTitle p{
    text-align: left;
}

.UserCardLast {
    background-color: white;
}

.UserCardsList {
    margin-top: 10px;
}

.OurTeamPageTopLine {
    width: 100%;
}

.OurTeamPageTopLine .OurTeamPageTop {
    margin: 0 auto;
    max-width: 940px;
    padding: 60px 40px 20px 40px;
    line-height: 24px;
    font-size: 16px;
}

.OurTeamPageTopLine .OurTeamPageSeparator {
    margin: 0 auto;
    max-width: 1420px;
    padding: 30px 40px 20px 40px;
    line-height: 24px;
    text-align: center;
    font-size: 14px;
    font-weight: bold;
}

.OurTeamPageTopLine .OurTeamPageSeparator h2{
    font-size: 14px;
    font-weight: bold;
    font-family: "Montserrat", sans-serif;
}


@media all and (max-width: 950px){

    .OurTeamPageTopLine .OurTeamPageTop {
        padding: 30px 20px 20px 20px;
    }
    .OurTeamPageTopLine .OurTeamPageSeparator {
        padding: 30px 20px 20px 20px;

    }


}