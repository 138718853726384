.StandAlonePage {
  background-color: #3d3d3d;
  color: white;
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.StandAlonePage .title {
  background-color: #121212;
  display: flex;
  justify-content: left;
  align-items: center;
  height: 100px;
  font-weight: bold;
  font-size: 22px;
}
.StandAlonePage .title .logo {
  padding: 15px;
  max-height: 70px;
  margin-right: 20px;
}
.StandAlonePage .title span {
  margin: 0 20px;
}
@media all and (max-width: 800px) {
  .StandAlonePage .title {
    justify-content: center;
  }
  .StandAlonePage .title p {
    display: none;
  }
  .StandAlonePage .title .logo {
    max-height: 70px;
    margin: 10px;
  }
}
.StandAlonePage .gallery {
  padding: 10px 0;
  background: inherit;
  align-items: center;
  text-align: center;
  flex-grow: 1;
  position: relative;
}
.StandAlonePage .gallery .TopSliderItem {
  background-color: #5d5959;
  height: 600px;
  max-width: none;
}
.StandAlonePage .gallery .SliderTopKo .TopSliderItem .TopSliderImg {
  width: auto;
}
.StandAlonePage .gallery .MediaSwitchItem .Other {
  background-color: inherit;
}
.StandAlonePage .presented {
  background-color: #121212;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 30px;
}
.StandAlonePage .presented .logo {
  margin-left: 30px;
  width: 300px;
}
@media all and (max-width: 800px) {
  .StandAlonePage .presented {
    flex-direction: column;
  }
  .StandAlonePage .presented .logo {
    margin: 0;
    padding-top: 10px;
    width: 200px;
  }
}
