.ProjectSliderItem{
    margin: 0 5px;
}

.ProjectSliderImg{
    height: 400px;
   /*width: -webkit-fill-available;*/
}

.ProjectSlider .slick-prev, .slick-next {
    font-size: 15px !important;
  }
  
 .ProjectSlider  .slick-prev:before, .ProjectSlider .slick-next:before  {
    content: '' !important;
  }

    
 .ProjectSlider .slick-next  {
    right: 47px;
  }

  .ProjectInner .ProjectSlider .ProjectSliderPage .slick-track{
    background-color: #dfdfdf;
  }

 @media all and (max-width: 1000px) {
  .ProjectSliderItem{
    height: 400px;
    display: flex;
    justify-content: center;
    align-items: center;
    align-content: center;
    position: relative;
    margin: 0;
}

.ProjectSliderImg{
  /* max-height: -webkit-fill-available;*/
  height: auto;
  max-width: 100%; 
  max-height: 400px;
    /* height: 400px; */
   /*width: -webkit-fill-available;*/
}
 }