.HeaderSelection {
  display: flex;
  flex-direction: row;
  width: 100%;
  max-width: 1920px;
  margin: 0 auto;
}
@media all and (max-width: 500px) {
  .HeaderSelection {
    flex-direction: column;
    align-items: center;
  }
}
.HeaderSelection .HeaderSelectionPoweredBy {
  padding: 10px 20px;
}
.HeaderSelection .HeaderSelectionLogo {
  flex: 1;
  padding: 0 20px;
  text-align: left;
}
.HeaderSelection .HeaderSelectionLogo a {
  border: 1px solid white;
}
.HeaderSelection .HeaderSelectionLogo a img {
  display: flex;
  max-width: 350px;
  max-height: 85px;
}
.HeaderSelection .HeaderSelectionPresented {
  padding: 20px;
  flex: 1;
  text-align: right;
  margin: auto;
}
@media all and (max-width: 500px) {
  .HeaderSelection .HeaderSelectionPresented {
    padding: 0 5px;
  }
}
.HeaderSelection .HeaderSelectionPresented .LanguageSwitch {
  color: black;
  padding: 5px;
  display: flex;
  align-items: center;
  margin-right: 12px;
  justify-content: flex-end;
  align-self: self-start;
  margin-top: 9px;
}
.HeaderSelection .HeaderSelectionPresented .LanguageSwitch > a {
  color: black;
  padding: 5px;
}
.HeaderSelection .HeaderSelectionPresented .LanguageSwitch .Current {
  font-weight: bolder;
}
.HeaderSelectionBack,
.HeaderSelectionBackMobile {
  display: flex;
  align-items: center;
}
.HeaderSelectionBack {
  position: fixed;
  right: 50px;
  bottom: 30px;
  padding-left: 40px;
  z-index: 1000;
}
.HeaderSelectionBack a,
.HeaderSelectionBackMobile a {
  display: block;
  border: 1px solid #E0E0E0;
  border-radius: 20px;
  padding: 10px;
  color: black;
  font-weight: bold;
  font-size: 14px;
  background: rgba(250, 250, 250, 0.7);
}
.HeaderSelectionBackMobile {
  display: none;
  width: fit-content;
  margin: 10px auto;
}
@media all and (max-width: 800px) {
  .HeaderSelection .HeaderSelectionLogo {
    padding-bottom: 5px;
  }
  .HeaderSelection .HeaderSelectionLogo a img {
    max-width: 250px;
  }
  .HeaderSelectionPoweredBy .PoweredBy {
    font-size: 14px;
  }
  .HeaderSelectionBackMobile {
    display: block;
  }
  .HeaderSelectionBack {
    display: none;
  }
}

@media all and (max-width: 500px) {
  .HeaderSelection .HeaderSelectionLogo a img {
    width: 100%;
  }
}
