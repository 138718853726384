.ListingPageMainInfoDiv{

}

.ListingPageMainInfoDiv > div:nth-child(2n){
    background-color: white;
}
.ListingPageMainInfoDiv > div:nth-child(2n + 1){
    background-color:#FAFAFA;
}
.ListinPageHeaderLine{
    width: 100%;
    overflow: visible;
    -webkit-box-shadow: 0px 10px 13px -7px #c3caca, -3px 23px 25px 1px rgba(0, 0, 0, 0);
    box-shadow: 0px 10px 13px -7px #c3caca, -3px 23px 25px 1px rgba(0, 0, 0, 0);
    position: relative;
}

.ListinPageHeaderLine .AgentLine{
    font-weight: bold;
    background:black;
    color: white;
    padding: 10px;
    display: flex;
    align-content: center;
    justify-content: space-around;
    align-items: center;
}
.ListinPageHeaderLine .AgentLine .AgentContact{
    display: contents;
}
.ListinPageHeaderLine .AgentLine .AgentContact a{
    color: white;
}
.ListinPageHeaderLine .AgentLine .ContactWA{
    width: 300px;
}

.SelectionListingPage {
    /* max-width: 1520px; */
}

.SelectionListingPage .TitieAndDisc{

}
.SelectionListingPage .TitieAndDisc h2{
    margin: 0 auto;
    color: black;
}

#smartluxe .SelectionListingPage {
    padding-top: 0!important;
    overflow-y: scroll;
}

.SelectionListingPage .PageBody {
    padding-left: 20px;
    padding-right: 20px;
    max-width: 1520px;
    margin: 0 auto;
}

.SelectionListingPage .PageBody .SliderTopKo .Counter{
    bottom:45px
}

.ListingPageMainInfoDiv .KeyDealBlock{
   
}

.ListingPageMainInfoDiv .DealBlockInfo {
    background-color: inherit;
    border-bottom: 1px solid #E0E0E0;
}


.SelectionListingPage .PropImages {
    max-width: 1520px;
}

.SelectionListingPage .MediaCurrentDiv {
    max-width: 1520px;
}


.SelectionListingPage .MediaSwitchItem {
    width: 180px;
    height: 125px;
}

.SelectionListingPage .MediaSwitchItem .Other {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(0, 0, 0, 0.5);
    }

.SelectionListingPage .TitieAndDisc h2 {
    /* white-space: nowrap; */
    padding-right: 20px;
    margin: 0;
    color: black;
}

.SelectionPage .SearchBlock {
    padding: 30px 20px 30px 20px;
}

.SelectionPage .SearchBlock .TopPart .Offers {
    margin-left: 0;
}

.SelectionPage .SearchBlock .TopPart .Offers .OfferMainVertical {
    margin: 0 0 15px 0;
}

@media all and (max-width: 1300px) {

    .SelectionListingPage .MainInfo {
        flex-direction: row;
    }

    .SelectionListingPage .ListingPageMainInfoDiv {
        display: flex;
        flex-direction: column;
    }

    .SelectionListingPage .ListinPageStaticHeader {
        flex-direction: column;
        height: auto;
        padding: 0;
    }

    .SelectionListingPage .ListinPageStaticHeader .Title {
        padding: 0 0;
    }

    .SelectionListingPage .ListinPageStaticHeader .MainInfo {
        flex-wrap: wrap;
        justify-content: space-around;
    }

    .SelectionListingPage .ListinPageStaticHeader .MainInfo .Separator {
        width: 1px;
    }

    .SelectionListingPage .ListinPageStaticHeader .MainInfo .Item {
        flex: 1 0 45%;
        height: 70px;
        border-bottom: 1px solid #E0E0E0;
    }

    .SelectionListingPage .FirstSep,
    .SelectionListingPage .LastSep {
        display: none;
    }

    .SelectionListingPage .ListinPageStaticHeader .Price {
        width: 100%;
    }


    .SelectionListingPage .ListinPageStaticHeader .Separator {
        font-weight: bold;
        border-left: 1px solid #E0E0E0;
    }

    .SelectionListingPage .ListinPageStaticHeader .Title {
        height: 90px;
    }

    .SelectionListingPage .ListinPageStaticHeader .TitieAndDisc {
        display: flex;
        flex-direction: column;
        justify-content: center;
    }

    .SelectionListingPage .ListinPageStaticHeader h2 {
        font-size: 18px;
        padding: 0;
        margin: 0 0 2px 0;
    }

    .SelectionListingPage .ListinPageStaticHeader .Title div {
        font-size: 14px;
    }


    /* .DealBlockInfo {
        border-top: 1px solid #E0E0E0;
        border-bottom: 1px solid #E0E0E0;
        background-color: #FAFAFA;
    }

    .DealBlockInfo .TextBlock {
        column-count: 1;
        padding: 0;
    } */

    .SelectionListingPage .KeyDealBlock {

        border-top: none;
        /* border-bottom: none;
        background-color: inherit; */
    }

    .SelectionListingPage .InfoBlock {
        padding: 20px 20px 20px 20px;
    }


    .SelectionListingPage .DealBlockProperty {
        order: 2;
        flex-direction: column;
    }

    .SelectionListingPage .KeyDealTable tbody td {
        font-size: 12px;

    }

    .SelectionListingPage .KeyDealTable thead td {
        font-size: 12px;
    }

    .SelectionListingPage .ListingPageContactForm {
        width: auto;
    }

    .SelectionListingPage .VertLine {
        padding: 17px 0 0 10px;
    }

    .SelectionListingPage .DealScoreAndContacts {
        min-width: auto;
    }

    .SelectionListingPage .checkIcon {
        width: 20px;
        height: 20px;
    }

}

@media all and (max-width:750px){
    .ListinPageHeaderLine .AgentLine .AgentContact{
        display: none;
    }
}

@media all and (max-width:600px){
    .ListinPageHeaderLine .AgentLine {
        flex-direction: column;
    }
}
/*  ListinPageHeaderLine see listing-page-style.css */