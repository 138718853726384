.ArticleInnerPage {
    background-color: #FAFAFA;
    margin-top: 130px;
}

.ArticleInnerPage .ArticleBody {
    max-width: 734px;
    background-color: white;
    border: 1px solid #FAFAFA;
    margin: 0 auto;
    padding: 50px;
    font-size: 16px;
    color: #5A5A5A;
    line-height: 24px;
}

.ArticleInnerPage .ArticleBody a {
    color: orange;
}

.ArticleInnerPage .ArtImg {
    width: 100%;
    height: auto;
    margin-bottom: 8px;
    margin-top: 50px;
}

.ArticleInnerPage .ArticleBody h1 {
    font-size: 32px;
    line-height: 42px;
    font-weight: 700;
    margin-bottom: 16px;
    padding: 0;
    color: black;
}

.ArticleInnerPage .ArticleBody h3 {
    font-size: 24px;
    line-height: 30px;
    font-weight: 700;
    margin-bottom: 16px;
    padding: 0;
    color: black;
}

.ArticleInnerPage .ArticleBody .SectionHdr{
    text-align: center;
    padding: 0;
}


.ArticleInnerPage .ArticleBody .JoinTeamForm {
    max-width: 500px;
    margin: auto;
}

.ArticleInnerPage .date {
    display: flex;
    align-items: center;
    flex-direction: row;
}

.ArticleInnerPage .date img {
    margin-right: 15px;
}

.ArticleInnerPage .ImageText {
    font-size: 14px;
    font-weight: 400;
    color: #868686;
    margin-bottom: 50px;
}


.ArticleInnerPage .FormLogo{
    max-width: 350px;
    max-height: 85px;
}

.ArticleInnerPage .firsP {
    font-weight: 700;
}
.ArticleInnerPage P {
    margin: 0 0 30px 0;
    padding: 0;
}

.ArticleInnerPage .Share {
    font-size: 24px;
    font-weight: 700;
    display: flex;
    color: black;
}

.ArticleInnerPage .Share img {
    padding: 0 5px 0 5px;
    display: block;
}

.ArticleInnerPage .Separator {
    width: 100%;
    height: 1px;
    background-color: #E0E0E0;
    margin: 44px 0;
}

.ArticleInnerPage .PageLogo .ProjectTitle{
    color: white;
}
.ArticleInnerPage .PageLogo .ProjectTitle .Title{

}

.ArticleInnerPage .SectionHdr h2{
    font-size: 35px;
    color: black;
    margin-bottom: 10px;
}

.SimilarProjects .OfferMain {
    width: 380px;
    height: 380px;
    display: flex;
    flex-direction: column;
}

.SimilarProjects .OfferMain .OfferImg {
    width: 380px;
    height: auto;
}

.MoreArticles .OfferMain {
    width: 380px;
    height: 520px;
    display: flex;
    flex-direction: column;
}

.MoreArticles .OfferMain .OfferImg {
    width: 380px;
    height: auto;
}

.SimilarProjects .OfferMain .BottomBlockOfferProject,
.MoreArticles .OfferMain .BottomBlockOfferProject
{
    display: flex;
    flex-direction: column;
    flex: 1;
}

.SimilarProjects .OfferMain .BottomBlockOfferProject .ReadMore,
.MoreArticles .OfferMain .BottomBlockOfferProject .ReadMore
{
    flex: 1;
    display: flex;
    align-items: flex-end;
    justify-content: center;
}

.SimilarProjects .OfferMain .ReadMore .Button,
.MoreArticles .OfferMain .ReadMore .Button {
    width: 100%;
}

.SimilarProjects .TopBlockOffer,
.MoreArticles .TopBlockOffer
{
    position: relative;
}

.SimilarProjects .ProjectLogo,
.MoreArticles .ProjectLogo
{
    position: absolute;
    z-index: 100;
    max-height: 50px;
    padding: 20px;
    top:0;
    left: 0;
    right: 0;
    display: flex;
    align-items: center;
    justify-content: space-around;
    background-color: white;
}

.SimilarProjects .ProjectLogo .ProjectLogoImg,
.MoreArticles .ProjectLogo .ProjectLogoImg{
    max-width: 330px;
    max-height: 80px;
}

.MoreArticles .BottomBlockOfferProject {
    padding: 15px;
}

.BottomBlockOfferProject .ArtName {
    font-size: 20px;
    font-weight: 700;
    margin-bottom: 30px;
    text-align: left;
}

.BottomBlockOfferProject .ArtP {
    text-align: left;
    font-size: 16px;
    font-weight: 400;
}

@media all and (max-width: 1000px){
    .ArticleInnerPage{
        margin-top: 84px;
    }
    

}

@media all and (max-width: 600px){
   
    .MoreArticles .OfferMain,
    .SimilarProjects .OfferMain{
        width: 330px;
    }

    .ArticleInnerPage .PageLogo .ProjectTitle .HOne h2{
        font-size: 21px;
    }
}