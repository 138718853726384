#smartluxe {
    display: flex;
    min-height: 100vh;
    flex-direction: column;
    justify-content: space-between;
}
.PageContent{
    flex: 1;
    display: flex;
    flex-direction: column;
  
}

.Selection{
    display: flex;
    justify-content: center;
    flex-direction: row;
}

.SelectionRT {
    flex:6;
    max-width: 900px;
}

.Selection::-webkit-scrollbar {
    display: none;
  }
  
  /* Hide scrollbar for IE, Edge and Firefox */
  .Selection {
    -ms-overflow-style: none;  /* IE and Edge */
    scrollbar-width: none;  /* Firefox */
  }

  .error{
      color: red;
  }