#InnerArticleCard .OfferMain .Button {
  margin-top: 10px;
}
#InnerArticleCard .OfferMain .ProjectLogo {
  background-color: rgba(255, 255, 255, 0.671);
}
#InnerArticleCard .OfferMain .OfferImgBlock {
  height: 400px;
}
#InnerArticleCard .OfferMain .TopBlockOffer {
  height: 145px;
}
