.SearchPage{
    padding-top: 138px;
}

.PageSearchLine {
    width: 100%;
}

.SearchBlock{
    padding: 50px 40px 50px 40px;
    max-width: 1520px;
    margin: 0 auto;
    display: flex;
    flex-direction: column;
}

.SearchPageHeaderLine{
    width: 100%;
    overflow: visible;
    -webkit-box-shadow: 0px 10px 13px -7px #c3caca, -3px 23px 25px 1px rgba(0,0,0,0);
    box-shadow: 0px 10px 13px -7px #c3caca, -3px 23px 25px 1px rgba(0,0,0,0);
    position: relative;
}

.SearchPageStaticHeader{
    display: flex;
    justify-content: space-between;
    max-width: 1520px;
    margin: 0 auto;
    padding: 25px 20px 25px 20px;
    font-weight: bold;
}

.ItemBedroomsBathrooms {
    display: flex;
    flex: 1;
}

.SearchItemLocationBedroomsBathrooms {
    display: flex;
    flex: 1;
    margin-right: 20px;
}

.MainMap{
    flex: 1;
    min-height: 700px;
    padding: 15px;
    box-shadow: 0 0 10px rgba(0,0,0,0.3);
}

.SearchBlock .TopPart{
    display: flex;
}

.SearchHeaderItem{
    display: flex;
    flex: 1;
    flex-direction: column;
    padding-right: 15px;
}

.PaginationDots{
    margin:auto 7px auto 0;
}
/*#SearchInput{*/
/*    width: 200px;*/
/*    margin-top: 8px;*/
/*    font-size: large;*/
/*}*/

.SearchHeaderItemPrice{
}

.SearchHeaderItemPrice .Inputs #SearchInput{
    padding-left: 20px;
}

.SearchPageStaticHeader .SearchLabel {
    display: flex;
    align-items: center;
    padding-bottom: 6px;
}
.SearchPageStaticHeader .SearchLabelFrom,
.SearchPageStaticHeader .SearchLabelTo
{
    padding-right: 5px;
}

.SearchPageStaticHeader .SearchLabelTo {
    padding-left: 5px;
}


.SearchPageStaticHeader .SearchInput {
    display: block;
    background-color: inherit;
    border: 1px solid #868686;
    font-family: inherit;
    border-radius: 7px;
    padding: 9px 5px 9px 5px;
    color: inherit;
    -webkit-appearance: none;
    flex: 1;
}

.SearchHeaderItemPrice .Inputs{
    display: flex;
    align-items:center;
    font-weight: normal;
}

.SearchHeaderItemPrice Input{
  margin: 0 10px;
}

.SearchHeaderButton{
    margin-left: 40px;
}

#SearchButton{
    padding: 5px 30px 5px 30px;
    font-weight: bold;
    margin-top: 26px;
}

.SearchInputDollar{
    position: absolute;
    left: 18px;
    top: 9px;
    font-weight: bold;
}

.InputsItem{
    width: 100%;
    display: flex;
    align-items:center;
    text-transform: lowercase;
}

.InputWsDol {
    display: flex;
    position: relative;
    flex: 1;
}

.TopPart .Offers{
    flex: 1;
    margin-left: 20px;
}

.PaginationItem{
    cursor: pointer;
    width: 20px;
    height: 20px;
    padding: 10px;
    display: flex;
    justify-content: center;
}
.PaginationDiv{
    display: flex;
    justify-content: center;
    padding-top: 20px;
}

#PaginationItemCurrent{
    color: white;
    background-color: #D19F46;
    border-radius: 19px;

}

.SearchInputRegion{
    background-color:inherit;
    font-family: inherit;
    border-radius:7px;
    border-color: black;
    color: inherit;
}

@media all and (max-width: 1200px){

    .MainMap{
        flex: none;
        min-height: 700px;
        margin: 20px 0 20px 0;
        padding: 5px;
        box-shadow: 0 0 10px rgba(0,0,0,0.3);
    }

    .SearchBlock {
        padding: 20px 10px;
    }

    .SearchHeaderItemPrice .Inputs {
        display: flex;
        align-items: flex-start;
        font-weight: normal;
        flex-direction: column;
        justify-content: stretch;
    }

    .SearchPageStaticHeader .SearchLabelFrom,
    .SearchPageStaticHeader .SearchLabelTo
    {
        width: 40px;
        padding: 0 5px 0 0;
        justify-content: flex-end;
    }

    .SearchItemLocationBedroomsBathrooms {
        flex-direction: column;
    }
    .ItemBedroomsBathrooms {
        padding-top: 20px;
    }

    .InputsItemSec {
        margin-top: 15px;
    }

    .SearchBlock .TopPart{
        flex-direction: column-reverse;
    }

    .MainMap{
        height: 400px;
    }

    .TopPart .Offers {
        margin: 0;
    }

}

@media all and (max-width: 764px){

    .SearchPageStaticHeader {
        flex-direction: column;
    }
    .SearchHeaderItemPrice {
        margin-top: 20px;
    }

    .SearchHeaderButton {
        margin: 0;
    }

    .SearchItemLocationBedroomsBathrooms {
        margin-right: 0;
    }

    .SearchHeaderItem {
        padding-right: 10px;
    }
}