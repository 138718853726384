.NeighboorSliderItem{
    margin:10px;
    /*width: 270px;*/
    position: relative;
    background-color:linear-gradient(0deg, rgba(34,193,195,0) 0%, rgba(0,0,0,0) 100%);
    min-width: 292px;
    min-height: 224px;
}

.NeighboorSliderItem >img{
    width: 100%;
  
}

.NeighboorSliderItem > div{
    position: absolute;
    bottom: 10px;
    width: 100%;
    color:white;
    font-size: 18px;
    font-weight: bold;
    font-family: "Montserrat";
}

.NeighboorSliderItem .Overlay{
    /* position: absolute;
    height: 100%;
    width: 100%;
    background:linear-gradient(#ffffff00,#ffffff00, #030303c7); */
    bottom:0;
}

#NeighboorGridItem{
    
}