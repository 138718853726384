.LobbyMenuLine {
  margin-top: 125px;
  width: 100%;
  overflow: visible;
  box-shadow: 0 10px 13px -7px #c3caca, -3px 10px 10px 1px #e9e1e1;
  position: relative;
}
.LobbyMenuLine .LobbyMenu {
  /*Styles for first menu BEGIN*/
  display: flex;
  flex-wrap: wrap;
  height: 100px;
  /* box-shadow: 0 0 10px rgb(0 0 0 / 30%); */
  margin: 0 auto;
  padding: 0 20px;
  align-items: center;
  justify-content: flex-start;
  /*Styles for first menu END*/
  /*Styles for second menu BEGIN*/
  /*Styles for second menu END*/
}
.LobbyMenuLine .LobbyMenu .ItemHeader {
  color: black;
  font-size: 18px;
  font-weight: 700;
  display: flex;
  align-items: center;
  white-space: nowrap;
}
.LobbyMenuLine .LobbyMenu .ProjectType {
  color: black;
  font-size: 18px;
  font-weight: 700;
  display: flex;
  align-items: center;
  white-space: nowrap;
}
.LobbyMenuLine .LobbyMenu .ProjectType label {
  font-size: 18px;
  font-weight: 400;
  color: #868686;
  margin-left: 20px;
  white-space: nowrap;
}
.LobbyMenuLine .LobbyMenu .Neighbourhoods {
  color: black;
  font-size: 18px;
  font-weight: 700;
  display: flex;
  align-items: center;
  white-space: nowrap;
  margin-right: 20px;
}
.LobbyMenuLine .LobbyMenu .Neighbourhoods #CategorySelector {
  margin-left: 10px;
  width: 320px;
  z-index: 1002;
}
.LobbyMenuLine .LobbyMenu .Neighbourhoods select {
  margin-left: 20px;
}
.LobbyMenuLine .LobbyMenu .LobbyMenuCheck {
  margin-right: 10px;
}
.LobbyMenuLine .LobbyMenu .LobbyMenuCheck:checked {
  background-color: #D19F46;
}
.LobbyMenuLine .LobbyMenu .MenuItems {
  display: flex;
  align-items: center;
}
@media all and (max-width: 730px) {
  .LobbyMenuLine .LobbyMenu .MenuItems {
    display: none;
  }
}
.LobbyMenuLine .LobbyMenu .MenuItems .MenuItemsSeparator {
  width: 4px;
  height: 4px;
  border-radius: 2px;
  background-color: #B4B4B4;
  margin-left: 20px;
  margin-right: 20px;
}
.LobbyMenuLine .LobbyMenu .MenuItems .MenuItem {
  font-size: 16px;
  font-weight: 700;
  text-transform: uppercase;
  color: black;
  white-space: nowrap;
  cursor: pointer;
}
.LobbyMenuLine .LobbyMenu .MenuItems .MenuItem .MenuItem:hover {
  color: #D19F46 !important;
}
.LobbyMenuLine .LobbyMenu .MenuItems .MenuItem .MenuItem:visited {
  color: black;
}
.LobbyMenuLine .LobbyMenu .MenuItems .MenuItem:hover {
  color: #D19F46 !important;
}
.LobbyMenuLine .LobbyMenu .MenuItemAnchor {
  display: none;
  font-size: 16px;
  font-weight: 700;
  text-transform: uppercase;
  white-space: nowrap;
  cursor: pointer;
}
.LobbyMenuLine .LobbyMenu .MenuItemAnchor .MenuItemAnchor:hover {
  color: #D19F46 !important;
}
.LobbyMenuLine .LobbyMenu .BackButton {
  cursor: pointer;
  display: block;
  margin-right: 60px;
  border: 1px solid #B4B4B4;
  border-radius: 20px;
  padding: 10px 28px;
  font-size: 16px;
  text-transform: uppercase;
  font-weight: 700;
  white-space: nowrap;
  color: black;
}
.LobbyMenuLine .LobbyMenu .BackButton:visited {
  color: black;
}
@media all and (max-width: 1000px) {
  .LobbyMenuLine {
    margin-top: 83px;
  }
  .LobbyMenuLine .LobbyMenu {
    height: 140px;
  }
  .LobbyMenuLine .LobbyMenu .BackButton {
    margin-right: 20px;
    font-size: 11px;
    padding: 5px 10px;
  }
  .LobbyMenuLine .LobbyMenu .MenuItems .MenuItem {
    font-size: 13px;
  }
  .LobbyMenuLine .LobbyMenu .MenuItems .MenuItemsSeparator {
    margin-right: 10px;
    margin-left: 10px;
  }
  .LobbyMenuLine .LobbyMenu .ContactWAButton .ContactWA {
    width: 300px;
  }
  .LobbyMenuLine .LobbyMenu .Button {
    padding: 5px 7px;
  }
}
@media all and (max-width: 730px) {
  .LobbyMenuLine .LobbyMenu .MenuItemAnchor {
    display: block;
  }
}
