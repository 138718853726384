.slick-slider {
  display: grid;
  margin: auto;
}
.SliderTopKo {
  position: relative;
}
.SliderTopKo .Counter {
  bottom: 10px;
  left: 10px;
}
.SliderTopKo .slick-slider .slick-prev:before,
.SliderTopKo .slick-slider .slick-next:before {
  content: none;
}
.SliderTopKo .slick-slider .slick-next {
  right: 45px;
}
.VirtualBlock {
  height: 90%;
  width: 90%;
}
.SliderTopKo {
  overflow: hidden;
  height: 600px;
}
@media all and (max-width: 950px) {
  .SliderTopKo {
    height: 500px;
  }
}
@media all and (max-width: 850px) {
  .SliderTopKo {
    height: 400px;
  }
}
@media all and (max-width: 750px) {
  .SliderTopKo {
    height: 300px;
  }
}
@media all and (max-width: 570px) {
  .SliderTopKo {
    height: 250px;
  }
}
@media all and (max-width: 450px) {
  .SliderTopKo {
    height: 190px;
  }
}
.SliderTopKo .slick-prev,
.SliderTopKo .slick-next {
  top: 300px;
}
@media all and (max-width: 850px) {
  .SliderTopKo .slick-prev,
  .SliderTopKo .slick-next {
    top: 200px;
  }
}
@media all and (max-width: 750px) {
  .SliderTopKo .slick-prev,
  .SliderTopKo .slick-next {
    top: 150px;
  }
}
@media all and (max-width: 570px) {
  .SliderTopKo .slick-prev,
  .SliderTopKo .slick-next {
    top: 125px;
  }
}
@media all and (max-width: 450px) {
  .SliderTopKo .slick-prev,
  .SliderTopKo .slick-next {
    top: 90px;
  }
}
.SliderTopKo .TopSliderItem {
  position: relative;
}
.SliderTopKo .TopSliderItem .VirtualPlay {
  position: absolute;
  left: 47%;
  width: 80px;
}
@media all and (max-width: 450px) {
  .SliderTopKo .TopSliderItem .VirtualPlay {
    left: 41%;
  }
}
.SliderTopKo .TopSliderItem .TopSliderImg {
  width: 100%;
}
.SliderTop .SliderSoldIcon {
  position: absolute;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.SliderTop .SliderSoldIcon img {
  z-index: 1000;
}
.SliderTop .TopSliderItem {
  cursor: pointer;
  overflow: hidden;
  max-width: 1134px;
  height: 600px;
  text-align: center;
  background-color: #ebebeb;
  display: flex;
  align-items: center;
}
@media all and (max-width: 850px) {
  .SliderTop .TopSliderItem {
    height: 400px;
  }
}
@media all and (max-width: 750px) {
  .SliderTop .TopSliderItem {
    height: 300px;
  }
}
@media all and (max-width: 570px) {
  .SliderTop .TopSliderItem {
    height: 250px;
  }
}
@media all and (max-width: 450px) {
  .SliderTop .TopSliderItem {
    height: 190px;
  }
}
.SliderTop .TopSliderImg {
  position: relative;
  margin: 0 auto;
  height: auto;
}
@media all and (max-width: 850px) {
  .SliderTop .TopSliderImg {
    width: 100%;
    height: auto;
  }
}
.SliderTop .slick-next {
  right: 10px;
}
.SliderTop .slick-prev {
  left: 10px;
  z-index: 100;
}
.SliderTop .SliderTop {
  /*background-color: #E0E0E0;*/
}
@media all and (max-width: 1000px) {
  .SliderNavi {
    display: none;
  }
  .TopSliderImg {
    width: 100%;
  }
  .TopSliderItem {
    height: auto;
  }
}
@media all and (max-width: 1200px) {
  .TopSliderItem {
    width: 100%;
    max-width: none;
  }
  .MediaSwitchItem {
    width: 195px!important;
    height: 145px!important;
  }
  .MediaSwitchItem .MediaImgSmall {
    width: 195px;
    height: auto;
    min-height: 145px;
  }
}
@media all and (max-width: 1100px) {
  .TopSliderItem {
    width: 100%;
    max-width: none;
  }
  .MediaSwitchItem {
    width: 185px!important;
    height: 135px!important;
  }
  .MediaSwitchItem .MediaImgSmall {
    width: 185px;
    height: auto;
    min-height: 135px;
  }
}
@media all and (max-width: 1150px) {
  .TopSliderItem {
    width: 100%;
    max-width: none;
  }
  .MediaSwitchItem {
    width: 175px!important;
    height: 135px!important;
  }
  .MediaSwitchItem .MediaImgSmall {
    width: 175px;
    height: auto;
    min-height: 135px;
  }
}
