.ConstructionLobby .GridMain .Grid {
    max-width: 800px;
}

.SoldOutIcon {
    top: 146px;
    right: 25px;
}

.OfferMain .BottomBlockOfferProject {
    display: flex;
    flex-direction: column;
    margin: 12px 23px 21px 23px;
}

.OfferMain .BottomBlockOfferProject .ProjectName {
    font-size: 14px;
    font-weight: 700;
    text-align: left;
    margin: 0;
}

.OfferMain .BottomBlockOfferProject .Separator {
    background-color: #E0E0E0;
    height: 1px;
    width: 100%;
    margin: 9px 0;
}

.OfferMain .BottomBlockOfferProject .ProjectFeature {
    display: flex;
    justify-content: space-between;
}

.OfferMain .BottomBlockOfferProject .ProjectFeatureOne {
    margin-bottom: 9px;
}

.OfferMain .BottomBlockOfferProject .ProjectFeature .Featuretitle {
    color: #5A5A5A;
    font-size: 14px;
    font-weight: 400;
    text-align: left;
}

.OfferMain .BottomBlockOfferProject .ProjectFeature .FeatureDescription {
    color: black;
    font-size: 14px;
    font-weight: 700;
    text-align: right;
}

.OfferMain .BottomBlockOfferProject  .ProjectLogo {
    max-width: 300px;
    max-height: 55px;
    width: auto;
    height: auto;
    display: inline;
    align-self: center;
}

.ConstructionLobby .PaginationDiv{
    margin-bottom: 30px;
}