
.DealScoreLine .PoweredBy {
    font-size: 10px;
    text-align: right;
    padding: 0 10px 8px 0;
    position: absolute;
    bottom: 0;
    right: 0;
}
.DealScoreLine {
    border-bottom: 1px solid #dbdbdb;
    position: relative;
    width: 100%;
    padding-top: 20px;
    padding-bottom: 50px;
    cursor: pointer;
}

.DealScoreImg {
    width: 220px;
    margin: 0 auto;
    display: block;
}



.DealScoreAtTop .DealScoreImg {
    width: 105px;
    height: 70px;
    display: block;
    margin: auto;
    padding: 10px;
}
