.NeighborhoodsModalMainCont {
    width: 100%;
}

.NeighborhoodsModalMain{
    max-width: 1000px;
    background-color: white;
    margin: 6px auto 0 auto;
    flex-wrap: wrap;
    padding: 25px;
    z-index: 1000;
    display: flex;
    flex-direction: row;
    flex-flow: row wrap;
}



.NeighborhoodsModalMain .Item{
    font-weight: 300;
    padding: 7px 15px;
    display: flex;
    font-family: gravesend-sans, sans-serif;
    font-size: 16px;
    color: black;
    width: 21%;
    text-transform: uppercase;
}

.NeighborhoodsModalMain .Item:hover{
    background-color: whitesmoke;
}
