.Footer {
  width: 100%;
  background-color: #121212;
  padding-top: 30px;
}
.Footer h2,
.Footer h3 {
  font-size: 20px;
}
.Footer .LinkedInIcon {
  filter: brightness(0) saturate(100%) invert(63%) sepia(66%) saturate(380%) hue-rotate(360deg) brightness(91%) contrast(91%);
}
.Footer .FooterProjectMain {
  background-color: #121212;
  text-align: center;
  display: flex;
  flex-direction: row;
  justify-content: center;
  color: #707070;
  max-width: 1660px;
  margin: 0 auto;
  flex-flow: row wrap;
}
.Footer .FooterProjectMain .FooterBlock {
  padding: 0 60px 0 0;
  padding-bottom: 20px;
}
@media all and (max-width: 1100px) {
  .Footer .FooterProjectMain .FooterBlock {
    padding: 0 60px;
  }
}
.Footer .FooterProjectMain .ProjectLogoBlock {
  display: flex;
  justify-content: space-evenly;
  flex-direction: row;
  align-items: baseline;
  width: 66%;
}
.Footer .FooterProjectMain .ProjectLogoBlock > a {
  flex: 2;
}
.Footer .FooterProjectMain .ProjectLogoBlock .CompanyBlock {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  flex: 3;
  height: 100%;
}
.Footer .FooterProjectMain .ProjectLogoBlock .CompanyBlock > a {
  flex: 1;
}
.Footer .FooterProjectMain .ProjectLogoBlock .CompanyBlock .ComplianceItems {
  flex: 3;
  text-align: left;
}
@media all and (max-width: 1200px) {
  .Footer .FooterProjectMain .ProjectLogoBlock .ProjectLogo {
    width: 200px;
  }
}
@media all and (max-width: 1100px) {
  .Footer .FooterProjectMain .ProjectLogoBlock {
    margin: 0 auto;
    flex-direction: column;
    padding-bottom: 30px;
    align-items: center;
  }
  .Footer .FooterProjectMain .ProjectLogoBlock .ProjectLogo {
    margin: 20px 0;
    width: 200px;
  }
}
.Footer .FooterProjectMain .ProjectLogo {
  width: 300px;
}
@media all and (max-width: 1100px) {
  .Footer .FooterProjectMain .FooterBlock {
    border-top: 1px solid #4A4A4A;
    width: 100%;
  }
}
.FooterMain {
  background-color: #121212;
  padding: 0px 130px 30px 130px;
  text-align: center;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding-top: 30px;
  color: #707070;
  max-width: 1660px;
  margin: 0 auto;
  flex-flow: row wrap;
  /* min-width: 1280px; */
}
.FooterMain .LogosBlock {
  display: block;
  margin: auto;
}
.FooterMain .LogosBlock .FooterLogoImg {
  padding: 20px;
}
.FooterBlock {
  width: 23%;
  text-align: left;
}
.FooterBlock .FourthBlockText a {
  display: block;
  margin: 10px 0;
  color: inherit;
  font-family: inherit;
  font-weight: inherit;
}
.FooterBlock .FourthBlockText a:hover {
  color: white;
}
.FooterLogoImg {
  width: 250px;
}
.FooterIcon {
  width: 20px;
}
.FooterContacts {
  text-align: left;
  text-decoration: none;
  color: inherit;
  margin-left: 20px;
}
.FirstBlock > div {
  display: flex;
  margin: 30px 0 0 0;
  flex-direction: row;
  align-items: flex-start;
  align-items: center;
}
.FirstBlock .LastColumn {
  margin: 0;
  flex-direction: column;
  align-items: normal;
}
.FirstBlock .LastColumn > div {
  margin: 30px 0 0 0;
  display: flex;
}
.FooterQuickLinks {
  display: flex;
  flex-direction: column;
}
.FooterBlockTitle {
  color: white;
  margin: 0 0 10px 0;
  text-transform: uppercase;
}
.FooterQuickLinks a,
.FooterQuickLinks a:visited {
  margin: 10px 0 10px 0;
  text-decoration: none;
  color: inherit;
  display: block;
}
.FooterQuickLinks a:hover {
  color: white;
}
/* .Input,
.Input::selection{
    background-color:inherit;
    margin: 2px 0 2px 0;
    border: 1px solid #868686;
    font-family: inherit;
    border-radius:5px;
    padding: 5px 0 5px 5px;
    width: 97%;
    color: inherit;
} */
.FooterSubmit {
  background-color: inherit;
  color: #D19F46;
  font-weight: bold;
  padding: 10px;
  border: 1px solid #D19F46;
  border-radius: 20px;
  float: right;
  margin-left: 10px;
  cursor: pointer;
}
.FirstBlock {
  width: 18%;
}
.SecondBlock {
  width: 15%;
}
.ThirdBlock {
  width: 36%;
}
.FourthBlock {
  width: 23%;
}
.RealtorIcons {
  width: 100px;
  height: auto;
  float: right;
  padding-right: 10px;
}
.FooterFormThank {
  display: flex;
  align-items: center;
}
.BottomFormPart {
  display: flex;
}
.FooterFormThankIcon {
  margin-right: 10px;
}
#NeighborhoodsItems {
  display: flex;
  flex-wrap: wrap;
}
#NeighborhoodsItems .Item {
  color: inherit;
  font-size: 17px;
  display: block;
  padding: 6px 3px;
  width: 45%;
  text-align: left;
}
#NeighborhoodsItems .Item:hover {
  color: white;
}
@media all and (max-width: 1500px) {
  .FooterMain {
    padding: 30px;
  }
  .FourthBlockText {
    font-size: 14px;
  }
  .FooterLogoImg {
    width: 185px;
    height: auto;
  }
}
@media all and (max-width: 1100px) {
  .FooterMain {
    padding: 20px;
  }
  .FourthBlockText {
    font-size: 12px;
    padding-top: 30px;
  }
  .FooterBlock {
    width: 48%;
    text-align: left;
  }
  .FooterLogoImg {
    width: 250px;
    height: auto;
  }
  .FirstBlock {
    padding-bottom: 30px;
  }
  .FooterMain .ProjectLogoBlock {
    flex-direction: column;
  }
  .FooterMain .ProjectLogoBlock .ProjectLogo {
    margin: 20px 0;
  }
}
@media all and (max-width: 690px) {
  .FooterMain {
    padding: 20px;
  }
  .FourthBlockText {
    font-size: 12px;
    padding-top: 30px;
  }
  .FooterBlock {
    width: 100%;
    text-align: left;
  }
  .FooterLogoImg {
    width: 280px;
    height: auto;
    display: block;
    margin: 0 auto;
  }
  .FirstBlock {
    padding-bottom: 30px;
    border-bottom: 1px solid #4A4A4A;
  }
  .SecondBlock {
    padding-top: 30px;
    padding-bottom: 30px;
    border-bottom: 1px solid #4A4A4A;
  }
  .ThirdBlock {
    padding-top: 30px;
    padding-bottom: 30px;
    border-bottom: 1px solid #4A4A4A;
  }
  .FooterSubmit {
    width: 100%;
  }
  .FooterQuickLinks .Links {
    display: flex;
    flex-direction: row;
    flex-flow: row wrap;
  }
  .FooterQuickLinks .Links a {
    width: 49%;
    font-size: 14px;
    margin: 6px 0;
  }
}
