.ListinPageBody {
    padding-top: 138px;
}

.ListinPageHeaderLine {
    width: 100%;
    overflow: visible;
    -webkit-box-shadow: 0px 10px 13px -7px #c3caca, -3px 23px 25px 1px rgba(0, 0, 0, 0);
    box-shadow: 0px 10px 13px -7px #c3caca, -3px 23px 25px 1px rgba(0, 0, 0, 0);
    position: relative;
}

.ListinPageStaticHeader {
    display: flex;
    justify-content: space-between;
    height: 100px;
    /*box-shadow: 0 0 10px rgba(0,0,0,0.3);*/
    max-width: 1520px;
    margin: 0 auto;
    padding: 0 20px;
}

.VertLine {
    padding: 17px 0 0 0;
    width: 20px;
}

.PageListingLine {
    width: 100%;
    background-color: rgb(245, 245, 245);
}

.PageListing {
    max-width: 1520px;
    margin: 0 auto;
    background-color: rgb(245, 245, 245);
    /*padding-bottom: 40px;*/
    padding: 0 20px 40px 20px;
}

.MediaMain {
    display: flex;
    background-color: white;
    box-shadow: 4px 2px 12px -2px rgb(0 0 0 / 30%);
}

.MediaMain .MediaBlock {
    flex-grow: 5;
}

.MediaMain #AgentContact{
    min-width: 388px;
}

.MediaMain #AgentContact .Agent{
    border-left:none
}

.MediaMain #AgentContact .Agent .AgentTop{
    flex-direction: column;
    align-items: center;
}

.MediaMain #AgentContact .Agent .AgentTop .Photo{
  margin: 0 0 30px 0;
}

.MediaMain #AgentContact .ListingAgentCard {
    /* border-top:#dbdbdb 1px solid; */
    padding-top: 15px;
    max-width: unset;
    margin: 0;
}

.PropImages {
    max-width: 1134px;
}

.ListinPageStaticHeader .Title {
    color: #5A5A5A;
    width: 100%;
    display: flex;

}

.ListinPageStaticHeader .Title h1{
    margin: auto 0;
    font-family: "Montserrat",sans-serif;
}

/* 
.DealScoreLine .PoweredBy {
    font-size: 10px;
    text-align: right;
    padding: 0 10px 8px 0;
    position: absolute;
    bottom: 0;
    right: 0;
} */

.ListinPageStaticHeader .TitieAndDisc{
    font-size: 16px;
    font-weight: normal;
}

.ListinPageStaticHeader .TitieAndDisc >div {
    margin-bottom: 4px;
    color: black;
    font-size: 24px;
    font-weight: bold;
}

.ListinPageStaticHeader .Item {
    /* margin: auto; */
    align-self: center;
    text-align: center;
    width: 120px;
    border-left: 1px solid #E0E0E0;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.ListinPageStaticHeader .Item span {
    font-weight: bold;
    color: black;
}

.ListinPageStaticHeader .MainInfo {
    display: flex;
    color: #5A5A5A;
    flex:2;
}

.ListinPageStaticHeader .Last {
    background-color: #FAFAFA;
    height: 100%;
    display: flex;
    width: 160px;
}

.ListinPageStaticHeader .Last>div {
    margin: auto;
}

.ListinPageStaticHeader .Separator {
    font-weight: bold;
    border-left: 2px solid #E0E0E0;
}

.MediaImgSmall {
    width: 195px;
    height: auto;
    min-height: 145px;
}

.MediaSwitchItem {
    display: flex;
    align-items:center;
    cursor: pointer;
    position: relative;
    /*width: 221px;*/
    text-align: center;
    height: 145px;
    /*background-color: #E0E0E0;*/
    overflow: hidden;
    margin: 6px 6px 0 0;
    justify-content: center;
}

.MediaSwitchItem .Other {
    position: absolute;
    top: 0;
    left: 0;
    /* right: 6px; */
    margin: 0 3px;
    right: 0px;
    bottom: 0;
    background: rgba(0, 0, 0, 0.5)
}

.MediaCurrentDiv {
    overflow: hidden;
    /* width: 73%; */
    max-width: 1134px;
    height: 500px;
    text-align: center;
    background-color: #ebebeb;
}

.MediaCurrentDiv .MediaImgBig {
    max-width: 1134px;
    max-height: 500px;
    margin: 0 auto;
    height: 500px;
}


.MediaSwitchDiv {
    display: flex;
    height: 151px;
}


.MediaSwitchSliderDiv {
    height: 151px;
}


/* .DealScoreLine {
    position: relative;
    width: 100%;
    padding-top: 20px;
    padding-bottom: 50px;
    cursor: pointer;
} */

/* .DealScoreImg {
    width: 220px;
    margin: 0 auto;
    display: block;
} */

.DealScoreAndContacts {
    /* margin: 40px; */
    min-width: 388px;
    background-color: white;
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    justify-content: center;
    /*box-shadow: 4px 2px 12px -2px rgba(0,0,0,0.3);*/
}

.DealScoreContact {
    background-color: #FAFAFA;
    border-top: 1px solid #E0E0E0;
    padding-bottom: 24px;
    flex-grow: 2;
    display: flex;
    flex-direction: column;
    justify-content: center;
}

/* .SectionHdr {
    padding: 0 0 40px 0;
} */

.DealScoreContact .Button {
    margin: 0 auto;
    display: block;
}

.DealScoreContactDiv,
.ListingContactDiv {
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.DealInputs {
    width: 80%;
    align-self: center;
}

.ContactFormInputs {
    width: 100%;
}

.ContactFormInputs>div {
    display: flex;
}

.DealInputs {}

/* .DealBlockInfo {
    order: 1;
    padding-top: 30px;

} */

.DealBlockProperty {
    padding-top: 30px;
    display: flex;
    justify-content: space-between;
}

.DealBlockProperty .LeftBlock {
    flex: 3;

}

.DealBlockProperty .RightBlock {
    flex: 4;
}

.DealBlockProperty .RightBlock img {
    display: block;
    width: 100%;
    height: auto;
}

/* .DealBlockInfo .TextBlock {
    text-align: left;
    padding-right: 50px;
    font-family: inherit;
    color: #5A5A5A;
    column-count: 2;

} 

.DealBlockInfoBody {
    display: flex;
} */

.ListingPageMainInfoDiv h2 {
    text-align: left;
}



.ListingPageMainInfoDiv {
    margin-top: 40px;
    background-color: white;


    box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);
}


.ListingPageSignature{
    margin-top: 40px;
    background-color: white;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);
   
}

.ListingPageSignature .ListingAgentCard  .Agent{
    border: none;
}


.InfoBlock {
    padding: 40px 40px 50px 40px;
}

.KeyDealBlock {
    position: relative;
    order: 0;
    border-top: 1px solid #E0E0E0;
    border-bottom: 1px solid #E0E0E0;
    background-color: #FAFAFA;
}

.KeyDealBlock .Title {
    font-weight: bold;
    font-size: 25px;
}

.KeyDealBlock .TitleDiv {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.KeyDealBlock .Icon {
    width: 60px;
}

.KeyDealBlock .DiamondIcon {
    width: 30px;
}


.KeyDealBlock .FeaturedItem .DiamondIcon {
    padding-right: 10px;
}


.KeyDealBlock .TitleRightBlock {
    display: flex;
    align-items: center;
}

.SmartDealIconListing {
    width: 70px;
    padding: 5px;
    right: 0;
}

.KeyDealBlock .TitleRightBlock>.SmartDealIcon {
    margin-right: 20px;
}

.KeyDealTable {
    width: 100%;
}

.KeyDealTable thead td {
    font-size: 16px;
    padding: 0 0 10px 0;
    font-weight: 400;
}

.KeyDealTable td {
    border-bottom: 1px solid #E0E0E0;
}

.KeyDealTable tbody td {
    font-size: 20px;
    font-weight: 500;
    color: black;
    padding: 5px;
    position: relative;
}

.KeyDealTable .Separator {
    width: 30px;
    border-bottom: none;
}


.KeyDealTable .Feature {
    width: 60%;
}

.KeyDealTable .TheDeal {
    width: 10%;
}

.KeyDealBlock .Tar {
    text-align: right;
}

.KeyDealBlock .FeatureDiscount {
    color: #D19F46;
    font-weight: 500;
}

.KeyDealTable .Icon {
    width: 30px;
}

.KeyDealTable .Feat {
    width: 100%;
}

.KeyDealTable .Pri {
    text-align: right;
    white-space: nowrap;
}

.KeyDealTable .FeatureDiscount {
    font-weight: bold;
    font-weight: 950;
}

.KeyDealTable .MobilTableRow td {
    border-bottom: none;
}

.KeyDealTable .MobilTableRowLast td {
    border-bottom: 1px solid #E0E0E0 !important;
}

.KeyDealTable .MobilTableRow .MobileHdr {
    color: #5A5A5A;
}

.FeatureDiscount {
    color: #D19F46;
    font-weight: 600;
}
.PropertyInfoTable{
    text-align: left;
}
.PropertyInfoTable tr:first-child>th,
 .KeyDealTable td {
    color: #5A5A5A;
    font-size: 15px;
    text-align: left;
    font-family: inherit;
    font-weight: normal;
}

.PropertyInfoTable tr>th:nth-child(1) {
    width: 50%;
    margin-right: 20px;
}

.PropertyInfoTable tr>th:nth-child(3) {
    width: 50%;
}

.PropertyInfoTable ul {
    color: black;
    padding-left: 0px;
    list-style-type: none;
    font-weight: 500;
    font-family: inherit;
}

.PropertyInfoTable ul>li {
    padding: 4px;
}

.PropertyInfoTable ul>li:before {
    content: "—";
    text-indent: -5px;
    margin-right: 15px;
    color: #B4B4B4;
}

.ListingPageGoogleMapDiv {
    padding: 10px;
    margin-top: 20px;
    margin-bottom: 20px;
    min-height: 400px;
    background-color: white;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);
}

.ListingPageContactForm {
    padding: 20px;
    width: 40%;
    margin: auto;
}

.ListingPageContactForm h2 {
    text-align: center;
}

.ContactFormBottom {
    padding-top: 10px;
    display: flex;
    justify-content: space-between;
}

/* #ButtonSubmit{
    padding: 7px 0px 7px 0px;
    width: 160px;
} */

.checkIcon {
    width: 30px;
    height: 30px;
}

.ListinPageStaticHeader .MainInfo .Item
{
    font-size: 14px;
    line-height: 25px;
}
.ListinPageStaticHeader .MainInfo .Item span
{

    font-size: 20px;
}


.ListinPageStaticHeader .Item div
{
    font-size: 14px;
    line-height: 25px;
}

.ListinPageStaticHeader .Item div span
{
    font-size: 20px;
}

.MainInfoAndDealScoreAtTop {
    display: flex;
    flex-direction: row;
    border-top: 1px solid #E0E0E0;

}

.DealScoreAtTop {
    display: flex;
    justify-content: center;
    border-left: 1px solid #E0E0E0;
}

/* .DealScoreAtTop .DealScoreImg {
    width: 105px;
    height: 70px;
    display: block;
    margin: auto;
    padding: 10px;
} */

.MainInfoFirst, .MainInfoSecond {
    display: flex;
}

@media all and (max-width: 1600px) {

    .MediaCurrentDiv {
        overflow: hidden;
        max-width: 1134px;
        height: 500px;
        text-align: center;
        background-color: #ebebeb;
    }

    .MediaSwitchItem {
        width: 180px;
        height: 125px;
    }

    .MediaImgSmall {
        width: 100%;
        height: auto;
        min-height: 125px;
    }

    .DealScoreContact {

        padding-bottom: 73px;
    }

    .MediaSwitchDiv,
    .MediaSwitchSliderDiv {

        height: 137px;
    }

}


@media all and (max-width: 1450px) {

    .MediaCurrentDiv {
        overflow: hidden;
        max-width: 1034px;
        height: 500px;
        text-align: center;
        background-color: #ebebeb;
    }

    .MediaSwitchItem {
        width: 160px;
        height: 125px;
    }

    .MediaImgSmall {
        width: 160px;
        height: auto;
        min-height: 125px;
    }

}

@media all and (max-width: 1200px) {

    
    .MediaMain #AgentContact .ListingAgentCard {
        min-width: auto;
        margin: 0;
        padding: 0;
        width: 100%;
    }
    
    .PageListing {
        padding: 0 0 40px 0;
    }

    .PageListing .MediaMain {
        flex-direction: column;
    }


}

.ListinPageStaticHeader .PriceInCol {
    display: none;
}


@media all and (max-width: 1600px) {
    .DealScoreContact {

        padding-bottom: 24px;
    }

    .MediaSwitchItem .Other {
        margin: 0;
        right: 0;
    }

}



@media all and (max-width: 1000px) {

    
    .MainInfo {
        flex-direction: row;
    }

    .ListingPageMainInfoDiv {
        display: flex;
        flex-direction: column;
    }

    .ListinPageStaticHeader {
        flex-direction: column;
        height: auto;
        padding: 0;
    }

    .ListinPageStaticHeader .Title {
        padding: 0 0;
    }

    .ListinPageStaticHeader .MainInfo {
        flex-wrap: wrap;
        justify-content: space-around;
    }

    .ListinPageStaticHeader .MainInfo .Separator {
        width: 1px;
    }

    .ListinPageStaticHeader .MainInfo .Item {
        flex: 1 0 45%;
        height: 70px;
        border-bottom: 1px solid #E0E0E0;
    }

    .FirstSep,
    .LastSep {
        display: none;
    }

    .ListinPageStaticHeader .Price {
        width: 100%;
    }


    .ListinPageStaticHeader .Separator {
        font-weight: bold;
        border-left: 1px solid #E0E0E0;
    }

    .ListinPageStaticHeader .Title {
        height: 90px;
    }

    .ListinPageStaticHeader .TitieAndDisc {
        display: flex;
        flex-direction: column;
        justify-content: center;
    }

    .ListinPageStaticHeader h2 {
        font-size: 18px;
        padding: 0;
        margin: 0 0 2px 0;
    }

    .ListinPageStaticHeader .Title div {
        font-size: 14px;
    }

   

    /* .DealBlockInfo {
        border-top: 1px solid #E0E0E0;
        border-bottom: 1px solid #E0E0E0;
        background-color: #FAFAFA;
    }

    .DealBlockInfo .TextBlock {
        column-count: 1;
        padding: 0;
    } */

    .KeyDealBlock {

        border-top: none;
        border-bottom: none;
        background-color: inherit;
    }

    .InfoBlock {
        padding: 20px 20px 20px 20px;
    }


    .DealBlockProperty {
        order: 2;
        flex-direction: column;
    }

    .KeyDealTable tbody td {
        font-size: 12px;

    }

    .KeyDealTable thead td {
        font-size: 12px;
    }

    .ListingPageContactForm {
        width: auto;
    }

    .VertLine {
        padding: 17px 0 0 10px;
    }

    .DealScoreAndContacts {
        min-width: auto;
    }

    .checkIcon {
        width: 20px;
        height: 20px;
    }

}

@media all and (max-width: 700px) {


    .MediaMain #AgentContact{
        min-width: auto;
    }
    

    .NamePhone {
        display: flex;
        flex-direction: column;
    }

    .ListinPageStaticHeader .PrevSep {
        display: none;
    }


    .ListinPageStaticHeader .PriceInCol {
        height: 80px;
        display: flex;
        border-top: 1px solid #E0E0E0;
    }

    .DealInputs {
        width: 95%;
    }
}



.ListingMap {
    margin-top: 20px;
}

.ListingPageSecureBlock {
    font-size: 12px;
}