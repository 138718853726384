.SelectionLineLine {
  width: 100%;
}
/* see search-page-style.css */
/* 
.SelectionBlock{
    padding: 50px 40px 50px 40px;
    max-width: 1520px;
    margin: 0 auto;
    display: flex;
    flex-direction: column;
}


.MainMap{
    flex: 1;
    min-height: 700px;
    padding: 15px;
    box-shadow: 0 0 10px rgba(0,0,0,0.3);
}

.Selectionlock .TopPart{
    display: flex;
} */
.SelectionPage .MainMap {
  flex: 1;
  height: auto;
  min-height: auto;
}
.SelectionPage .MainMap .GoogleStaticMapImg {
  width: 100%;
  height: auto;
}
.SelectionPage .PageBody {
  padding: 0;
}
.Selection .TopSliderImg {
  max-width: none;
  height: auto;
  width: 100%;
}
.Selection .TopSliderItem {
  height: auto;
}
