/* .OffersGridMain{
    top: 0;
    text-align: center;
    padding-top: 40px;
    padding-bottom: 40px;
}

.OffersGrid{
    display: flex;
    align-items:center;
    flex-direction: row;
    justify-content: center;
    flex-flow: row wrap;
    margin: 0 auto;
    max-width: 1520px;
} */

.OffersGridMainVertical{
    top: 0;
    text-align: center;
    /* padding-top: 40px;
    padding-bottom: 40px; */
}

.OffersGridVertical{
    display: flex;
    flex-direction: column;
    margin: 0 auto;
}

