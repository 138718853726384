.SellPageDescr {
    margin: 0 auto;
    text-align: left;
    max-width: 940px;
    padding: 60px 40px 20px 40px;
}

.SellPage{
    margin-bottom: 40px;
    padding-top: 138px;
    max-width: 1920px;
    margin: 0 auto;
}

.SellPageItemText {
    text-align: left;
    padding-left: 100px;
    padding-right: 235px;
}

.SellPageItemTextRt {
    text-align: right;
    padding-right: 100px;
    padding-left: 235px;
}

.SellPageDescription {
    padding: 70px 70px;
    font-size: 28px;
    line-height: 38px;
    background-image: url('../../elements/images/sell_with_us_bgr.png');
    background-position: center;
    background-repeat: no-repeat;
}

.SellPageDescriptionText {
    margin: auto;
    max-width: 640px;
}

.SellPageItemTextBody {
    font-size: 16px;
    line-height: 24px;
    padding-top: 20px;
}

.SellPageElements {
    box-shadow: 0 0 10px rgb(0 0 0 / 30%);

}

.SellPageItem{
    padding: 84px 129px;
    display: flex;
    background-color: white;
    border-bottom: 1px solid #E0E0E0;

}

.SellPageItem .HOne h2 {
    margin-top: 0;
    /* text-transform: none!important; */
}


.SellPageContactForm{
    padding: 120px 20px 50px 20px;
    max-width: 800px;
    margin: 0 auto;
}

.SellPageContactForm .HOne h2{
    text-transform: uppercase;

}

.SellPageContactForm h2{
    text-align: center;
}

.ContactFormBottom{
    display: flex;
    justify-content: space-between;
}

/* #ButtonSubmit{
    padding: 7px 0px 7px 0px;
    width: 160px;
} */

.NamePhone {
    display: flex;
    flex-direction: row;
}

.SellPageContactForm .Name, .SellPageContactForm .Phone {
    flex: 1;
    display: flex;
}
.SellPageBody {
    max-width: 1520px;
    margin: 0 auto;
    padding: 0 60px;
}

.SellPageContactForm input,
.SellPageContactForm textarea
{
        display: block;
        flex: 1;
}

SellPageDescrLine {
    width: 100%;
    display: flex;
}

.SellPageDescr h3 {
    font-size: 25px;
}

.FormSubtitle {
    text-align: center;
    font-size: 15px;
    padding: 0 0 15px 0;
}

.ImgPresentation {
    width: 179px;
    height: 186px;
}

.ImgMarketing {
    width: 156px;
    height: 166px;
}

.ImgService {
    width: 168px;
    height: 167px;
}

@media all and (max-width: 1000px){

    .SellPageDescription {
        padding: 79px 44px;
    }

    .SellPageDescr {
        /*margin: 40px 0 10px 0;*/
        text-align: left;
    }

    .NamePhone {
        flex-direction: column;
    }

    .SellPageBody {
        padding: 0 30px;
    }


}

@media all and (max-width: 600px){

    .SellPageBody {
        padding: 0 10px;
    }


    .SellPageDescription {
        padding: 40px 20px;
    }
    .SellPageDescriptionText {
        font-size: 20px;
        line-height: 28px;
    }


}

@media all and (max-width: 1280px){

    .SellPageItemText {
        padding-right: 10px;
    }

    .SellPageItemTextRt {
        padding-left: 10px;
    }


}

@media all and (max-width: 1080px){

    .SellPageItem {
        padding: 37px 46px;
    }

    .SellPageItemText {
        padding-right: 20px;
        padding-left: 20px;
    }

    .SellPageItemTextRt {
        padding-left: 20px;
        padding-right: 20px;
    }

}


@media all and (max-width: 780px){
    .SellPageItemText .HOne {
        text-align: center!important;
    }

    .SellPageItemTextRt .HOne {
        text-align: center!important;
    }

    .SellPageItemTextRt {
        text-align: left!important;
    }

    .SellPageItem {
        flex-direction: column;
        padding: 27px 2px;
    }

    .ImgPresentation {
        width: 150px;
        height: auto;
        margin: 0 auto;
        padding-bottom: 40px;
    }

    .ImgMarketing {
        width: 130px;
        height: auto;
        margin: 0 auto;
        padding-bottom: 40px;
    }

    .ImgService {
        width: 140px;
        height: auto;
        margin: 0 auto;
        padding-bottom: 40px;
    }

    .SellPageItemCt {
        flex-direction: column-reverse;
    }

    .SellPageContactForm {
        padding: 50px 20px 50px 20px;
    }

}