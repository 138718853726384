.AboutPageLine {
    width: 100%;
}

.AboutPageTopBlockBody {
    max-width: 1520px;
    margin: 40px auto;
    display: flex;
    text-align: left;
    padding: 0 40px;
}


.AboutUsPage .AboutText {
    font-size: 16px;
    line-height: 22px;
    margin-top: 30px;
    margin-bottom: 30px;
}

.AboutPageImgSmart{
    width: 490px;
    margin-left: 100px;
}

.OurLeaderShipBlock{
    background-color:#FAFAFA;
    padding-bottom: 60px;
    width: 100%;
    background-image: url('../../elements/images/bgr-lines.png');
    background-position: top;
    background-repeat: no-repeat;
    padding-top: 20px;

}

.CustomerRelationsBlock{
    background-color:white;
    padding-top: 40px;
    padding-bottom: 80px;
    background-image: url('../../elements/images/bgr-lines.png');
    background-position: top;
    background-repeat: no-repeat;
}

.UserCardsGrid{
    display: flex;
    justify-content: center;
}

.OurLeaderShipBody{
    padding-top: 15px;
}

.AboutUsPage .OurValueBlock{
    background-color: #FAFAFA;
    max-width:fit-content;
}
.AboutUsPage .OurValueBlock .ValuesBody{
    margin-bottom: 0;
    padding-bottom: 60px;
}

@media all and (max-width: 1300px){

    .AboutPageImgSmart{
        width: 380px;
        height: auto;
    }

}


@media all and (max-width: 1000px){

    .AboutPageTopBlockBody{
        flex-direction: column;
    }

    .AboutPageImgSmart {
        margin: 0 auto;
        display: block;
        width: 280px;
    }

    .UserCardsGrid {
        flex-direction: column;
    }

    .UserCard {
        display: inline-block;
    }

    .UserCardsGrid .UserCardMainDiv  {
        text-align: center;
    }

}
