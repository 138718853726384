/* .MyDealMain{
    background-color:white;
    position: fixed;
    z-index: 11000;
    top:30%;
    left:27%;
    padding: 40px;
    font-family: inherit;
    display:flex;
    width: 40%;
}



.MyDealMain:focus{
    opacity: 100%;
} */
.CloseIcon{
    position: absolute;
    top:16px;
    right: 16px;
}

.CloseIconUnderBlock{
    z-index: 100000;
}

.CloseIconUnderBlock line{
        stroke:rgb(55, 53, 53);
}


.ModalMessage{
    margin-top: 30px;
    margin-bottom: 40px;

}

.ModalMessageText{
    color:#5A5A5A;
    text-align: center;
    margin: auto;
}

.Body{
    padding: 40px;
    /* margin: auto; */
    margin: 0 120px;
}

.ThanksTitle{
margin-bottom: 30px;
}

.Modal .Body .PoweredByBlock {
    justify-content: center;

}


@media all and (max-width: 780px) {
    .Body{
        padding: 10px;
        margin: auto;
    }
}