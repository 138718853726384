.slick-slider {
  display: grid;
  margin: auto;
}
.StandAloneTopSliderImg {
  max-width: none;
  height: auto;
  width: 100%;
}
.StandAloneTopSliderItem {
  height: auto;
}
.StandAloneSlider {
  height: 100%;
  display: grid;
  align-content: space-between;
}
.StandAloneSlider .StandAloneSliderTopKo .Counter {
  bottom: 160px;
  z-index: 10000;
  left: 10px;
}
.StandAloneSlider .StandAloneSliderTopKo .slick-slider {
  align-items: center;
}
.StandAloneSlider .StandAloneSliderTopKo .slick-slider .slick-prev:before,
.StandAloneSlider .StandAloneSliderTopKo .slick-slider .slick-next:before {
  content: none;
}
.StandAloneSlider .StandAloneSliderTopKo .slick-slider .slick-arrow {
  padding-bottom: 15px;
}
.StandAloneSlider .StandAloneSliderTopKo .slick-slider .slick-next {
  right: 45px;
}
@media all and (max-width: 1000px) {
  .StandAloneSlider .StandAloneSliderTopKo {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
  }
  .StandAloneSlider .StandAloneSliderTopKo .slick-slider {
    height: 100%;
  }
  .StandAloneSlider .StandAloneSliderTopKo .Counter {
    bottom: 10px;
  }
  .StandAloneSlider .StandAloneSliderTopKo .slick-track {
    display: flex;
    justify-content: center;
    align-items: center;
  }
}
.StandAloneSlider .StandAloneSliderTopKo {
  overflow: hidden;
}
.StandAloneSlider .StandAloneSliderTopKo .StandAloneTopSliderItem {
  position: relative;
}
.StandAloneSlider .StandAloneSliderTop .StandAloneTopSliderItem {
  cursor: pointer;
  overflow: hidden;
  text-align: center;
  display: flex;
  align-items: center;
}
.StandAloneSlider .StandAloneSliderTop .StandAloneTopSliderImg {
  width: auto;
  position: relative;
  margin: 0 auto;
  height: calc(100vh - (100px + 120px + 140px + 30px));
}
@media all and (max-width: 850px) {
  .StandAloneSlider .StandAloneSliderTop .StandAloneTopSliderImg {
    height: auto;
    width: 100%;
    max-height: 100%;
  }
}
.StandAloneSlider .StandAloneSliderTop .slick-next {
  right: 10px;
}
.StandAloneSlider .StandAloneSliderTop .slick-prev {
  left: 10px;
  z-index: 100;
}
.StandAloneSlider .StandAloneSliderTop .StandAloneSliderTop {
  /*background-color: #E0E0E0;*/
}
.StandAloneSlider .StandAloneSliderNavi .slick-list {
  max-height: 140px;
}
.StandAloneSlider .StandAloneSliderNavi .StandAloneMediaSwitchItem {
  position: relative;
  height: 140px;
  display: flex;
  width: 200px;
  align-items: center;
  overflow: hidden;
  justify-content: center;
}
.StandAloneSlider .StandAloneSliderNavi .StandAloneMediaSwitchItem .StandAloneMediaImgSmall {
  width: 195px;
  height: auto;
  min-height: 145px;
}
.StandAloneSlider .StandAloneSliderNavi .StandAloneMediaSwitchItem img {
  height: 100%;
}
.StandAloneSlider .StandAloneSliderNavi .StandAloneMediaSwitchItem .Other {
  position: absolute;
  top: 0;
  left: 0;
  /* right: 6px; */
  right: 0px;
  bottom: 0;
  background: rgba(0, 0, 0, 0.5);
}
@media all and (max-width: 1000px) {
  .StandAloneSlider .StandAloneSliderNavi {
    display: none;
  }
}
@media all and (max-width: 1200px) {
  .StandAloneSlider .StandAloneTopSliderItem {
    width: 100%;
    max-width: none;
  }
  .StandAloneSlider .StandAloneMediaSwitchItem {
    width: 195px !important;
    height: 145px !important;
  }
  .StandAloneSlider .StandAloneMediaSwitchItem .StandAloneMediaImgSmall {
    width: 195px;
    height: auto;
    min-height: 145px;
  }
}
@media all and (max-width: 1100px) {
  .StandAloneSlider .StandAloneTopSliderItem {
    width: 100%;
    max-width: none;
  }
  .StandAloneSlider .StandAloneMediaSwitchItem {
    width: 185px !important;
    height: 135px !important;
  }
  .StandAloneSlider .StandAloneMediaSwitchItem .StandAloneMediaImgSmall {
    width: 185px;
    height: auto;
    min-height: 135px;
  }
}
@media all and (max-width: 1150px) {
  .StandAloneSlider .StandAloneTopSliderItem {
    width: 100%;
    max-width: none;
  }
  .StandAloneSlider .StandAloneMediaSwitchItem {
    width: 175px !important;
    height: 135px !important;
  }
  .StandAloneSlider .StandAloneMediaSwitchItem .StandAloneMediaImgSmall {
    width: 175px;
    height: auto;
    min-height: 135px;
  }
}
.FullScreenModalSlider {
  display: block;
  position: relative;
}
.FullScreenModalSlider .slick-slider .slick-track {
  display: flex;
  align-items: center;
}
.FullScreenModalSlider .slick-slider .slick-arrow {
  z-index: 1000;
}
.FullScreenModalSlider .slick-slider .slick-prev:before,
.FullScreenModalSlider .slick-slider .slick-next:before {
  content: none;
}
.FullScreenModalSlider .slick-slider .slick-next {
  right: 45px;
}
.FullScreenModalSlider .slick-slider .slick-prev {
  left: 10px;
}
.FullScreenModalSlider .slick-slider .StandAloneTopSliderItem .StandAloneTopSliderImg {
  min-height: 98vh;
  max-height: 98vh;
  max-width: 90vw;
  height: auto;
  width: auto;
  margin: auto;
}
