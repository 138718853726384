.UserCard .Title{
    position: absolute;
    bottom:15px;
    color: white;
    text-align: center;
    width: 100%;
    font-weight: bold;
}
.UserCard .Title >span{
    color: #D19F46;
    font-weight:500;
}

.UserCard{
    position: relative;
    min-width: 300px;
    min-height: 375px;
}



.UserCard .Overlay{
    position: absolute;
    height: 100%;
    width: 100%;
    background:linear-gradient(#ffffff00,#ffffff00, #030303c7);
    bottom:4px;
    height: 375px;
}

.UserCardOverlay .SocialIcon{
    max-width: 25px;
    margin: -5px;
}

.UserCardOverlay{
    position: absolute;
    width: 100%;
    top:0;
    bottom: 4px;
    background-color:#000000a2;
}

.UserCardOverlay .Title{
    height: 90%;
    
}

.OverlayBlur{
    filter: blur(10px);
}

#TeamTitleCard{
    height: 60%;
}

#TeamTitleCard .Description{
    font-weight: normal;
    text-align: left;
    padding:20px;
    word-break: break-all;
    line-height: 23px;
}
.UserCardMainDiv{
    margin:10px;
}
.UserCardLast{
    width: 300px;
    height: 375px;
    box-shadow: 0 0 10px rgba(0,0,0,0.3 );
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.UserCardLast button{
    width:80%;
    padding:10px 15px 10px 15px;
}

.UserCardLast img{
    width: 110px;
    padding-right: 15px;
    margin-bottom: 10px;
}

.UserCardOverlay a{ 
    color: white;
}

.Link{
    display: block;
    margin: 4px 0;
    width: 100%;
    text-align: center;
    padding: 3px 0;
    border-radius:100px;
    border: solid 2px #D19F46;
    
}