 body {
    display: flex;
    min-height: 100vh;
    flex-direction: column;
}

.Counter{
    color: white;
    border-radius: 5px;
    background-color:rgba(0,0,0,0.8);
    padding: 5px 10px;
    font-weight: bold;
    position: absolute;
}

#root {
    /*padding-top: 138px;   */
    font-family: "Montserrat", sans-serif;
    display: flex;
    min-height: 100vh;
    flex-direction: column;
}

.Page{
    margin: 0 auto;
    overflow: hidden;
    width: 100%;
}

.FonGravesendLight {
    font-family: gravesend-sans, sans-serif;
    font-weight: 300;
    font-style: normal;
}

.FonGravesendMedium {
    font-family: gravesend-sans, sans-serif;
    font-weight: 500;
    font-style: normal;
}

.FonGravesendBold {
    font-family: gravesend-sans, sans-serif;
    font-weight: 700;
    font-style: normal;
}

.FonMontserratRegular {
    font-family: "Montserrat", sans-serif;
    font-weight: 400;
    font-style: normal;
}

.FonMontserratMedium {
    font-family: "Montserrat", sans-serif;
    font-weight: 500;
    font-style: normal;
}

.FonMontserratBold {
    font-family: "Montserrat", sans-serif;
    font-weight: 700;
    font-style: normal;
}


.PageBody{
    padding-left: 130px;
    padding-right: 130px;
    /*padding-top: 138px;*/
    text-align: center;
    flex: 1;
}

.MenuSpace {
    padding-top: 129px;
}

.PageBodyWhite{
    background-color:white;
}

.PageBodyGrey{
    background-color:rgb(245, 245, 245);
}


.Block{
    top: 0;
    text-align: center;
    padding-top: 40px;
}

.Icon{
    width: 100px;
}

.Blured{
    -webkit-filter: blur(5px);
  -moz-filter: blur(5px);
  -o-filter: blur(5px);
  -ms-filter: blur(5px);
  filter: blur(5px);
}

.ModalBlur{
    width: 100%;
    position: fixed;
    height: 100%;
    background-color:rgba(0,0,0,0.3);
    z-index: 13000;
    top: 0;
    left:0;
    backdrop-filter: blur(1.5px);
}

.ModalBlurDiv{
    width: 100%;
    position: fixed;
    height: 100%;
    z-index: 13000;
    top: 0px;
}


/* .Input,
.Input::selection */
.Input{
    background-color:inherit;
    margin: 2px;
    border: 1px solid #868686;
    font-family: inherit;
    border-radius:7px;
    padding: 5px 0 5px 5px;
    width: 97%;
    color: inherit;
    -webkit-appearance: none;
    font-size: 12px;
}

.Input:focus{
    box-shadow: 0 0 10px #0000004d;
    border :1px solid #5A5A5A;
    outline: none;
}

.Button{
    background-color:#D19F46;
    border: solid 1px #D19F46;
    border-radius: 23px;
    font-weight: bolder;
    font-size: 17px;
    color: white;
    display: inline;
    cursor: pointer;
}

textarea{
    resize: none;
    height: 60px;
}

a{
    text-decoration: none;
}

.IconFormSmall{
    width: 20px;
    margin-right: 10px;
}

.EmptyBody{
    min-height: 900px;
}

h1,h2,h3 {
    font-size: 40px;
    font-family: gravesend-sans, sans-serif;
    font-weight: bold;
    padding-bottom: 0;
    margin-bottom: 0;
}


@media all and (max-width: 1300px){

    .PageBody{
        padding-left: 30px;
        padding-right: 30px;
        text-align: center;
    }

}


#PageLogoImg{
   
    width: 100%;
    max-height: 350px;
    max-width: 1920px;
}

#PageLogoTitle{
    position: absolute;
    margin: auto;
    z-index: 100;
    color:white;

}

.PageLogo{
    min-height: 350px;
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 55px;
    font-weight: bold;
}



.Overlay{
    position: absolute;
    height: 100%;
    width: 100%;
    background:linear-gradient(#ffffff00,#ffffff00, #030303c7);
    bottom:5px;
}

.Modal{
    justify-content: center;
    display: flex;
    align-items: baseline;
    position: fixed;
    left: 50%;
    top: 50%;
    /*min-width: 500px;*/
    -webkit-transform: translateX(-50%) translateY(-50%);
    -moz-transform: translateX(-50%) translateY(-50%);
    transform: translateX(-50%) translateY(-50%);
    background-color:white;
    z-index: 14000;
    font-family: inherit;
    box-shadow: 20px 20px 70px #000000;
}

.Modal .CoverImage{
    width: 100%;
    margin-top: 20px;
}

.ModalNoFelx {
    display: block!important;
    /*vertical-align: middle;*/
}

.ModalBody {
    padding: 10px 40px 40px 40px;
    width: 800px;
}

.Modal:focus{
    opacity: 100%;
}

@media all and (max-width: 1600px){
    .PageLogo{
        min-height: calc((100vw - 17px) * 0.2078125);
    }

}

@media all and (max-width: 1300px){

    .PageBody{
        padding-left: 30px;
        padding-right: 30px;
        text-align: center;
    }

}

@media all and (max-width: 1000px){

    .MenuSpace {
        padding-top: 83px!important;
    }

    .Modal{
        width: 90%;
        /*left: 0;*/
        /*top: 0;*/
        /*width: 100%;*/
        /*-webkit-transform: none;*/
        /*-moz-transform: none;*/
        /*transform: none;*/
        /*overflow-y: auto;*/
    }

    .ModalBody {
        overflow-y: auto;
    }

    .SecureDiv .Text {
        padding: 10px 0 10px 0;
        font-size: 14px;
        line-height: 22px;
        display: block;
    }


}

@media all and (max-width: 700px){

    .MenuSpace {
        padding-top: 83px!important;
    }

    .Modal{
        /*left: 0;*/
        /*top: 0;*/
        /*bottom: 0;*/
        /*right: 0;*/
    }

    .ModalBody {
        overflow-y: auto;
        padding: 10px 20px 20px 20px;
    }


}

.Hidden{
    display: none;
}

.HIDDEN{
    display: hidden;
}

#ButtonSubmit{
    padding: 7px 20px ;
    /* width: 160px; */
    white-space: nowrap;
}

.SecureIcon{
    width: 20px;
    display: inline;
    vertical-align:middle
}


.SecureDiv .Text{
    vertical-align:middle;
    display: inline;
    margin-left: 5px;
}
.SecureDiv{
    /*display: flex;*/
    /*flex-direction: row;*/
    padding-right: 20px;
    text-align: center;
    margin: 10px 0;
    align-items:center;
}

.CloseModal{
    /* background-color:rgba(255,255,223,0.3); */
    position:fixed;
    width: 100%;
    height: 100%;
}

.GridMain{
    top: 0;
    text-align: center;
    padding-top: 40px;
    padding-bottom: 40px;
}

.Grid{
    display: flex;
    align-items:center;
    flex-direction: row;
    justify-content: center;
    /* flex: 0 0 25%; */
    /*justify-content: space-between;*/
    /*align-content: flex-start;*/
    flex-flow: row wrap;
    margin: 0 auto;
    max-width: 1520px;
}

.MessageModal .CoverBlock{
    width: 100%;
    min-height: 150px;
}
.MessageModal .MobileMessageModal .Input{
    font-size: 18px;
}

 
.MessageModal .ProjectLogo{
    max-height: 170px;
    max-width: 220px
}

.ListinPageStaticHeader{
  height: auto;
  min-height: 100px;
  
}

.TitieAndDisc{
    margin: auto 0;
}

.TitieAndDisc h2{
    font-size: 20px;
    margin: 0 auto;
    color: black;
    white-space:normal;
}


@media all and (max-width:1000px){
    .MessageModal .MobileMessageModal .Input{
        padding: 7px 0 7px 5px;
        font-size: 16px;
    }
}