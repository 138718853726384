.NotesAndAgent {
  margin: 0 auto;
  display: flex;
  padding: 0 20px 0 20px;
  max-width: 900px;
}
.NotesAndAgent .Notes {
  vertical-align: top;
  flex: 1;
  font-size: 18px;
  line-height: 22px;
  text-align: left;
  color: white;
  background-color: black;
  padding: 0 40px 30px 40px;
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.NotesAndAgent .Notes .Title {
  line-height: 35px;
}
.NotesAndAgent .Date {
  font-size: 14px;
  font-style: italic;
}
.NotesAndAgent .Agent {
  padding: 20px;
  background-color: white;
  font-style: italic;
  border-left: 1px solid #dbdbdb;
}
.NotesAndAgent .Agent .SMLXSignature {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 432px;
  margin: auto;
  border: 1px solid #E0E0E0;
  padding: 14px;
  box-shadow: 10px 5px 5px #E0E0E0;
}
.NotesAndAgent .Agent .SMLXSignature .Logo {
  width: 240px;
}
.NotesAndAgent .Agent .AgentTop {
  display: flex;
  padding: 10px 10px;
}
.NotesAndAgent .Agent .AgentTop .Photo {
  margin: 0 30px 0 0;
  width: 160px;
  height: 160px;
  border-radius: 100%;
  display: flex;
  align-items: flex-start;
  justify-content: center;
  overflow: hidden;
}
.NotesAndAgent .Agent .AgentTop .Photo .AgentImg {
  overflow: hidden;
}
.NotesAndAgent .Agent .AgentTop .BigProfile {
  margin: 0 0 30px 0;
  border-radius: 2%;
  display: flex;
  align-items: flex-start;
  justify-content: center;
  overflow: hidden;
}
.NotesAndAgent .Agent .AgentTop .BigProfile .AgentImg {
  overflow: hidden;
}
.NotesAndAgent .Agent .AgentTop .Info {
  font-weight: 500;
  text-align: left;
  font-style: normal;
  line-height: 22px;
}
.NotesAndAgent .Agent .AgentTop .Info .HeadquarterLocation {
  font-size: 13px;
}
.NotesAndAgent .Agent .AgentTop .Info .TableBlock {
  color: #000000;
}
.NotesAndAgent .Agent .AgentTop .Info .TableBlock a {
  color: black;
}
.NotesAndAgent .Agent .AgentTop .Info .TableBlock .InfoIcon {
  color: #D19F46;
  font-weight: bolder;
}
.NotesAndAgent .Agent .AgentTop .Info .TableBlock .InfoIcon .InstagramIcon {
  margin: -7px -6px;
  width: 25px;
}
.NotesAndAgent .Agent .AgentTop .Info .Name {
  font-weight: bold;
  margin-bottom: 10px;
}
.NotesAndAgent .Agent .AgentTop .Info .HOne h2 {
  margin: 0;
}
.NotesAndAgent .Agent .AgentTop .Info .HOne .Underline {
  margin-bottom: 10px;
}
.NotesAndAgent .Agent .AgentBottom {
  display: flex;
  flex-direction: row;
  justify-content: center;
}
.NotesAndAgent .Agent .AgentBottom .ContactWABtn {
  margin-top: 10px;
  display: flex;
  flex-direction: row;
  width: 230px;
  background-color: #D19F46;
  color: white;
  font-size: 15px;
  font-style: normal;
  text-transform: uppercase;
  border-radius: 20px;
  padding: 5px 25px;
  justify-content: center;
  align-items: center;
}
.NotesAndAgent .Agent .AgentBottom .ContactWABtn .ContactWA {
  margin: auto;
  width: 104px;
  height: auto;
}
.NotesAndAgent .AgentImg {
  width: 200px;
}
.ListingAgentCard {
  display: block;
}
.ListingAgentCard .AgentTop {
  justify-content: center;
}
@media all and (max-width: 900px) {
  .NotesAndAgent {
    padding: 20px 10px;
    flex-direction: column;
  }
  .NotesAndAgent .Notes {
    font-size: 16px;
    line-height: 18px;
    padding: 0 10px 10px 10px;
  }
  .NotesAndAgent .Agent {
    border-left: none;
  }
  .NotesAndAgent .Agent .AgentTop {
    justify-content: center;
  }
}
@media all and (max-width: 900px) {
  .NotesAndAgent .Agent {
    padding: 20px 0;
  }
}
@media all and (max-width: 600px) {
  .NotesAndAgent {
    padding: 20px 10px;
    flex-direction: column;
  }
  .NotesAndAgent .Notes {
    font-size: 16px;
    line-height: 18px;
    padding: 0 10px 10px 10px;
  }
  .NotesAndAgent .Agent {
    border-left: none;
  }
  .NotesAndAgent .Agent .SMLXSignature {
    width: 327px;
  }
  .NotesAndAgent .Agent .SMLXSignature .Logo {
    width: 170px;
  }
  .NotesAndAgent .Agent .SMLXSignature .SocialIcon {
    width: 30px;
  }
  .NotesAndAgent .Agent .AgentTop {
    justify-content: center;
  }
  .NotesAndAgent .Agent .AgentTop .Photo {
    width: 70px;
    height: 70px;
    margin: 0 10px 0 0;
  }
  .NotesAndAgent .Agent .AgentTop .Info {
    font-size: 15px;
  }
}
@media all and (max-width: 400px) {
  .NotesAndAgent .Agent .SMLXSignature {
    width: 300px;
  }
  .NotesAndAgent .Agent .SMLXSignature .Logo {
    width: 170px;
  }
  .NotesAndAgent .Agent .SMLXSignature .SocialIcon {
    width: 30px;
  }
}
