.DealBlockInfo {
    order: 1;
    padding-top: 30px;

}

.DealBlockInfo .TextBlock {
    text-align: left;
    padding-right: 50px;
    font-family: inherit;
    color: #5A5A5A;
    column-count: 2;

}
.DealBlockInfoBody {
    display: flex;
}



.SectionHdr {
    padding: 0 0 40px 0;
}


@media all and (max-width: 1000px) {
    .DealBlockInfo {
        border-top: 1px solid #E0E0E0;
        border-bottom: 1px solid #E0E0E0;
        background-color: #FAFAFA;
    }

    .DealBlockInfo .TextBlock {
        column-count: 1;
        padding: 0;
    }

}