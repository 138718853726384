.CustomList{
    padding-top: 40px;
    margin: 0;
    -moz-column-count: 3;
    -moz-column-gap: 20px;
    -webkit-column-count: 3;
    -webkit-column-gap: 20px;
    column-count: 3;
    column-gap: 20px;
    list-style: '—';
    color: grey;
    font-weight: 500;

    .Feature {
        break-inside: avoid-column;
        color: black;
        padding: 0 30px 30px 10px;

    }

    //li:not(:first-child){
    //    margin: 30px 0;
    //}

    li::marker{
        color: #B4B4B4;
    }
    .Feature{
        .FeatureText{
        }
    }
    @media all and (max-width: 800px){
        -moz-column-count: 1;
        -webkit-column-count: 1;
        column-count: 1;
        margin: 0 20px 0 10px;
    }
}