.KeyDealBlock .infoIcon{
    
    /* right: 20px;
    top: 20px; */
}

.KeyDealBlock .InfoModal{
    min-width: 300px;
    min-height: 200px;
    padding: 20px;
    display: flex;
    justify-content: center;
    align-content: center;
    align-items: center;
    text-align: left;
    line-height: 26px;
}

.KeyDealBlock .InfoModal > b{
    padding-right: 10px;
}