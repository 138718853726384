.ListingMapMarker {
  padding-left: 5px;
  font-size: 16px;
  display: flex;
  justify-content: center;
  flex-direction: column;
  text-align: center;
}
.ListingMapMarker .MarkerImage {
  height: 400px;
  align-self: center;
  max-width: 250px;
  max-height: 170px;
  padding-bottom: 20px;
}
.ListingMapMarker .Location {
  font-size: 20px;
  font-weight: bold;
  margin-bottom: 10px;
}
.ListingMapMarker .Price {
  font-weight: bold;
}
.ListingMapMarker .Price > span {
  font-style: oblique;
  color: #D19F46;
}
.SelecitonMapMarker {
  position: relative;
  padding: 3px;
  margin: 3px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  min-width: 200px;
}
.SelecitonMapMarker .SoldOutIcon {
  position: absolute;
  right: 0;
  margin: auto;
  width: 140px;
  height: auto;
  z-index: 9000;
}
.SelecitonMapMarker .MarkerImage {
  align-self: center;
  max-width: 250px;
  max-height: 70px;
  padding-bottom: 20px;
}
.SelecitonMapMarker .SoldOutIcon {
  position: absolute;
  top: 0;
  left: 0;
}
.SelecitonMapMarker div {
  font-size: 16px;
  padding-bottom: 5px;
}
.SelecitonMapMarker div span {
  font-weight: bold;
}
.SelecitonMapMarker a:focus {
  outline: none!important;
}
