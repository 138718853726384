.WizardPage {
    padding-top: 85px;
}

.WizardPage .WizardBlock {
    padding: 110px 0 80px 0;
    display: flex;
    justify-content: center;
    background-color: white;
    background-image: url('../../elements/images/wizard_bgr.jpg');
    background-repeat: repeat-y;
    background-position: center;
}

.WizardPage .WizardBlock h2 {
    margin: 0;
}

.WizardPage .BuySmartText1 {
    font-size: 28px;
    padding-top: 89px;
    max-width: 660px;
    margin: 0 auto;
    color: black;
}

.WizardPage .BuySmartText2 {
    padding-top: 38px;
    padding-bottom: 60px;
    font-size: 28px;
    font-weight: bold;
    max-width: 460px;
    margin: 0 auto;
    color: black;
}

.WizardPage .PoweredByBlock {
    display: flex;
    justify-content: space-around;
}

.WizardFrame {
    display: flex;
    justify-content: center;
    /* height: 550px; */
    max-width: 1500px;
    width: 100%;
    align-items: center;
}


.WizardBlock h1{
    margin: 0;
    color: Black;
}

.WizardBody {
    flex: 1;
}

.WizardSelectDiv {
    display: flex;
    flex-direction: row;
    justify-content: center;
    padding: 80px 0 95px 0;
}

.WizardContactDiv .TopBlock{
    display: flex;
}

.WizardContactDiv .Form .CountryNumber{
}

.WizardContactDiv .Form .SecureDiv{
    /*display: flex;*/
    /*flex-direction: row;*/
    font-size: 14px;
    text-align: center;
}

.WizardContactDiv .Form .Button{
    width: auto;
        padding: 10px;
}


.WizardContactDiv div {
    display: flex;
    flex-direction: column;
}


.WizardContactDiv div .Input{
    padding: 5px;
    background-color:white;
    width: auto;
    margin: 2px;
}

.WizardContactDiv .InputComments{
    height: 110px;
    resize: none;
}

.WizardContactDiv{
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 40px 40px 0 40px;
    font-size: 24px;
}

.WizardSelectItem{
    box-shadow: 0 0 10px rgba(0,0,0,0.3);
    color:inherit;
    margin: 5px;
    width: 248px;
    height: 136px;
    text-align:center;
    background-color: white;
    font-size: 24px;
    color: black;
    display: flex;
    align-items: center;
    cursor: pointer;
}
.WizardLastBlock {
    padding: 80px 0 60px 0;
}

.WizardContactThank {
    font-size: 24px;
    padding: 30px 0 5px 0;
    max-width: 400px;
    margin: 0 auto;
    font-weight: 500;
    line-height: 32px;
}

.WizardLastBlockText {
    font-size: 24px;
    padding: 30px 0 0 0;
    max-width: 700px;
    margin: 0 auto;
    font-weight: 500;
    line-height: 32px;
}

.WizardThankBlock .PoweredByBlock {
    padding: 0 0 20px 0;
    align-items: flex-end;
    justify-content: flex-end;
}

.WizardSelectCurrentItem{
    background-color:#D8C393;
}

.WizardSelectItem:hover{
    color:white;
    background-color:#D19F46;
}

.WizardSelectItem >div{
    margin: auto;
}

.WizardSelectItem span{
  font-weight: bold;


}

.WizardSelectSteps .Item{
    padding: 4px 9px 4px 9px;
    margin: 15px 0;
    border: 1px solid #B4B4B4;
    border-radius: 100%;
    color: #B4B4B4;
    font-weight: bold ;

}

.WizardSelectSteps .Current{
    background-color:white;
    color:black;
    box-shadow: 0 0 10px rgba(0,0,0,0.3);
}

.WizardSelectSteps .Separator{
    border: 0.5px solid #B4B4B4;
    height: 100%;
    padding: 0;
    width: 0px;
    margin: auto;

}

.WizardSelectSteps .PrevItem,
.WizardSelectSteps .PrevSeparator
{
    cursor: pointer;
    color: white;
    background-color:#D8C393;
    border-color: #D8C393;
}

.StatusBar{
    width: 30px;
    height: 462px;
    border: 1px solid #B4B4B4;
    margin: 10px;
    padding: 10px;
    display: flex;
    flex-direction: column-reverse;
}

.StatusProgress{
    bottom: 0;
    width: 100%;
    height: 100%;
    background-image: url('../../elements/images/progress_bar_bgr.jpg');
    background-repeat: no-repeat;
}

.WizardLoading{
    -webkit-animation:spin 4s linear infinite;
    -moz-animation:spin 4s linear infinite;
    animation:spin 4s linear infinite;
    width: 300px;
}

.WizardLoadingBlock {
    padding: 78px 0;
    position: relative;
}


.WizardLoadingTitle{
    display: flex;
    align-items: baseline;
    position: absolute;
    left: 50%;
    top: 50%;
    -webkit-transform: translateX(-50%) translateY(-50%);
    -moz-transform: translateX(-50%) translateY(-50%);
    transform: translateX(-50%) translateY(-50%);
}

.WizardThankBlock HOne {
    width: 100px;
}

.WizardThankBlock .DealScoreImg {
    width: 119px;
    padding: 10px 20px;
}

#ButtonNext{
    padding: 7px 3px 7px 3px;
    width: 160px;
}

#ButtonPrev{
    padding: 7px 0px 7px 0px;
    width: 160px;
    color:black;
    border: 1px solid #B4B4B4;
    background-color:rgba(0,0,0,0.0);
    margin-right: 10px;
}

.WizardIcon{
    width: 20px;
}

.WizardThankBlock{
    /* height: 320px; */
}

.WizardGrid{

}

.WizardSelectSteps {
    display: flex;
    flex-direction: column;
    height: 350px;
    align-self: center;
}

.WizardBodyText {
    /*padding-right: 50px;*/
    margin: 0 auto;
    color: #5A5A5A;
    font-size: 16px;
    padding-top: 15px;
}

.WizardBodyText .PoweredByBottom {
    display: none;
    font-size: 12px;
    text-align: right;
}

.WizardThankBlock .PoweredBy,
.WizardBody .PoweredBy{
    text-align: left;
    margin-top: 20px;
    font-size: 14px;
}

@keyframes spin { 100% { -webkit-transform: rotate(360deg); transform:rotate(360deg); } }

@media all and (max-width: 1000px){

    .WizardFrame .StatusProgress{
        bottom: 0;
        width: 100%;
        height: 100%;
        background-image: url('../../elements/images/progress_bar_hor_bgr.jpg');
        background-repeat: no-repeat;
    }

    .WizardFrame .StatusBar {
        width: 90%;
        max-width: 462px;
        height: 30px;
        border: 1px solid #B4B4B4;
        margin: 10px;
        margin: 0 10px 40px 10px;
        display: flex;
        flex-direction: column-reverse;
    }

    .WizardBodyText .PoweredByBottom {
        display: block;
    }

    .WizardPage .BuySmartText1 {
        font-size: 21px;
        padding-top: 31px;
    }

    .WizardPage .BuySmartText2 {
        padding-top: 34px;
        padding-bottom: 12px;
        font-size: 17px;
    }



    #smartluxe .WizardPage .WizardBlock {
        flex-direction: column-reverse;
        width: auto;
        height: auto;
        padding: 40px 20px;
    }

    .WizardFrame {
        flex-direction: column-reverse;

        width: auto;
        height: auto;
    }


    .WizardBodyText {
        padding: 20px;
    }

    .WizardBody {
        max-width: none;
        padding-right:0;
    }


    .WizardSelectSteps {
        flex-direction: row;
        width: 350px;
        height: auto;
    }

    .WizardSelectSteps .Separator {
        border: 0.5px solid #B4B4B4;
        height: 0;
        padding: 0;
        width: 100%;
        margin: auto;
    }
    .WizardSelectDiv{
        margin-top: 0;
        padding: 0 0 20px 0;
    }

    .WizardSelectItem {
        font-size: 20px;
        height: 90px;
    }



    .WizardSelectSteps .Item{
        margin: 0 15px;
    }

    .WizardPage .Block {
        padding: 0;
    }



}


@media all and (max-width: 700px){
    .WizardSelectDiv {
        flex-direction: column;
    }

    .WizardSelectDiv .WizardSelectItem {
        margin: 10px auto;
    }
    .WizardContactDiv {
        padding: 10px;
    }

    #ButtonPrev, #ButtonNext {
        width: 45%;
    }

    .WizardSelectDivTwo {
        flex-direction: row!important;
        flex-wrap: wrap;
    }

    .WizardSelectItemTwo {
        width: 47%!important;
    }

    .WizardContactThank {
        font-size: 22px;
        padding: 10px 0 20px 0;
        line-height: 26px;
    }
    .WizardLastBlock {
        padding: 40px 0 20px 0;
    }

    .WizardLastBlockText {
        font-size: 22px;
        padding: 20px 0 0 0;
        line-height: 28px;
    }
}
