.ProjectCard {
  margin: 10px;
}
.ProjectCard .ShortTermIcon {
  position: absolute;
  z-index: 1000;
  top: 5px;
  left: 5px;
}
.ProjectCard .ShortTermIcon img {
  width: 110px;
}
.ProjectCard .OfferMain {
  margin: 0;
  height: 340px;
}
.ProjectCard .OfferMain .TopBlockOffer .OfferImgSubstrate {
  position: absolute;
  width: 100%;
  height: 100%;
  background: linear-gradient(#ffffff00, #ffffff00, #030303c7);
}
.ProjectCard .OfferMain .TopBlockOffer .SoldOutIcon {
  position: absolute;
  top: 0;
  right: 0;
}
.ProjectCard .OfferMain .BottomBlockOfferProject #FeatureLogo {
  justify-content: center;
  position: relative;
  display: flex;
  align-items: center;
  height: 70px;
}
.ProjectCard .OfferMain .BottomBlockOfferProject #FeatureLogo .Logo {
  max-width: 284px;
  max-height: 70px;
}
.ProjectCard .LastCard {
  width: 330px;
  height: 340px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);
  display: flex;
  justify-content: center;
}
.ProjectCard .LastCard .Logo {
  height: 100%;
  margin: auto;
  display: block;
}
.ProjectCard .LastCard .Button {
  padding: 10px 60px;
  position: absolute;
}
.ProjectCard .LastCardTop {
  align-items: center;
}
.ProjectCard .LastCardBottom {
  align-items: flex-end;
}
.ProjectCard .LastCardBottom .Button {
  bottom: 20px;
}
