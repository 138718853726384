.ListingPageModal {
  display: flex;
  align-items: baseline;
  overflow-y: auto;
  position: fixed;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  /*width: 100%;*/
  /*height: 100%;*/
  /*-webkit-transform: translateX(-50%) translateY(-50%);*/
  /*-moz-transform: translateX(-50%) translateY(-50%);*/
  /*transform: translateX(-50%) translateY(-50%);*/
  background-color: white;
  z-index: 12000;
  font-family: inherit;
  align-items: stretch;
  /*box-shadow: 20px 20px 70px #000000;*/
}
.ListingPageModal .ModalCarusel {
  z-index: 12003;
  position: relative;
}
.ListingPageModal .ListingPageModalBody {
  flex: 1;
  z-index: 12004;
  display: flex;
  flex-direction: column;
  justify-content: stretch;
}
.ListingPageModal .DealScoreContact {
  justify-content: flex-start;
}
.ListingPageModal .ListingPageModalBody .PageListingLine {
  flex-grow: 1;
  display: flex;
  width: auto;
}
.ListingPageModal .ListingPageModalBody .MediaMain {
  flex-grow: 1;
  /*height: 100%;*/
}
.ListingPageModal .ListingPageModalBody .MediaMain .MediaBlock {
  display: flex;
}
.ListingPageModal .ListingPageModalBody .slick-prev,
.ListingPageModal .ListingPageModalBody .slick-next {
  width: 30px;
  height: 30px;
}
.ListingPageModal .ListingPageModalBody .slick-prev:before,
.ListingPageModal .ListingPageModalBody .slick-next:before {
  font-size: 33px;
}
.ListingPageModal .ListingPageModalBody .SliderTop {
  background-color: #E0E0E0;
  display: flex;
  justify-content: stretch;
  width: 100%;
}
.ListingPageModal .ListingPageModalBody .SliderTop .TopSliderItem {
  height: auto;
  max-width: unset;
}
.ListingPageModal .ListingPageModalBody .SliderTop .TopSliderItem .TopSliderImg {
  max-width: 100%;
  max-height: 100%;
  height: auto;
  padding: 0;
  margin: auto auto;
}
.ListingPageModal .ListingPageModalBody .Item .Close {
  padding: 10px 0 10px 20px;
  font-weight: bold;
  cursor: pointer;
  font-size: 30px;
  font-family: gravesend-sans, sans-serif;
}
.ListingPageModal .ListingPageModalBody .ListinPageStaticHeader {
  max-width: unset;
}
.ListingPageModal .TopSliderItem {
  overflow: hidden;
  /* width: 73%; */
  max-width: 1134px;
  height: 500px;
  text-align: center;
  background-color: #ebebeb;
}
.ListingPageModal .TopSliderImg {
  max-width: 1134px;
  max-height: 500px;
  margin: 0 auto;
  height: auto;
}
.ListingPageModal .slick-next {
  right: 10px;
}
.ListingPageModal .slick-prev {
  left: 10px;
  z-index: 100;
}
.ListingPageModal .SliderTop {
  /*background-color: #E0E0E0;*/
}
.ListingPageModal .MainInfo .DealScoreAtTop .DealScoreLine {
  padding: 0;
  cursor: default;
}
.ListingPageModal .ListingPageModalBody .SliderTop {
  position: relative;
}
.ListingPageModal .ListingPageModalBody .SliderTop .Counter {
  color: white;
  border-radius: 5px;
  background-color: rgba(0, 0, 0, 0.8);
  padding: 5px 10px;
  font-weight: bold;
  position: absolute;
  top: 10px;
  left: 10px;
}
.ListingPageModal .ListingPageModalBody .SliderTop .slick-slider .slick-prev:before,
.ListingPageModal .ListingPageModalBody .SliderTop .slick-slider .slick-next:before {
  content: none;
}
.ListingPageModal .ListingPageModalBody .SliderTop .slick-slider .slick-next {
  right: 35px;
}
@media all and (max-width: 1000px) {
  .ListingPageModal .SliderNavi {
    display: none;
  }
  .ListingPageModal .TopSliderImg {
    width: 100%;
  }
  .ListingPageModal .TopSliderItem {
    height: auto;
  }
}
@media all and (max-width: 1200px) {
  .ListingPageModal .TopSliderItem {
    width: 100%;
    max-width: none;
  }
}
@media all and (max-width: 1100px) {
  .ListingPageModal .TopSliderItem {
    width: 100%;
    max-width: none;
  }
}
@media all and (max-width: 1150px) {
  .ListingPageModal .TopSliderItem {
    width: 100%;
    max-width: none;
  }
}
