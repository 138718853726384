.ContactUsForm{
    display: flex;
    justify-content: center;
}

.ContactUsForm .LeftBlock{
    padding:40px;
    background-color:#FAFAFA;
    width: 50%;
    text-align: right;
}

.ContactUsForm .RightBlock{
    padding:40px;
    width: 50%;
}

.ContactUsDiv{
    text-align: justify;
    margin-top: 20px;
}

.ContactUsDiv .TopBlock{
    display: flex;
}

.ContactUsForm .LeftBlock .FormDiv{
    width: 100%;
    max-width: 700px;
    float: right;
    padding-right: 20px;
}

.ContactUsForm .LeftBlock .FormDiv .HOne{
   margin-bottom: 30px;
}

.ContactUsForm .LeftBlock .FormDiv .Form{
    padding: 0 0 0 5px;
}

.RightBlock .ContactInfo {
    width: 100%;
    max-width: 700px;
    float: left;
    padding-left: 20px;
}

.BlockAfterForm .ThankYouBlock{
    display: flex;
    align-items:center;
    width: 200px;
}
.BlockAfterForm .ThankYouBlock #ThankIcon{
    width: 45px;
}

.ContactUsForm .BlockAfterForm{
    display: flex;
}

.TouchBlock{
    margin-top: 20px;
}

.TouchBlock span{
    font-weight: bold;
}

.TouchBlockLeft{
    margin-top: 20px;
    display: flex;
    flex: 1;
    flex-direction: column;
}

.TouchBlockRight{
    flex: 1;
    margin-top: 20px;
    display: flex;
    flex-direction: column;
}

.TouchBlockRight >div{
display: flex;
align-items: center;
}

.TouchBlockInfo{
    display: flex;
    flex-direction: row;
}

.TouchBlockInfo .TBItem {
    padding: 4px;
    display: flex;
    flex-direction: row;
    align-items: center;
}



.TouchBlockInfo .TBItem .TBItemText,
.TouchBlockInfo .TBItem .TBItemImage
{
    display: flex;
    align-items: flex-start;
    padding-right: 5px;
}
.TouchBlockInfo .TBItem .TBItemImage >svg{
    margin-left: 3px;
    margin-right: 2px;
}

.TouchBlockInfo .TBItem .SvgIcon > img{
    width: 32px;
    height: 20px;
    margin: -3px;
}

.TouchBlockInfo .TBItem .TBItemText
{
    padding-top: 3px;
}

.TouchBlockInfo .TBItem img {
    display: block;

}

.TouchBlockInfo .TBItem .TBItemImage .LinkedInIcon{
    width: 26px;
        filter: brightness(0) saturate(100%) invert(63%) sepia(66%) saturate(380%) hue-rotate(360deg) brightness(91%) contrast(91%);
}

.TouchBlockInfo a{
    color:#D19F46;
}

.TouchBlockInfo img{
    width: 25px;
}

.ContactUsForm .Input,
.ContactUsForm .Input::selection{
    /* width: auto; */
    flex: 1;
}

.ContactUsForm textarea {
    height: 110px;
    resize: none;
    background-color:inherit;
    margin: 2px;
    border: 1px solid #868686;
    font-family: inherit;
    border-radius:7px;
    padding: 5px 0 5px 5px;
    color: inherit;
}

.ContactUsForm .ButBlock {
    display: flex;
    flex-direction: column;
}

@media all and (max-width: 1000px){
    .ContactUsForm{
        flex-direction: column-reverse;

    }

    .ContactUsForm .LeftBlock{
        width: 100%;
        padding:0;
    }

    .ContactUsForm .LeftBlock{
        display: flex;
        width: 100%;
    }

    .ContactUsForm .LeftBlock .FormDiv {
        flex: 1;
        float: none;
        padding: 40px;
        max-width: none;

    }

    .ContactUsForm .RightBlock{
        width: 100%;
        display: flex;
        padding: 0;
    }

    .ContactUsForm .RightBlock .ContactInfo{
        flex: 1;
        float: none;
        padding: 40px;
    }

}

@media all and (max-width: 600px){
    .TopBlock {
        flex-direction: column;
    }
    .ContactUsForm .LeftBlock .FormDiv {
        padding: 20px 20px 50px 20px;
    }

    .ContactUsForm .RightBlock .ContactInfo{
        padding: 20px;
    }
}

@media all and (max-width: 1200px){
    .TouchBlockInfo {
        flex-direction: column;

    }

}


