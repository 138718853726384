.CompanyProjectMobile {
  font-family: "Montserrat", sans-serif;
  font-weight: bold;
  max-width: 1920px;
  position: relative;
}
.CompanyProjectMobile .Form .Button {
  width: 100%;
}
.CompanyProjectMobile .Form .SecureDiv .Text {
  font-weight: normal;
}
.CompanyProjectMobile .Description {
  padding: 15px 0;
  font-weight: normal;
  text-align: center;
}
.CompanyProjectMobile .TopPart .TopSlider {
  width: 100%;
  max-width: 1700px;
  margin: auto;
}
.CompanyProjectMobile .TopPart .TopSlider .TopHeader {
  position: absolute;
  z-index: 100;
  left: 16%;
  top: 17%;
}
.CompanyProjectMobile .TopPart .TopSlider .TopHeaderMobile {
  position: absolute;
  display: flex;
  width: 100%;
  height: 100%;
  flex-direction: column;
  justify-content: space-between;
}
.CompanyProjectMobile .TopPart .TopSlider .TopHeaderMobile .Slogan {
  margin-top: 40px;
}
.CompanyProjectMobile .TopPart .TopSlider .TopHeaderMobile .Slogan .HOne {
  z-index: 100;
}
@media all and (max-width: 650px) {
  .CompanyProjectMobile .TopPart .TopSlider .TopHeaderMobile .Slogan {
    width: 350px;
    align-self: center;
  }
}
.CompanyProjectMobile .TopPart .TopSlider .TopHeaderMobile .Buttons {
  width: 100%;
  max-width: 500px;
  align-self: center;
  margin-bottom: 40px;
  display: flex;
  justify-content: space-evenly;
}
.CompanyProjectMobile .TopPart .TopSlider .TopHeaderMobile .Buttons .ContactWAButton {
  flex: 1;
  z-index: 100;
  display: flex;
  justify-content: center;
  align-items: center;
  max-width: 190px;
  padding: 5px 14px;
  font-size: 20px;
  font-family: Montserrat, Arial, sans-serif;
  font-weight: bold;
  margin: 0 5px;
}
.CompanyProjectMobile .TopPart .TopSlider .TopHeaderMobile .Buttons .ContactWAButton .ContactWA {
  width: 340px;
}
.CompanyProjectMobile .TopPart .TopSlider .TopHeaderMobile .Buttons .Button {
  z-index: 100;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 190px;
  padding: 5px 0;
}
.CompanyProjectMobile .TopPart .TopSlider .TopHeaderMobile .Buttons .Button .Icon {
  width: 20px;
}
@media all and (max-width: 500px) {
  .CompanyProjectMobile .TopPart .TopSlider .TopHeaderMobile .Buttons .Button {
    width: 150px;
  }
}
@media all and (max-width: 850px) {
  .CompanyProjectMobile .TopPart .TopSlider .TopHeaderMobile .HOne h2 {
    font-size: 30px;
  }
}
@media all and (max-width: 650px) {
  .CompanyProjectMobile .TopPart .TopSlider .TopHeaderMobile .HOne h2 {
    font-size: 30px;
  }
}
.CompanyProjectMobile .TopPart .TopSlider .slick-slider .slick-list .slick-track .slick-slide > div {
  display: flex;
}
.CompanyProjectMobile .TopPart .TopSlider .slick-slider .slick-dots .slick-active .CustomDot {
  border: 1px solid white;
  background-color: #D19F46;
}
.CompanyProjectMobile .TopPart .TopSlider .slick-slider .slick-dots .CustomDot {
  width: 15px;
  height: 15px;
  border-radius: 100%;
  background-color: white;
  margin: -65px 0;
}
@media all and (max-width: 1200px) {
  .CompanyProjectMobile .TopPart .TopSlider .TopHeader {
    z-index: 100;
  }
  .CompanyProjectMobile .TopPart .TopSlider .TopHeader .HOne h2 {
    font-size: 30px;
  }
}
@media all and (max-width: 1000px) {
  .CompanyProjectMobile .TopPart {
    flex-direction: column;
    align-items: center;
  }
}
.ProjectCompanyMobileSlider .ItemBox {
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #f1f0f0;
}
.ProjectCompanyMobileSlider .ItemBox .Item {
  min-height: 100%;
  flex-shrink: 0;
  height: 100%;
  margin: auto;
  overflow: hidden;
}
