.FindMyDeal {
     width: 100%;
     background-color: #121212;
 }

.FindMyDeal .PoweredMain .Button {
    white-space: nowrap;
}

.PoweredMain >img{
    width: 550px;
}

.PoweredMain .CenterBlock{
    color: white;
    text-align: center;
    padding-top: 50px;
    height: 346px;
}

.PoweredMain .TextBlock{
    font-size: 22px;
    padding-bottom: 10px;
    margin-bottom: 10px;
}

.CenterBlock a{
    padding: 10px 70px 10px 70px;
    margin: 0px 45px 0 45px;

}

.CenterBlock > div{
    margin-top: 30px;
}


.PoweredMain{
    background-color: black;
    display: flex;
    justify-content: space-between;
    flex-direction: row;
    padding-bottom: 20px;
}

.leftBgrImage {
    width: 30%;
    background-image: url('../../elements/images/dots_left.png');
    background-position: left;
    background-repeat: no-repeat;
}

.rtBgrImage {
    width: 30%;
    background-image: url('../../elements/images/dots_right.png');
    background-position: right;
    background-repeat: no-repeat;

}


@media all and (max-width: 1024px){
    .CenterBlock h1 {
        font-size: 22px;
    }
    .CenterBlock .TextBlock {
        font-size: 18px;
    }

    .CenterBlock a {
        padding: 7px 30px 7px 30px;
        margin: 0px 45px 0 45px;
        font-size: 15px;
    }
    .CenterBlock {
        padding-top: 10px;
        height: 300px;
    }

}