.LinkTreeFooter {
  width: 100%;
  background-color: #000000;
  height: 100px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-direction: column;
  flex: 0 0 auto;
  min-height: 200px;
  color: white;
  font-size: 10px;
}
.LinkTreeFooter a {
  margin-top: 20px;
  margin-bottom: 50px;
}
.LinkTreeFooter img {
  height: 65px;
}
@media all and (max-width: 500px) {
  .LinkTreeFooter img {
    height: 33px;
  }
}
.LinkTreePage {
  flex: 1 0 auto;
  background-color: #656565;
  padding-top: 20px;
}
.LinkTreePage .SocialBlock {
  padding: 15px 0;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}
.LinkTreePage .SocialBlock a {
  color: white;
  font-weight: bold;
}
.LinkTreePage .SocialBlock a :hover {
  background-color: white;
  color: black;
  transition: all 0.3s ease-out;
}
.LinkTreePage .SocialBlock .Logo {
  min-height: 50px;
}
.LinkTreePage .SocialBlock .Logo img {
  border-radius: 50%;
}
.LinkTreePage .SocialBlock .AgentName {
  color: white;
  font-weight: bold;
  font-size: 25px;
  margin-top: 10px;
}
.LinkTreePage .SocialBlock .Links {
  margin-top: 20px;
  width: 60%;
  min-width: 300px;
  max-width: 900px;
}
.LinkTreePage .SocialBlock .Links .Link {
  margin: 15px 0;
  width: 100%;
  text-align: center;
  padding: 15px 0;
  border-radius: 100px;
  border: solid 2px #D19F46;
}
