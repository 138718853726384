/* .NeighboorSliderItem{
    margin:10px;
    position: relative;
    background-color:linear-gradient(0deg, rgba(34,193,195,0) 0%, rgba(0,0,0,0) 100%)
}

.NeighboorSliderItem >img{
    width: 100%;
}

.NeighboorSliderItem > div{
    position: absolute;
    bottom: 10px;
    width: 100%;
    color:white;
    font-size: 18px;
    font-weight: bold;
    font-family: "Montserrat";
}

.NeighboorSliderItem .Overlay{
   
    bottom:0;
} */

.HeighborhoodBlock {
    text-align: center;
    max-width: 1520px;
    /* margin: 70px auto 100px auto; */
    margin: 0 auto;
    padding: 10px 0 40px 0;
}

.HeighborhoodBlock .slick-next
{
    margin-right: 10px;
    font-size: 20px;
}


.HeighborhoodBlock .slick-prev{
    margin-left: 10px;
}



.HeighborhoodBlock .slick-next:before,
.HeighborhoodBlock .slick-prev:before
{
    color: white ;
    text-shadow: black 1px 1px 1px;
    padding-right: 10px;
}