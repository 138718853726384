.supIcon {
  vertical-align: sub;
  line-height: 10px;
  font-size: 26px;
  cursor: pointer;
}
.MessageCustomTitle {
  font-size: 25px;
  text-align: center;
  line-height: 30px;
}
@media all and (max-width: 600px) {
  .MessageCustomTitle {
    font-size: 20px;
    line-height: 25px;
  }
}
