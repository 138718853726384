
.NeighborhoodTopBlock{
    display: flex;
    justify-content: center;
    margin-bottom: 10px;
    margin: 0 auto;
    padding: 0 30px;
}

#NeighborhoodMap{
    padding-top: 52px;
}

.NeighborhoodDescription {
    font-size: 16px;
    line-height: 24px;
    padding-top: 25px;
}


.NeighborhoodTopBlock .LeftBlock{
    float: left;
    text-align: left;
    margin-right: 30px;

}

.NeighborhoodPageBody {
    max-width: 1920px;
    margin: 0 auto;
}

.NeighborhoodPageBody .FeatureProducts{
    padding-top: 10px;    
}
#NeighborhoodDealsBlock{
    text-align: left;
    /* margin-bottom: 40px; */
    padding: 0 30px;
}

#NeighborhoodMap img{
    width: 800px;
    height: auto;
}

.NeighborhoodLine .NeighborhoodLineTitle{
    padding-left: 30px;
}
.NeighborhoodLine:nth-child(n){
    background-color: white;
}
.NeighborhoodLine:nth-child(2n){
    background-color: rgb(245, 245, 245);
}


@media all and (max-width: 1500px){

    #NeighborhoodMap img{
        width: 600px;
        height: auto;
    }

}

@media all and (max-width: 1300px){

    #NeighborhoodMap img{
        width: 500px;
        height: auto;
    }

}

@media all and (max-width: 1200px){

    #NeighborhoodMap img{
        display: none;
    }

}