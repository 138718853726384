.PrivacyPage{
    width: 100%;
    padding-top: 130px;
    padding-bottom: 80px;
}

#PrivacyBlock{
    margin: 0 auto;
    max-width: 940px;
    padding: 40px 40px 20px 40px;
}

#PrivacyBlock .PrivacyText{
    text-align: left;
    margin-top: 40px;
}

.PrivacyItem{
    margin-top: 20px;
}

.PrivacyItem >div{
    color:#5A5A5A;
    font-size: 18px;
    font-weight: bold;
    margin-bottom: 7px;
}
