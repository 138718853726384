.ProjectInner {
  max-width: 820px;
}
.ProjectInner .BottomGallery {
  display: flex;
  overflow: hidden;
  width: 100%;
  flex-wrap: wrap;
  justify-content: space-around;
  align-items: center;
}
.ProjectInner .MuiBox-root {
  width: 100%;
}
.ProjectInner .Photo {
  max-width: 30%;
  padding: 5px;
  width: 100%;
  display: block;
  cursor: pointer;
}
.ProjectInner .HighlightsIllustration {
  width: 100%;
  display: block;
  border-bottom: 1px solid #E0E0E0;
}
.ProjectInner .ParallaxDisabled {
  position: relative;
}
.ProjectInner .ParallaxContent {
  background-color: inherit;
  position: relative;
  color: white;
}
.ProjectInner .ParallaxContent .Feature {
  color: white;
}
.ProjectInner .ParallaxItem {
  width: 100%;
  height: 100%;
  position: absolute;
  inset: 0;
}
.ProjectInner .ParallaxItem #ParallaxPropertyFeatures,
.ProjectInner .ParallaxItem #ParallaxPropertyAmenties,
.ProjectInner .ParallaxItem #ParallaxPropertyResidenceFeatures {
  height: 190%;
  position: absolute;
  inset: 0;
  filter: brightness(0.4);
}
.ProjectInner .CustomList {
  column-count: 2;
}
@media all and (max-width: 700px) {
  .ProjectInner .CustomList {
    column-count: 1;
  }
}
.ProjectInner .ReadMore {
  color: #D19F46;
  font-weight: 700;
  font-size: 16px;
  text-transform: uppercase;
}
.ProjectInner .ProjectInnerVideo .player-wrapper {
  width: auto;
  height: auto;
}
.ProjectInner .ProjectInnerVideo .react-player {
  padding-top: 56.25%;
  position: relative;
}
.ProjectInner .ProjectInnerVideo .react-player > div {
  top: 0;
  left: 0;
  position: absolute;
}
.ProjectInner .ReadMore {
  cursor: pointer;
}
.ProjectInner .slider {
  height: 400px;
  overflow: hidden;
}
.ProjectInner .slider .ShortTermIcon {
  display: none;
}
@media all and (max-width: 1000px) {
  .ProjectInner .slider .ShortTermIcon {
    display: block;
    position: absolute;
    z-index: 1000;
    width: 100px;
    top: 140px;
    left: 10px;
  }
}
.ProjectInner .slider .ProjectSlider .slick-slider {
  height: 400px;
}
.ProjectInner .slider .ProjectSlider .slick-slider .slick-prev,
.ProjectInner .slider .ProjectSlider .slick-slider .slick-next {
  top: 48%;
  z-index: 100;
}
.ProjectInner .slider .ProjectSlider .slick-slider .slick-prev {
  left: 9px;
}
.ProjectInner .slider .ProjectSlider .slick-slider .slick-slide {
  cursor: pointer;
}
.ProjectInner .slider .ProjectSlider #ProjectSliderModal .slick-slider {
  height: auto;
}
.ProjectInner .slider .ProjectSlider #ProjectSliderModal .slick-slider .slick-prev,
.ProjectInner .slider .ProjectSlider #ProjectSliderModal .slick-slider .slick-next {
  top: 50%;
}
.ProjectInner .ProjectInnerHdr {
  display: flex;
  flex-direction: row;
  align-items: center;
  position: relative;
}
.ProjectInner .ProjectInnerHdr .soldOut {
  position: absolute;
  right: 20px;
  top: -105px;
  margin: auto;
  width: 240px;
  height: auto;
  z-index: 9000;
}
@media all and (max-width: 700px) {
  .ProjectInner .ProjectInnerHdr .soldOut {
    left: 0;
    right: 0;
    margin: auto;
  }
}
.ProjectInner .ProjectInnerHdr .ProjectInnerName {
  font-size: 34px;
  font-weight: 700;
}
.ProjectInner .ProjectInnerHdr .ProjectInnerAddress {
  font-size: 24px;
  font-weight: 400;
  color: #5A5A5A;
}
.ProjectInner .ProjectInnerHdr .Sep {
  width: 250px;
  border-bottom: 1px solid #E0E0E0;
  margin: 30px auto;
}
@media all and (max-width: 1000px) {
  .ProjectInner .ProjectInnerHdr {
    flex-direction: column;
    text-align: center;
  }
}
.ProjectInner .ProjectInnerHdr .ProjectInnerLogo {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 270px;
  height: 85px;
}
@media all and (max-width: 1000px) {
  .ProjectInner .ProjectInnerHdr .ProjectInnerLogo {
    width: auto;
    height: auto;
  }
}
.ProjectInner .ProjectInnerHdr .ProjectInnerLogo .ProjectLogo {
  max-width: 270px;
  max-height: 85px;
}
@media all and (max-width: 1000px) {
  .ProjectInner .ProjectInnerHdr .ProjectInnerLogo .ProjectLogo {
    margin-bottom: 20px;
    max-width: 240px;
    max-height: initial;
  }
}
.ProjectInner .NameAndAddress {
  display: flex;
  flex-direction: column;
  flex: 1;
  justify-content: center;
}
.ProjectInner .ProjectInnerBox {
  padding: 48px 60px 48px 60px;
  border-bottom: 1px solid #E0E0E0;
}
.ProjectInner .ProjectInnerBox .ShortTermIcon {
  width: 120px;
}
.ProjectInner .ProjectInnerBox .BuildingLine {
  display: flex;
  justify-content: space-evenly;
  margin: 0 50px 10px 50px;
  flex-direction: row;
  align-items: center;
}
@media all and (max-width: 700px) {
  .ProjectInner .ProjectInnerBox .BuildingLine {
    flex-direction: column;
    margin: 0 15px 0 0;
  }
}
.ProjectInner .ProjectInnerBox .BuildingLine .Step {
  width: 33px;
  height: 33px;
}
.ProjectInner .ProjectInnerBox .BuildingLine .StepPoint {
  width: 5px;
  height: 5px;
}
@media all and (max-width: 700px) {
  .ProjectInner .ProjectInnerBox .BuildingLine .StepPoint {
    margin: 3px 0;
  }
}
@media all and (max-width: 700px) {
  .ProjectInner .ProjectInnerBox {
    padding: 40px 20px 18px 20px;
  }
}
.ProjectInner .BuildingMarks {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  color: black;
  font-size: 14px;
  font-weight: 700;
}
@media all and (max-width: 700px) {
  .ProjectInner .BuildingMarks {
    flex-direction: column;
    justify-content: space-between;
  }
}
.ProjectInner .BuildingMarks div {
  width: auto;
  text-align: center;
}
@media all and (max-width: 700px) {
  .ProjectInner .BuildingMarks div {
    display: flex;
    height: 30px;
    width: auto;
    text-align: left;
    align-items: center;
  }
}
.ProjectInner .BuildingSteps {
  position: relative;
  display: flex;
  flex-direction: column;
  padding: 20px 40px 0 40px;
}
@media all and (max-width: 700px) {
  .ProjectInner .BuildingSteps {
    flex-direction: row;
    justify-content: center;
  }
}
.ProjectInner .InfoModal {
  min-width: 300px;
  min-height: 200px;
  padding: 20px;
  display: flex;
  justify-content: center;
  align-content: center;
  align-items: center;
}
.ProjectInner .InfoModal .est {
  display: block;
  font-size: 14px;
  color: #5A5A5A;
}
.ProjectInner .infoIcon {
  cursor: pointer;
  position: absolute;
  top: -15px;
  right: -7px;
}
.ProjectInner .ProjectInnerForm {
  display: flex;
  flex-direction: row;
}
.ProjectInner .ProjectInnerForm .ProjectInnerFormDesc {
  flex: 1;
}
.ProjectInner .ProjectInnerForm .ProjectInnerFormDesc .FeelFree {
  font-size: 16px;
  color: #5A5A5A;
  line-height: 24px;
  margin-top: 30px;
}
.ProjectInner .ProjectInnerForm .ProjectInnerFormDesc .sep {
  width: 139px;
  border-bottom: 1px solid #E0E0E0;
  margin: 19px 0 0 0;
}
.ProjectInner .ProjectInnerForm .ProjectInnerFormDesc .SecureDiv {
  margin-top: 29px;
  display: flex;
  flex-direction: row;
  text-align: left;
  font-size: 16px;
  color: #5A5A5A;
  align-items: flex-start;
}
.ProjectInner .ProjectInnerForm .ProjectInnerFormDesc .SecureDiv .SecureIcon {
  padding-right: 10px;
}
.ProjectInner .ProjectInnerForm .ProjectInnerFormDesc .ProjectLogo {
  margin-top: 30px;
  max-width: 250px;
  display: block;
  max-height: 150px;
}
@media all and (max-width: 1000px) {
  .ProjectInner .ProjectInnerForm .ProjectInnerFormDesc .ProjectLogo {
    margin: 20px auto;
  }
}
.ProjectInner .ProjectInnerForm .TextAndForm {
  display: flex;
  flex-direction: row;
}
.ProjectInner .ProjectInnerForm .Text {
  flex: 1;
}
.ProjectInner .ProjectInnerForm .InnerForm .Button {
  width: 100%;
}
@media all and (max-width: 1000px) {
  .ProjectInner .ProjectInnerForm {
    flex-direction: column;
    align-items: center;
    max-width: 450px;
    margin: auto;
  }
  .ProjectInner .ProjectInnerForm .SecureDiv {
    display: flex;
    margin: 0;
    justify-content: left;
    align-items: flex-start;
    margin-top: 10px;
  }
  .ProjectInner .ProjectInnerForm .SecureDiv .Text {
    text-align: left;
    padding: 0;
  }
  .ProjectInner .ProjectInnerForm .ProjectInnerFormDesc .FeelFree {
    text-align: center;
  }
}
.ProjectInner .ProjectInnerPropDesc .PropDescBlock {
  display: flex;
  flex-direction: row;
}
@media all and (max-width: 800px) {
  .ProjectInner .ProjectInnerPropDesc .PropDescBlock {
    flex-direction: column;
  }
}
.ProjectInner .ProjectInnerPropDesc .PropDesc {
  flex: 7;
  margin-right: 30px;
  font-size: 16px;
  color: #5A5A5A;
  line-height: 24px;
}
.ProjectInner .ProjectInnerPropDesc .PropDesc p {
  padding-bottom: 14px;
}
.ProjectInner .ProjectInnerPropDesc .PropDescRt {
  flex: 4;
}
.ProjectInner .ProjectInnerPropDesc .PropDescRt .DescPart {
  font-size: 16px;
  position: relative;
  line-height: 23px;
  padding-bottom: 15px;
  border-bottom: 1px solid #E0E0E0;
  margin-bottom: 15px;
}
.ProjectInner .ProjectInnerPropDesc .PropDescRt .DescPart .infoIcon {
  right: 5px;
  top: -9px;
}
.ProjectInner .ProjectInnerPropDesc .PropDescRt .DescPart .Hdr {
  font-weight: 500;
}
@media all and (max-width: 800px) {
  .ProjectInner .ProjectInnerPropDesc .PropDescRt {
    margin-top: 50px;
    margin-bottom: 40px;
  }
}
.ProjectInner .ProjectInnerFeature {
  overflow: hidden;
  position: relative;
}
.ProjectInner .ProjectInnerFeature .ProjectInnerFeaturesBlock {
  font-size: 16px;
  color: #000000;
  font-weight: 500;
  display: flex;
  flex-direction: row;
}
.ProjectInner .ProjectInnerFeature .ProjectInnerFeaturesBlock .FeaturesBlockLt {
  margin-right: 80px;
}
.ProjectInner .ProjectInnerFeature .ProjectInnerFeaturesBlock .FeaturesBlock {
  margin-top: 30px;
  flex: 1;
}
.ProjectInner .ProjectInnerFeature .ProjectInnerFeaturesBlock .FeaturesBlock .FeatureBlocksHdr {
  margin-bottom: 31px;
  color: #5A5A5A;
  font-size: 14px;
  text-transform: uppercase;
}
.ProjectInner .ProjectInnerFeature .ProjectInnerFeaturesBlock .FeaturesBlock .FeatureBlock {
  display: flex;
  flex-direction: row;
  margin-bottom: 30px;
}
.ProjectInner .ProjectInnerFeature .ProjectInnerFeaturesBlock .FeaturesBlock .FeatureBlock .Bul {
  background-color: #B4B4B4;
  width: 17px;
  height: 2px;
  margin-right: 10px;
  margin-top: 8px;
}
.ProjectInner .ProjectInnerArticles a:visited {
  color: inherit;
}
.ProjectInner .ProjectInnerArticles .HOne {
  margin-bottom: 40px;
}
.ProjectInner .ProjectInnerArticles .ProjectInnerArticle {
  display: flex;
  flex-direction: row;
  position: relative;
  justify-content: stretch;
  /* border: 1px solid black; */
  margin: 0 0 10px 0;
  background-color: white;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);
  /* Параметры тени */
  align-items: center;
}
.ProjectInner .ProjectInnerArticles .ProjectInnerArticle .ProjectInnerArticleImage {
  min-width: 200px;
  max-width: 210px;
  display: flex;
  overflow: hidden;
  height: 250px;
}
.ProjectInner .ProjectInnerArticles .ProjectInnerArticle .ProjectInnerArticleImage img {
  display: block;
}
@media all and (max-width: 720px) {
  .ProjectInner .ProjectInnerArticles .ProjectInnerArticle .ProjectInnerArticleImage {
    width: 100%;
    max-width: none;
  }
}
.ProjectInner .ProjectInnerArticles .ProjectInnerArticle .ProjectInnerArticleText {
  margin: 21px 35px 23px 35px;
  font-size: 14px;
}
.ProjectInner .ProjectInnerArticles .ProjectInnerArticle .ProjectInnerArticleText .ArticleDate {
  display: flex;
  margin-bottom: 10px;
}
.ProjectInner .ProjectInnerArticles .ProjectInnerArticle .ProjectInnerArticleText .ArticleDate img {
  display: block;
  margin-right: 8px;
}
.ProjectInner .ProjectInnerArticles .ProjectInnerArticle .ProjectInnerArticleText .ArticleTextHdr {
  color: #000000;
  font-size: 16px;
  font-weight: 700;
  margin-bottom: 10px;
}
.ProjectInner .ProjectInnerArticles .ProjectInnerArticle .ProjectInnerArticleText .ArticleText {
  color: #5A5A5A;
}
@media all and (max-width: 1000px) {
  .ProjectInner {
    max-width: none;
  }
  .ProjectInner .ProjectInnerVideo {
    padding: 0;
  }
}
@media all and (max-width: 850px) {
  .ProjectInner .ProjectInnerArticles .ProjectInnerArticle .ProjectInnerArticleText {
    font-size: 12px;
  }
}
@media all and (max-width: 720px) {
  .ProjectInner .ProjectInnerArticles .ProjectInnerArticle {
    flex-direction: column;
  }
  .ProjectInner .ProjectInnerArticles .ProjectInnerArticle .ProjectInnerArticleImage {
    max-height: 200px;
    overflow: hidden;
    display: flex;
    align-items: center;
  }
  .ProjectInner .ProjectInnerArticles .ProjectInnerArticle .ProjectInnerArticleImage img {
    display: flex;
    align-items: center;
    width: 100%;
  }
}
