.NotFoundPage{
    width: 100%;
    padding-top: 138px;
    padding-bottom: 80px;
}

.ErrorImage{
    width: 500px;
    display: block;
    margin: auto;
}