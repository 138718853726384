#SelectedProjectOffer {
  box-shadow: 0px 0px 15px 0px rgba(0, 0, 0, 0.7);
}
.ProjectOffer {
  display: flex;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);
  width: 100%;
  margin: 8px 0;
}
.ProjectOffer .Logo {
  width: 160px;
  overflow: hidden;
  height: 100px;
}
.ProjectOffer .Logo img {
  width: 100%;
}
.ProjectOffer .RightBlock {
  flex: 1 0;
}
.ProjectOffer .RightBlock .TopPart {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin: 15px;
}
.ProjectOffer .RightBlock .TopPart .Feedback {
  position: relative;
  flex: 1 1;
}
.ProjectOffer .RightBlock .TopPart .Title {
  font-size: 20px;
  font-weight: bold;
}
.ProjectOffer .RightBlock .Button {
  background-color: inherit;
  color: #D19F46;
  font-weight: bold;
  padding: 5px 15px;
  border: 1px solid #D19F46;
  border-radius: 20px;
  float: right;
  cursor: pointer;
}
.ProjectOffer .RightBlock .ProjectOfferTitle {
  font-style: italic;
  text-align: left;
  padding: 0 15px;
}
.ProjectOffer a {
  color: inherit;
}
.ProjectOffer a:visited {
  color: inherit;
}
@media all and (max-width: 1000px) {
  .ProjectOffer {
    flex-direction: column;
    width: 330px;
    height: 320px;
    margin: 10px;
  }
}
