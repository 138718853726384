#SelectionTopSliderImg{
    width: auto;
}

#SelectionTopSliderItem{
    background-color: rgb(29, 29, 29);
}

#SelectionTopSlider{
    background-color: rgb(29, 29, 29);
}

#SelectionTopSlider .Counter{
    background-color:rgba(255,255,255,0.7);
    color:black;
}

#SelectionListingHeader{

}


.ListingPageModalBody .TitieAndDisc h2{
    margin: 0 auto;
    color: black;
}
.CloseLine{
    display: none;
}

.ListingPageModalBody .Buttons{
    display: none;
    position: absolute;
    bottom:40px;
    width: 100%;
    align-self: center;
    justify-content:space-evenly;
}

.ListingPageModalBody .Buttons .ContactWA{
         width: 290px;
}

.ListingPageModalBody .Buttons .Button .Icon{
    width:20px;
}

@media all and (max-width: 1000px){
    #SelectionListingHeader{
        display: none;
    }

    .CloseLine{
        display: block;
        position: absolute;
        right: 20px;
        top: 10px;
        color: white;
        font-weight: bold;
        cursor: pointer;
        font-size: 30px;
        font-family: gravesend-sans, sans-serif;
    }

    .ListingPageModalBody .Buttons{
        display: flex;
    }
}