.ProjectSliderModalImg {
  margin: auto;
  max-width: 100%;
  max-height: 900px;
}
#ProjectSliderModal {
  min-width: 700px;
  margin: auto;
}
#ProjectSliderModal .slick-list {
  width: 100%;
}
#ProjectSliderModal .slick-list .slick-track {
  display: flex;
  align-items: center;
}
#ProjectSliderModal .slick-list .slick-track .slick-slide {
  display: hidden;
}
#ProjectSliderModal .slick-list .slick-track .slick-current {
  display: block;
}
#ProjectSliderModal .slick-prev:before,
#ProjectSliderModal .slick-next:before {
  content: '' !important;
}
#ProjectSliderModal .slick-next {
  right: 45px;
}
#ProjectSliderModal .slick-prev {
  left: 10px;
  z-index: 100;
}
.slick-slide {
  outline: none;
}
.ModalNoFelx {
  height: 100%;
}
.ModalNoFelx .slick-slider {
  position: relative;
  top: 50%;
  -webkit-transform: translateY(-50%);
  -moz-transform: translateY(-50%);
  transform: translateY(-50%);
}
.ModalNoFelx .slick-prev,
.ModalNoFelx .slick-next {
  z-index: 1000;
}
.ModalNoFelx .slick-prev {
  left: 5px;
}
.ModalNoFelx .slick-prev img {
  width: 30px;
  height: 30px;
}
.ModalNoFelx .slick-next {
  right: 15px;
}
.ModalNoFelx .slick-next img {
  width: 30px;
  height: 30px;
}
.ModalNoFelx .slick-next:before {
  content: '' !important;
}
.ModalNoFelx .slick-prev:before {
  content: '' !important;
}
.ModalNoFelx .slick-track > div {
  margin-top: auto;
  margin-bottom: auto;
}
.ProjectSliderModalParent {
  display: flex;
  height: 100%;
  width: 100%;
  justify-content: space-between;
}
.ProjectSliderModalParent .ProjectSliderModalBlock {
  display: block;
  margin: auto;
}
.ProjectSliderModalParent .ProjectSliderModalBlock .Counter {
  color: white;
  padding: 5px 10px;
  background-color: rgba(1, 1, 1, 0.8);
  border-radius: 5px;
  position: absolute;
  top: 10px;
  left: 10px;
  font-weight: bold;
  z-index: 1000;
}
.ProjectSliderModalParent .ProjectSliderContact {
  border-left: 0.2mm solid #494949;
}
.ProjectSliderContact {
  padding: 40px;
  min-width: 250px;
  width: 350px;
  text-align: center;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  color: white;
}
.ProjectSliderContact .Logo {
  max-width: 160px;
}
.ProjectSliderContact .LogoTitle {
  font-size: 20px;
}
.ProjectSliderContact .Urgency {
  margin-top: 15px;
  font-size: 14px;
}
#ProjectSliderThankBlur {
  background-color: unset;
  backdrop-filter: unset;
}
@media all and (max-width: 1600px) {
  .ProjectSliderModalParent {
    height: auto;
  }
}
@media all and (max-width: 1000px) {
  .ProjectSliderModalParent {
    display: block;
  }
  .ProjectSliderModalParent .ProjectSliderContact {
    width: auto;
    display: none;
  }
  #ProjectSliderModal {
    min-width: auto;
  }
  #ProjectSliderModal .slick-prev:before,
  #ProjectSliderModal .slick-next:before {
    content: '' !important;
    width: 30px;
  }
  #ProjectSliderModal .slick-next {
    right: 20px;
  }
  #ProjectSliderModal .slick-next > img {
    width: 30px;
  }
  #ProjectSliderModal .slick-prev > img {
    width: 30px;
  }
}
