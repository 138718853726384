.SliderTop .StatusBlock {
  position: absolute;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}
.SliderTop .StatusBlock img {
  width: 250px;
  z-index: 1;
}
@media all and (max-width: 500px) {
  .SliderTop .StatusBlock img {
    width: 150px;
  }
}
