.LoadingComponent {
  -webkit-animation: spin 4s linear infinite;
  -moz-animation: spin 4s linear infinite;
  animation: spin 4s linear infinite;
  width: 200px;
  margin-top: 40px;
  position: absolute;
  top: 37vh;
}
@media all and (max-width: 750px) {
  .LoadingComponent {
    width: 100px;
  }
}
@media all and (max-width: 550px) {
  .LoadingComponent {
    width: 70px;
  }
}
.LoadingBlock {
  display: flex;
  justify-content: center;
  padding: 0;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}
