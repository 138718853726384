.MainPageVideo{
    width: 100%;
    display: flex;
    max-width: 1920px;
    border-width: 0;
    height: 1400px;
    pointer-events: none;
    background-color: #ffffff;
}

.MainPage{
    width: 100%;
    padding-top: 138px;
}

.Video {
    width: 100%;
    background-color: #ebebeb;
}

.MainPageVideoDiv{
    margin: 0 auto;
    align-items: center;
    text-align: center;
    display: flex;
    justify-content: flex-start;
    overflow: hidden;
    /* width: 100%; */
    height: 27vw;
    max-height: 540px;
    max-width: 1920px;
    position: relative;
}

.SloganWord{

}

.MainPage .Slogan{
    letter-spacing: 1px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    min-width:885px;
    height: 180px;
    overflow: hidden;
    position: absolute;
    color: white;
    z-index: 100;
    font-size:60px;
    text-align: center;
    font-family:  'Red Hat Display', sans-serif;
   
    font-weight: 900;
    -webkit-text-stroke-width: 1px;
    -webkit-text-stroke-color: rgba(0,0,0, .3);

    height: 100%;
}



.MainPage .Slogan .SloganSmall{
    font-family: Arial, sans-serif;
    font-size: 49px;
}

.MainPage .Slogan .SloganSmall .SloganLine{
    margin-left: 40px;
    margin-right: 20px;
}

.MainPage .Slogan .SloganSmall >a{
    color:white;
    font-size: 20px;
}

.MainPageBody{
     margin-top: -21px;
 }

 .ProductBlock{
    /* display: grid; */
 }

 .ProductBlock .HOne{
    padding: 1px;
 }
 
 .ProductBlock:nth-child(2n+1) {
    background-color:rgb(245, 245, 245);
 }
 
 .ProductBlock:nth-child(2n){
    background-color: white;
 }

.FeaturedSmartDeals {
    width: 100%;
  
}

.Neighborhoods {
    width: 100%;
  

}

.MoreArticles{
    padding-top: 30px;
   
}

.OurValueBlock {
    width: 100%;
    background-color: white;
    max-width: 1500px;
    margin: 0 auto;
    text-align: center;
}

.ValuesBody{
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    margin-bottom: 50px;



}

.ValuesBody >div{
    width: 24%;
    text-align: center;
}
.ValuesBody .Text{
    font-size: 16px;
    color:#5A5A5A;
}

.ValuesBody .Separator{
    width: 80px;
    height: 2px;
    background-color:black;
    margin: auto;
    margin-bottom: 20px;
}
.ValuesBody h2{
    word-spacing: 9999999px;
}

.PlayerDiv{
    /* min-height: calc((100vw - 17px) * 0.28125); */
    max-width: 1920px;
    width: 100%;
    /*position: relative;*/
}

.PlayerDiv .Location{
    z-index: 1000;
    position: absolute;
    left: 0;
    bottom:0;
    font-size: 28px;
    font-weight: normal;
    padding: 3px 20px;
    display: block;
    color: #ffffff;
    background-color: rgba(4, 90, 166, 0.35);
}

.OurValueBlock{
    padding-top: 40px;
}

.MainPage .Slogan .SloganPart2,
.MainPage .Slogan .SloganPart2{
    line-height: 60px;
    min-height: 60px;
}



.ImgPreloadVideo{
    /* position: absolute; */
    height: 58%;

    display: block;
    left: 0;
    width: 100%;
}

.ImgPreloadVideoDiv{
    width: 100%;
    position: absolute;
}

@media all and (max-width: 1240px){
    .MainPage .Slogan{
        min-width:885px;
        font-size: 55px;
    }

    .MainPage .Slogan .SloganPart1{
        padding-right: 150px;
        line-height: 60px;
        min-height: 60px;
    }
    .MainPage .Slogan .SloganPart2{
        padding-left: 50px;
        line-height: 60px;
        min-height: 60px;
    }
    
}

@media all and (max-width: 1050px){
    .MainPage .Slogan{
        font-size: 45px;
    }

    .MainPage .Slogan .SloganPart1,
    .MainPage .Slogan .SloganPart2 {
        line-height: 55px;
        min-height: 55px;
    }

    .MainPage .Slogan .SloganPart1{
        padding-right: 150px;
    }
    .MainPage .Slogan .SloganPart2{
        padding-left: 50px;
    }
}

@media all and (max-width: 950px){
    .MainPage .Slogan{
        min-width:655px;
        font-size: 35px;
    }
}


@media all and (max-width: 790px){

    .ValuesBody{
        flex-direction: column;
    } 

    .ValuesBody >div{
        width: 100%;
        text-align: center;
    }

    .MainPage .Slogan{
        font-size: 25px;
    }

    .MainPageVideoDiv {
        height: 62vw;
    }

    h1 {
        font-size: 25px;
    }

    .ImgPreloadVideo{
        /* max-height: 410px; */
    }

    .PlayerDiv .Location{
        font-size: 16px;
        bottom: 1px;
    }

}

@media all and (max-width: 775px){
    .MainPage .Slogan{
        background-color: rgb(0, 0, 0, 30%);
        font-family:   sans-serif, 'fantasy';
    }
    
    .PlayerDiv{
        /* min-height: calc((100vw - 17px) * 0.6929); */
    }

    .MainPage .Slogan{
        font-size: 35px;
    }

    .MainPage .Slogan .SloganPart1{
        line-height: 75px;
        min-height: 75px;
    }
    .MainPage .Slogan .SloganPart2{
        line-height: 75px;
        min-height: 75px;
        padding-left: 160px;
    }
   
}



@media all and (max-width: 700px){
    .MainPage .Slogan{
        min-width:483px;
        font-size: 30px;
    }

    .MainPage .Slogan .SloganPart1{
        padding-right: 70px;
        line-height: 65px;
        min-height: 65px;
    }
    .MainPage .Slogan .SloganPart2{
        padding-left: 130px;
        line-height: 65px;
        min-height: 65px;
    }
   
}

@media all and (max-width:590px){
    .PlayerDiv .Location{
        bottom: 0px;
    }
}

@media all and (max-width:500px){
    .PlayerDiv .Location{
        bottom: 0px;
    }

    .MainPage .Slogan{
        font-size: 26px;
    }


    .MainPage .Slogan .SloganPart1{
       line-height: 55px;
       min-height: 55px;
    }
    .MainPage .Slogan .SloganPart2{
        line-height: 55px;
        padding-left: 120px;
        min-height: 55px;
    }
}


@media all and (max-width: 450px){
    .MainPage .Slogan{
        font-size: 26px;
    }

    .MainPage .Slogan .SloganPart1{
        line-height: 55px;
        min-height: 55px;
        padding-right: 60px;
     }
     .MainPage .Slogan .SloganPart2{
         line-height: 55px;
         padding-left: 35px;
         min-height: 55px;
     }
   
}


@media all and (max-width: 400px){
    .MainPage .Slogan{
        font-size: 24px;
    }

    .MainPage .Slogan .SloganPart1{
        line-height: 45px;
        min-height: 45px;
        padding-right: 120px;
     }
     .MainPage .Slogan .SloganPart2{
         line-height: 45px;
         padding-left: 0px;
         padding-right: 20px;
         min-height: 45px;
     }
   
}



/*  ES AND PT VERISON FOR SLOGAN */

.MainPage .Slogan_es,
.MainPage .Slogan_pt{
    font-size: 55px;
}

.MainPage .Slogan_es .SloganPart1,
    .MainPage .Slogan_pt .SloganPart1{
        padding-right: 150px;
        line-height: 80px;
    }

    .MainPage .Slogan_es .SloganPart2,
    .MainPage .Slogan_pt .SloganPart2{
        padding-left: 120px;
        line-height: 80px;
    }


@media all and (max-width: 1240px){
    .MainPage .Slogan_es,
    .MainPage .Slogan_pt{
        min-width:885px;
        font-size: 45px;
    }
    
    .MainPage .Slogan_es .SloganPart1,
    .MainPage .Slogan_pt .SloganPart1{
        padding-right: 150px;
        line-height: 80px;
        min-height: 80px;
    }

    .MainPage .Slogan_es .SloganPart2,
    .MainPage .Slogan_pt .SloganPart2{
        padding-left: 230px;
        line-height: 80px;
        min-height: 80px;
    }
    
}

@media all and (max-width: 1050px){
    .MainPage .Slogan_es,
    .MainPage .Slogan_pt{
        font-size: 40px;
    }

    .MainPage .Slogan_es .SloganPart1,
    .MainPage .Slogan_pt .SloganPart1{
        padding-right: 150px;
        line-height: 65px;
        min-height: 65px;
    }

    .MainPage .Slogan_es .SloganPart2,
    .MainPage .Slogan_pt .SloganPart2{
        padding-left: 180px;
        line-height: 65px;
        min-height: 65px;
    }
}

@media all and (max-width: 950px){
    .MainPage .Slogan_es,
    .MainPage .Slogan_pt{
        min-width:655px;
        font-size: 30px;
    }

    .MainPage .Slogan_es .SloganPart1,
    .MainPage .Slogan_pt .SloganPart1{
        line-height: 55px;
        min-height: 55px;
    }

    .MainPage .Slogan_es .SloganPart2,
    .MainPage .Slogan_pt .SloganPart2{
        line-height: 55px;
        padding-left: 180px;
        min-height: 55px;
    }
}


@media all and (max-width: 790px){

    .MainPage .Slogan_es,
    .MainPage .Slogan_pt{
        font-size: 25px;
    }

}

@media all and (max-width: 775px){
    .MainPage .Slogan_es,
    .MainPage .Slogan_pt{
        background-color: rgb(0, 0, 0, 30%);
        font-family:   sans-serif, 'fantasy';
    }
    

    .MainPage .Slogan_es,
    .MainPage .Slogan_pt{
        font-size: 25px;
    }

    .MainPage .Slogan_es .SloganPart1,
    .MainPage .Slogan_pt .SloganPart1{
        line-height: 55px;
        min-height: 55px;
    }

    .MainPage .Slogan_es .SloganPart2,
    .MainPage .Slogan_pt .SloganPart2{
        line-height: 55px;
        padding-left: 180px;
        min-height: 55px;
    }
   
}



@media all and (max-width: 700px){
    .MainPage .Slogan_es,
    .MainPage .Slogan_pt{
        min-width:483px;
        font-size: 20px;
    }

    .MainPage .Slogan_es .SloganPart1,
    .MainPage .Slogan_pt .SloganPart1{
        padding-right: 70px;
        line-height: 45px;
        min-height: 45px;
    }

    .MainPage .Slogan_es .SloganPart2,
    .MainPage .Slogan_pt .SloganPart2{
        padding-left: 150px;
        line-height: 45px;
        min-height: 45px;
    }
   
}

@media all and (max-width: 600px){
    .MainPage .Slogan_es,
    .MainPage .Slogan_pt{
        min-width:483px;
        font-size: 20px;
    }

    .MainPage .Slogan_es .SloganPart1,
    .MainPage .Slogan_pt .SloganPart1{
        padding-right: 70px;
        line-height: 45px;
        min-height: 45px;
    }

    .MainPage .Slogan_es .SloganPart2,
    .MainPage .Slogan_pt .SloganPart2{
        padding-left: 130px;
        line-height: 45px;
        min-height: 45px;
    }
   
}

@media all and (max-width: 550px){
    .MainPage .Slogan_es,
    .MainPage .Slogan_pt{
        min-width:483px;
        font-size: 17px;
    }

    .MainPage .Slogan_es .SloganPart1,
    .MainPage .Slogan_pt .SloganPart1{
        padding-right: 70px;
        line-height: 45px;
        min-height: 45px;
    }

    .MainPage .Slogan_es .SloganPart2,
    .MainPage .Slogan_pt .SloganPart2{
        padding-left: 130px;
        line-height: 45px;
        min-height: 45px;
    }
   
}



@media all and (max-width:500px){

    .MainPage .Slogan_es,
    .MainPage .Slogan_pt{
        font-size: 17px;
    }


    .MainPage .Slogan_es .SloganPart1,
    .MainPage .Slogan_pt .SloganPart1{
       line-height: 35px;
       min-height: 35px;
    }

    .MainPage .Slogan_es .SloganPart2,
    .MainPage .Slogan_pt .SloganPart2{
        line-height: 35px;
        padding-left: 90px;
        min-height: 35px;
    }
}


@media all and (max-width: 450px){
    .MainPage .Slogan_es,
    .MainPage .Slogan_pt{
        font-size: 17px;
    }

    .MainPage .Slogan_es .SloganPart1,
    .MainPage .Slogan_pt .SloganPart1{
        line-height: 35px;
        min-height: 35px;
        padding-right: 60px;
     }

     .MainPage .Slogan_es .SloganPart2,
     .MainPage .Slogan_pt .SloganPart2{
         line-height: 35px;
         padding-left: 10px;
         min-height: 35px;
     }
   
}

@media all and (max-width: 430px){
    .MainPage .Slogan_es,
    .MainPage .Slogan_pt{
        font-size: 17px;
    }

    .MainPage .Slogan_es .SloganPart1,
    .MainPage .Slogan_pt .SloganPart1{
        line-height: 35px;
        min-height: 35px;
        padding-right: 80px;
     }

     .MainPage .Slogan_es .SloganPart2,
     .MainPage .Slogan_pt .SloganPart2{
         line-height: 35px;
         padding-left: 10px;
         padding-right: 0px;
         min-height: 35px;
     }
   
}


@media all and (max-width: 400px){

    .MainPage .Slogan_es,
    .MainPage .Slogan_pt{
        font-size: 14px;
    }

    .MainPage .Slogan_es .SloganPart1,
    .MainPage .Slogan_pt .SloganPart1{
        line-height: 35px;
        min-height: 35px;
        padding-right: 120px;
     }

     .MainPage .Slogan_es .SloganPart2,
     .MainPage .Slogan_pt .SloganPart2{
         line-height: 35px;
         padding-left: 0px;
         padding-right: 40px;
         min-height: 35px;
     }
   
}


@media all and (max-width: 350px){

    .MainPage .Slogan_es,
    .MainPage .Slogan_pt{
        font-size: 13px;
    }

    .MainPage .Slogan_es .SloganPart1,
    .MainPage .Slogan_pt .SloganPart1{
        line-height: 35px;
        min-height: 35px;
        padding-right: 120px;
     }

     .MainPage .Slogan_es .SloganPart2,
     .MainPage .Slogan_pt .SloganPart2{
         line-height: 35px;
         padding-left: 0px;
         padding-right: 70px;
         min-height: 35px;
     }
   
}




