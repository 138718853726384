.SelectionProjectOfferPage {
  overflow-y: scroll;
}
@media all and (max-width: 1000px) {
  .SelectionProjectOfferPage .VertLine {
    display: none;
  }
}
.SelectionProjectOfferPage .TitieAndDisc {
  color: black;
  font-size: 20px;
  margin: auto 0;
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: space-between;
}
.SelectionProjectOfferPage .TitieAndDisc .Logo {
  max-height: 80px;
}
.SelectionProjectOfferPage .TitieAndDisc h2 {
  margin: 0;
}
@media all and (max-width: 1000px) {
  .SelectionProjectOfferPage .TitieAndDisc h2 {
    display: none;
  }
}
.SelectionProjectOfferPage .PageBody {
  padding: 0 30px;
}
.SelectionProjectOfferPage .PageBody .GridMain {
  padding-top: 0;
}
.SelectionProjectOfferPage .OfferBody {
  overflow-wrap: anywhere;
  background-color: white;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);
  margin: 20px 0;
  color: #5a5a5a;
  font-size: 16px;
  text-align: left;
  padding: 60px;
  line-height: 25px;
}
.SelectionProjectOfferPage .OfferBody .HOne {
  margin-bottom: 20px;
}
.SelectionProjectOfferPage .OfferBody .HOne h2 {
  margin: 0;
  line-height: 45px;
}
.SelectionProjectOfferPage .slider {
  height: auto;
  overflow: hidden;
  max-height: 400px;
}
.SelectionProjectOfferPage .slider .ProjectSlider {
  height: auto;
}
.SelectionProjectOfferPage .slider .ProjectSlider .slick-slider {
  height: auto;
}
.SelectionProjectOfferPage .slider .ProjectSlider .slick-slider .ProjectSliderItem {
  height: auto;
}
.SelectionProjectOfferPage .slider .ProjectSlider .slick-slider .slick-prev,
.SelectionProjectOfferPage .slider .ProjectSlider .slick-slider .slick-next {
  top: 48%;
  z-index: 100;
}
.SelectionProjectOfferPage .slider .ProjectSlider .slick-slider .slick-prev {
  z-index: 100;
  left: 9px;
}
.SelectionProjectOfferPage .slider .ProjectSlider .slick-slider .slick-slide {
  cursor: pointer;
}
.SelectionProjectOfferPage .slider .ProjectSlider #ProjectSliderModal .slick-slider {
  height: auto;
}
.SelectionProjectOfferPage .slider .ProjectSlider #ProjectSliderModal .slick-slider .slick-prev,
.SelectionProjectOfferPage .slider .ProjectSlider #ProjectSliderModal .slick-slider .slick-next {
  top: 50%;
}
