.HOne {
    text-align: center;
    position: relative;
}

.HOne h2 {
    font-size: 40px;
    font-family: 'Red Hat Display', sans-serif;
    font-weight: 700;
    padding-bottom: 10px;
    margin-bottom: 0;

    /* text-transform: none!important; */
}

.HOne .PoweredByTop {
    position: absolute;
    top: 1px;
    right: 54px;
    font-size: 12px;
}




.HOne h2.small {
    font-size: 30px;
}

.HOne h2.xsmall {
    font-size: 24px;
}


.HOne h2.lagresize {
    -webkit-text-stroke-width: 1px;
    -webkit-text-stroke-color: rgba(0,0,0, .2);
    font-size: 85px;
    padding: 0;
    margin: 0;
}

.HOne h2.upper{
    text-transform: uppercase;

}
.HOne .Underline {
    font-size: 1px;
}


@media all and (max-width: 1000px){
    .HOne h2.lagresize {
        font-size: 55px;
    }

    .HOne .PoweredByTop {
        display: none;
    }

}


@media all and (max-width: 700px){
    .HOne h2.lagresize {
        font-size: 35px;
    }

    .HOne h2 {
        font-size: 30px;
        line-height: 30px;
    }

}