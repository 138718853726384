.OfferMain{
    width: 330px;
    height: 320px;
    /* border: 1px solid black; */
    margin:10px;
    background-color:white;
    box-shadow: 0 0 10px rgba(0,0,0,0.3 ); /* Параметры тени */
    cursor: pointer;
}

.OfferImg{
    width: 100%;
    height: 100%;
    min-width: 340px;
    /* min-height: 204px; */
    margin: auto auto;
}

.OfferMain .TopBlockOffer{
    position: relative;
    cursor: pointer;
    height: 204px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    overflow: hidden;
}

.OfferMain .Logo{
}

.OfferExpired{
    color: red;
    font-size: 30px;
    font-weight: bolder;
    position: absolute;
    width: 100%;
    margin: auto;
}

.OfferParent{
    color:black;
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: center;
}
.OfferParent .OfferSoldIcon img{
    width: 160px;
    left: 10px;
    top: 10px;
    position: absolute;
    z-index: 1000;
}

.OfferParent #OfferSoldIconVertical img{
    left: 60%;
    width: 90px;
    top: 0;
    position: absolute;
    z-index: 1000;
    width: 100px;
}

.OfferParent .LastCardTop{
    align-items: center;
    overflow: hidden;
}

.OfferParent .LastCardBottom{
    /* align-items: flex-end; */
    overflow: hidden;
    align-items:center;
}


.OfferParent .LastCardTop .Button{
    position: absolute;
    margin: auto;
} 

.OfferParent .LastCardBottom .Button{
    position: absolute;
    margin: auto;
} 



.BottomBlockOffer{
    /* margin: 0 15px 0 15px; */
    
    /* for new offer */
    display: flex;
    align-items: center;
}

.BottomBlockOffer .Info{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    border-bottom: 1px solid #E0E0E0;
    padding-bottom: 10px;
  
}

.BottomBlockOffer .StatusBlock{
    position: absolute;
    left: 10px;
    bottom: 10px;
    z-index: 100;
}

.BottomBlockOffer .StatusBlock > img {
    width: 250px;
}

.FindIn {
    font-size: 20px;
    font-weight: bold;
    margin-bottom: 20px;
}

.BottomBlockOffer .Info > div{
  
    padding: 5px;
    color:#707070;
   text-align: left;
   font-size: 14px;
   /* font-family: "Montserrat"; */
}


.BottomBlockOffer .Info > div > span{
  font-size: 14px;
  font-weight: bold;
  font-family: "Montserrat";
  color: black;
}

.BottomBlockOffer .Price {
    display: flex;
    justify-content: space-between;
    margin-top: 5px;
    padding-bottom: 5px;
    text-align: left;
    font-size: 14px;
    /* font-family: "Montserrat"; */
    color:#707070;
    border-bottom: 1px solid #E0E0E0;
}

.BottomBlockOffer .Price > span{
    font-size: 18px;
    font-weight: bold;
    /* font-family: "Montserrat"; */
    color: black;
}
.BottomBlockOffer .Button{
    background-color:white;
    border: 1px solid #D19F46;
    /* font-family: "Montserrat"; */
    border-radius:20px;
    font-size: 16px;
    font-weight: bold;
    color:#D19F46;
    width: 100%;
    padding-top: 10px;
    padding-bottom: 10px;
    cursor: pointer;
}

.OfferDiamond{
    width: 30px;
}

.BottomBlockOffer .Rate{
    margin: 10px 0 5px 0;
}

.BottomBlockOffer .Left{
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    width: 100%;
}

.BottomBlockOffer .Right{
}


.BottomBlockOffer .InfoRowItem{
    border-right: 1px solid #E0E0E0;
    border-bottom: 1px solid #E0E0E0;
    flex: 1 0 45%;
    font-size: 13px;
    height: 58px;
    text-align: center;
    display: flex;
    justify-content: center;
    flex-direction: column;
    color: #5A5A5A;
}

.BottomBlockOffer .InfoRowItem span{
    font-weight: bold;
    font-size: 15px;
    color: black;
}

.BottomBlockOffer .Right .DealScoreImg{
    width: 100px;
    margin: 10px;
}
/* 
.OfferIconSearch{
    width: 90px;
} */

.OfferCrossLine{
    border:1px solid red;
    width: 100%;
    height: 1px;
    position: absolute;
}

.BottomBlockOfferWizard {
    color:black;
    align-items: center;
}
.BottomBlockOfferWizard .ListingPrice {
    background-color: #FAFAFA;
    border-bottom: 1px solid #E0E0E0;
    padding: 15px;
    font-size: 20px;
    font-weight: bold;
}


.BottomBlockOfferWizard .BottomPart{
    display: flex;
}

.BottomBlockOfferWizard .BottomPart .Options {
    margin: 10px;
    padding: 10px;
    text-align: left;
    font-size: 14px;
    line-height: 17px;
    border-right: 1px solid #E0E0E0;
    min-width: 130px;
}

.BottomBlockOfferWizard .BottomPart .Options span {
    font-weight: bold;
}

.WizardThankBlock .OfferMain {
    height: auto;
}

.OfferLastItem{
    display: flex;
    flex-direction: column;
    justify-content: center;
    text-align: center;
    position: relative;
}

.OfferLastItem .SearchIcon{
    margin-bottom: 30px;
}

.OfferLastItem > a{
    padding: 10px 30px 10px 30px;
    margin: 0 45px 0 45px;
}

.OfferTitle{
   position: absolute;
   /* top:73%; */
   bottom:7%;
   left: 15px;
   color:white;
   font-weight: bold;
   font-size: 16px;
   text-align: left;
    z-index: 10;
}

.OfferOverlay{
    position: absolute;
    height: 100%;
    width: 100%;
    background:linear-gradient(#ffffff00,#ffffff00, #030303c7);
    /* background-color: #f3f3f3; */
    /* bottom:4px; */
}

.OfferTitle img{
    width: 20px;
    margin-right: 5px;
    margin-left: -4px;
}

.OfferLocation{
    font-size: 14px;
    font-weight: normal;
    display: flex;
    align-items: center;
}

.SmartDealIcon{
    position: absolute;
    width: 70px;
    padding: 5px;
    right: 0;
    top: 0;
    z-index: 40;
}

#OfferFindMyDeal{
    font-family: "gravesend-sans";
}

.OfferMainVertical{
    position: relative;
    display: flex;
    justify-content: stretch;
    /* border: 1px solid black; */
    margin:0 0 10px 0px;
    background-color:white;
    box-shadow: 0 0 10px rgba(0,0,0,0.3 ); /* Параметры тени */
}

.OfferMainVertical .StatusBlock{
    position: absolute;
    left: 50%;
    bottom: -10px;
    z-index: 100;
}

.OfferMainVertical  .StatusBlock > img {
    width: 100px;
}
.FeedbackOverlay{
    /* position: absolute;
    top: 0;
    right: 0;
    z-index: 1000;
    background-color:rgba(255,255,255,0.79 );
    border-radius: 0 0 0 10px;
    padding: 6px; */
}

.LeftBlockOfferVertical{
    float: left;
}
.OfferImgVertical{
    height: 100px;
    width: 160px;
    display: block;
}
.RightBlockOffer{
    width: 100%;
    padding: 0 10px;
    display: flex;
    flex-direction: column;
    align-content: stretch;
    justify-content: center;
}

.RightBlockOffer .TitleBlock{
    border-bottom: 1px solid #E0E0E0;
    display: flex;
    align-items:center;
    justify-content: space-between;
    position: relative;
    padding: 0 5px 10px 5px;
}


.RightBlockOffer .TitleBlock .Feedback{
    flex: 1;
}

.RightBlockOffer .TitleBlock .Feedback .FeedbackBlock{
    position: relative;
}

.RightBlockOffer .TitleBlock .Title{
}

.OfferLocationIcon{
    width: 20px;
    margin-right: 5px;
    margin-left: -4px;
}

.RightBlockOffer .TitleBlock .Button{
    background-color:inherit;
    color:#D19F46;
    font-weight: bold;
    padding: 5px 15px;
    border:1px solid #D19F46;
    border-radius: 20px;
    float: right;
    cursor: pointer;
}

.RightBlockOffer .InfoBlock {
    display: flex;
    padding: 0;
    margin-top: 7px;
    align-content: stretch;
    align-items: stretch;
}

.InfoBlock .MainInfo {
    display: flex;
    align-content: stretch;
    align-items: stretch;
}

.InfoBlock .InfoPart {
    margin-right: 20px;
    white-space: nowrap;
}

.InfoBlock span{
    font-weight:bold;
    margin-right: 5px;
}


.InfoBlock #PriceBlock{
    text-align: right;
    flex: 1;
}

#SmartDealIconVertical{
    left: -4%;
    top: -13%;
}

.InfoBlockPrice {
    min-width: 90px;
    background-color: #FAFAFA;
    display: flex;
    align-items: center;
    align-content: center;
    padding: 0 46px;
    font-weight: 600;
    border-left: 1px solid #E0E0E0;
    border-right: 1px solid #E0E0E0;
}

.OfferLastItem .PoweredBy{
    font-size: 10px;
    text-align: right;
    padding: 0 10px 8px 0;
    position: absolute;
    bottom: 0;
    right: 0;
}

.OfferTitleBlock{
    text-align: left;
    
}

.OfferTitleBlock .OfferLocation{
    margin-left: 20px;
}

.OfferTitleBlock .Title{
    display: flex;
    font-size: 14px;
    font-weight: normal;
    align-items: center;
}

.SelectedOffer{
    box-shadow:  0px 0px 15px 0px rgb(0 0 0 / 70%);
}
@media all and (max-width: 1200px){

    .OfferMainVertical{
        margin:0 0 10px 0;
    }


    .InfoBlock .InfoPart {
        margin: 3px 10px;
    }
}

@media all and (max-width: 1000px){

    .OfferParent .OfferSoldIcon img{
        width: 160px;
        position: absolute;
        z-index: 1000;
    }

    
.OfferParent #OfferSoldIconVertical img{
    left:10px;
    top: 10px;
    width: 160px;
}
}

