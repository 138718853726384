@lineMargin: 20px 140px;
@lineBackground:#FAFAFA;

.CompanyProjectMobile {
    font-family: "Montserrat", sans-serif;
    font-weight: bold;
    max-width: 1920px;
    position: relative;
    .Form{
        .Button{
            width: 100%;
        }
      .SecureDiv{
            .Text{
            font-weight: normal;
            }
      }
    }
    
    .Description{
        padding: 15px 0;
        font-weight: normal;
        text-align: center;
    }

    .TopPart {
        .TopSlider {
            width: 100%;
            max-width: 1700px;
            margin: auto;
            .TopHeader {
                position: absolute;
                z-index: 100;
                left: 16%;
                top: 17%;
            }

            .TopHeaderMobile{
                position: absolute;
                display: flex;
                width: 100%;
                height: 100%;
                flex-direction: column;
                justify-content: space-between;
                .Slogan{
                    .HOne{
                        z-index: 100;
                    }

                    margin-top: 40px;
                    @media all and (max-width:650px) {
                        width: 350px;
                        align-self: center;
                    }
                }
                .Buttons{

                    width: 100%;
                    max-width:500px;
                    align-self: center;
                    margin-bottom: 40px;
                    display: flex;
                    justify-content:space-evenly;
                    .ContactWAButton{
                        flex: 1;
                        z-index: 100;
                        display: flex;
                        justify-content: center;
                        align-items:center;
                        max-width: 190px;
                        padding: 5px 14px;
                        font-size: 20px;
                        font-family: Montserrat, Arial, sans-serif;
                        font-weight: bold;
                        margin: 0 5px;
                        .ContactWA{
                            width: 340px;
                        }
                    }
                    .Button{
                        z-index: 100;
                        display: flex;
                        justify-content: center;
                        align-items:center;
                        width:190px;
                        padding:5px 0;

                        .Icon{
                            width:20px;
                        }
                        
                        @media all and(max-width:500px) {
                            width:150px;
                        }
                    }
                }

                @media all and (max-width:850px) {
                    .HOne{
                        h2{
                            font-size: 30px;
                        }
                    }
                }

                @media all and (max-width:650px) {
                    .HOne{
                        h2{
                            font-size: 30px;
                        }
                    }
                }
            }

            .Item{
                // height: 832px;
            }
            .slick-slider{
                .slick-list{
                    .slick-track{
                        .slick-slide{
                            > div{
                                display: flex;
                            }
                        }
                        
                    }
                }

                .slick-dots{
                    .slick-active{
                        .CustomDot{
                            border:1px solid white;
                            background-color:#D19F46;
                        }
                    }
                    .CustomDot{
                        width: 15px;
                        height: 15px;
                        border-radius: 100%;
                        background-color:white;
                        margin:-65px 0;
                    }
                }
            }
            

            @media all and (max-width:1200px) {
                .TopHeader {
                    z-index: 100;
                
                    .HOne{
                        h2{
                         font-size: 30px;   
                        }
                    }    
                }
            }
        }

        @media all and (max-width: 1000px) {
            flex-direction: column;
            align-items:center;
        }
    }
}

.ProjectCompanyMobileSlider {

    .ItemBox {
        overflow: hidden;
        display: flex;
        justify-content: center;
        align-items: center;
        background-color: rgb(241, 240, 240);
        .Item {
            //min-width: 100%;
            min-height: 100%;
            flex-shrink: 0; 
            // max-width: 1920px;
            height  : 100%;
            margin: auto;
            overflow: hidden;
        }
    }
}