.UserCardsList {
  display: flex;
  flex-direction: row;
  justify-content: center;
  flex-flow: row wrap;
  max-width: 1100px;
  margin: auto;
  align-items: flex-start;
}
.UserCardsList .UserCardButton {
  min-width: 940px;
  display: flex;
  align-items: flex-end;
  justify-content: flex-end;
}
.UserCardsList .UserCardButton .Button {
  padding: 5px 24px;
}
@media all and (max-width: 1020px) {
  .UserCardsList .UserCardButton {
    min-width: 620px;
  }
}
@media all and (max-width: 700px) {
  .UserCardsList .UserCardButton {
    min-width: 330px;
    padding: 0 15px;
    justify-content: center;
  }
}
