.NeighborhoodsGridMain{
    top: 0;
    text-align: center;
    padding-top: 40px;
    padding-bottom: 40px;
}

.NeighborhoodsGrid{
    display: flex;
    align-items:center;
    flex-direction: row;
    justify-content: center;
    /* flex: 0 0 25%; */
    /*justify-content: space-between;*/
    /*align-content: flex-start;*/
    flex-flow: row wrap;
    margin: 0 auto;
    max-width: 1520px;
}