.ArticlesPage {
  margin-top: 83px;
  padding-bottom: 30px;
}
.ArticlesPage .GridMain .Grid {
  max-width: 1400px;
}
.ArticlesPage .OfferMain {
  width: 350px;
  height: 520px;
  display: flex;
  flex-direction: column;
}
.ArticlesPage .OfferMain .OfferImg {
  width: 380px;
  height: auto;
}
.ArticlesPage .OfferMain .BottomBlockOfferProject {
  display: flex;
  flex-direction: column;
  flex: 1;
}
.ArticlesPage .OfferMain .BottomBlockOfferProject .ReadMore {
  flex: 1;
  display: flex;
  align-items: flex-end;
  justify-content: center;
}
.ArticlesPage .OfferMain .BottomBlockOfferProject .ReadMore .Button {
  width: 100%;
}
.ArticlesPage .TopBlockOffer {
  position: relative;
}
.ArticlesPage .ProjectLogo {
  position: absolute;
  z-index: 100;
  max-height: 50px;
  padding: 20px;
  top: 0;
  left: 0;
  right: 0;
  display: flex;
  align-items: center;
  justify-content: space-around;
  background-color: white;
}
.ArticlesPage .ProjectLogo .ProjectLogoImg {
  max-width: 330px;
  max-height: 80px;
}
.MoreArticles .OfferMain .BottomBlockOfferProject {
  display: flex;
  flex-direction: column;
  flex: 1;
}
.MoreArticles .OfferMain .BottomBlockOfferProject .ReadMore {
  flex: 1;
  display: flex;
  align-items: flex-end;
  justify-content: center;
}
.MoreArticles .OfferMain .ReadMore .Button {
  width: 100%;
}
.MoreArticles .TopBlockOffer {
  position: relative;
}
.MoreArticles .ProjectLogo {
  position: absolute;
  z-index: 100;
  max-height: 50px;
  padding: 20px;
  top: 0;
  left: 0;
  right: 0;
  display: flex;
  align-items: center;
  justify-content: space-around;
  background-color: white;
}
.MoreArticles .ProjectLogo .ProjectLogoImg {
  max-height: 80px;
  max-width: 330px;
}
@media all and (max-width: 600px) {
  .MoreArticles .OfferMain,
  .SimilarProjects .OfferMain {
    width: 330px;
  }
}
