.sign-agreement-page {
  min-height: 100vh;
  background-color: gainsboro;
  color: white;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.sign-agreement-page .title {
  padding-left: 50px;
  background-color: #121212;
  display: flex;
  justify-content: left;
  align-items: center;
  height: 100px;
  font-weight: bold;
  font-size: 22px;
}
.sign-agreement-page .title .logo {
  padding: 15px;
  max-height: 70px;
  margin-right: 20px;
}
.sign-agreement-page .title span {
  margin: 0 20px;
}
@media all and (max-width: 800px) {
  .sign-agreement-page .title {
    justify-content: center;
  }
  .sign-agreement-page .title p {
    display: none;
  }
  .sign-agreement-page .title .logo {
    max-height: 70px;
    margin: 10px;
  }
}
.sign-agreement-page .agreement {
  background-color: white;
  border: 2px solid gray;
  color: black;
  margin: 30px auto;
  padding: 10px;
  font-size: 15px;
  border-radius: 5px;
}
.sign-agreement-page .actions {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
}
.sign-agreement-page .actions .sign_block {
  background-color: white;
  padding: 0;
  border-radius: 5px;
  border: 2px solid #c9c9c9;
}
.sign-agreement-page .actions .sign_block .tabs {
  display: flex;
  padding: 5px 5px 0 5px;
  border-bottom: 1px solid #a5a5a5;
}
.sign-agreement-page .actions .sign_block .tabs .nav_tab {
  margin: 0 10px;
  padding: 10px;
  cursor: pointer;
}
.sign-agreement-page .actions .sign_block .tabs .nav_tab:hover {
  font-weight: bold;
}
.sign-agreement-page .actions .sign_block .tabs #nav_tab_active {
  border-bottom: 2px solid #1f1f95;
}
.sign-agreement-page .actions .sign_block .sign {
  padding: 15px;
}
.sign-agreement-page .actions .sign_block .sign .buttons {
  display: flex;
  justify-content: flex-end;
}
.sign-agreement-page .actions .sign_block .sign .buttons .Button {
  margin: 0 10px;
  padding: 2px 15px;
  border-radius: 6px;
  width: 80px;
}
.sign-agreement-page .actions .sign_block .sign .buttons .button_sign {
  background-color: inherit;
  color: #5f5f5f;
  font-weight: normal;
  border-color: #D19F46;
}
.sign-agreement-page .actions .sign_block .Input {
  width: 370px;
  background-color: white;
  margin-bottom: 10px;
  border-color: #D19F46;
}
.sign-agreement-page .actions .sign_block canvas {
  border: 1px solid #D19F46;
  background-color: #ededed;
  border-radius: 5px;
  width: 370px;
  height: 150px;
  margin-bottom: 10px;
}
@media all and (max-width: 800px) {
  .sign-agreement-page .actions .sign_block {
    flex-direction: column;
  }
  .sign-agreement-page .actions .sign_block .Button {
    margin: 5px 0;
    padding: 2px 15px;
  }
}
@media all and (max-width: 500px) {
  .sign-agreement-page .actions .sign_block .Input {
    width: 250px;
    background-color: white;
    margin-bottom: 10px;
    border-color: #D19F46;
  }
  .sign-agreement-page .actions .sign_block canvas {
    border: 1px solid #D19F46;
    background-color: #ededed;
    border-radius: 5px;
    width: 250px;
    height: 100px;
    margin-bottom: 10px;
  }
}
.sign-agreement-page .presented {
  background-color: #121212;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 30px;
}
.sign-agreement-page .presented .logo {
  margin-left: 30px;
  width: 300px;
}
@media all and (max-width: 800px) {
  .sign-agreement-page .presented {
    flex-direction: column;
  }
  .sign-agreement-page .presented .logo {
    margin: 0;
    padding-top: 10px;
    width: 200px;
  }
}
