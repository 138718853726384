.customCampaigns {
  font-family: Montserrat, sans-serif;
  width: 100%;
  background-color: #FAFAFA;
}
.customCampaigns .header {
  background-color: white;
  padding: 93px 0 30px 0;
  width: 100%;
  max-width: 1512px;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  align-items: center;
}
@media all and (max-width: 655px) {
  .customCampaigns .header {
    padding: 30px 0 30px 0;
  }
}
.customCampaigns .header .investLogo {
  margin: 0 0 22px 0;
}
@media all and (max-width: 918px) {
  .customCampaigns .header .investLogo {
    width: 600px;
    height: auto;
  }
}
@media all and (max-width: 655px) {
  .customCampaigns .header .investLogo {
    width: 330px;
    height: auto;
  }
}
.customCampaigns .header .smartLuxeLogo {
  display: flex;
  flex-direction: row;
  align-items: center;
  font-size: 21px;
  font-weight: 400;
}
@media all and (max-width: 655px) {
  .customCampaigns .header .smartLuxeLogo {
    font-size: 18px;
  }
}
.customCampaigns .header .smartLuxeLogo img {
  margin-left: 10px;
}
@media all and (max-width: 655px) {
  .customCampaigns .header .smartLuxeLogo img {
    width: 170px;
    height: auto;
  }
}
.customCampaigns .separator {
  background-image: url('../../elements/images/invest/sep.svg');
  height: 19px;
  width: 100%;
  max-width: 1512px;
  margin: 0 auto;
}
.customCampaigns .contactFormBlock {
  position: relative;
  overflow: visible;
  z-index: 2;
  width: 100%;
  max-width: 1512px;
  height: 649px;
  background-image: url('../../elements/images/invest/form-1-bgr.jpg');
  background-repeat: no-repeat;
  background-size: cover;
  margin: 0 auto;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
}
@media all and (max-width: 1000px) {
  .customCampaigns .contactFormBlock {
    align-self: center;
    justify-content: center;
    height: 550px;
  }
}
.customCampaigns .contactFormBlock .contactForm {
  align-self: flex-start;
  margin: 65px 152px 0 0;
  padding: 44px 60px 39px 60px;
  display: flex;
  flex-direction: column;
  max-width: 492px;
  background-color: rgba(255, 255, 255, 0.75);
}
.customCampaigns .contactFormBlock .contactForm .contactFormHeader {
  font-size: 23px;
  font-weight: 400;
  color: black;
  max-width: 400px;
  margin-bottom: 11px;
}
.customCampaigns .contactFormBlock .contactForm input,
.customCampaigns .contactFormBlock .contactForm textarea {
  background: white;
  border: 1px solid #979797;
  padding: 9px;
  font-size: 20px;
  margin-bottom: 19px;
}
.customCampaigns .contactFormBlock .contactForm input::placeholder,
.customCampaigns .contactFormBlock .contactForm textarea::placeholder {
  color: #C8C8C8;
}
.customCampaigns .contactFormBlock .contactForm .requestButton {
  cursor: pointer;
  background-color: #000000;
  padding: 14px;
  color: white;
  text-align: center;
  font-weight: 700;
  font-size: 19px;
}
@media all and (max-width: 1000px) {
  .customCampaigns .contactFormBlock .contactForm {
    margin: 0;
    align-self: center;
    justify-content: center;
  }
}
@media all and (max-width: 700px) {
  .customCampaigns .contactFormBlock .contactForm {
    min-width: auto;
    flex: 1;
    max-width: none;
    padding: 20px 30px 20px 30px;
    margin: 0 20px;
  }
  .customCampaigns .contactFormBlock .contactForm .contactFormHeader {
    font-size: 20px;
  }
}
.customCampaigns .contactFormBlock .approved {
  z-index: 10;
  position: absolute;
  left: 136px;
  bottom: 0;
  margin-bottom: -146px;
}
@media all and (max-width: 1130px) {
  .customCampaigns .contactFormBlock .approved {
    left: 0;
    bottom: 0;
    margin-bottom: -90px;
    width: 170px;
    height: auto;
  }
}
.customCampaigns .timeToAct {
  width: 100%;
  max-width: 1512px;
  background-color: black;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
}
.customCampaigns .timeToAct .timeToActInt {
  flex: 1;
  padding: 55px 150px 130px 150px;
}
@media all and (max-width: 1200px) {
  .customCampaigns .timeToAct .timeToActInt {
    padding: 80px 30px 40px 30px;
  }
}
.customCampaigns .timeToAct .timeToActInt h2 {
  padding: 0;
  margin: 0;
  color: white;
  font-size: 48px;
  font-weight: 400;
  text-align: right;
}
@media all and (max-width: 1200px) {
  .customCampaigns .timeToAct .timeToActInt h2 {
    text-align: center;
    font-size: 34px;
  }
}
.customCampaigns .timeToAct .timeToActInt .listAndImage {
  margin-top: 70px;
  display: flex;
  flex-direction: row;
  gap: 20px;
}
@media all and (max-width: 1200px) {
  .customCampaigns .timeToAct .timeToActInt .listAndImage {
    flex-direction: column;
    margin-top: 30px;
  }
}
.customCampaigns .timeToAct .timeToActInt .listAndImage .list {
  font-size: 21px;
  color: white;
}
@media all and (max-width: 1200px) {
  .customCampaigns .timeToAct .timeToActInt .listAndImage .list {
    font-size: 18px;
  }
}
.customCampaigns .timeToAct .timeToActInt .listAndImage .list .item {
  margin-bottom: 30px;
  display: flex;
  flex-direction: row;
  gap: 12px;
  align-items: center;
}
.customCampaigns .timeToAct .timeToActInt .listAndImage .list .pointed {
  font-weight: 700;
}
.customCampaigns .featuredProjects {
  background-color: white;
  width: 100%;
  max-width: 1512px;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
}
.customCampaigns .featuredProjects .featuredProjectsInt {
  padding: 80px 195px 95px 195px;
  flex: 1;
}
@media all and (max-width: 1000px) {
  .customCampaigns .featuredProjects .featuredProjectsInt {
    padding: 60px 30px 20px 30px;
  }
}
.customCampaigns .featuredProjects .featuredProjectsInt h2 {
  margin: 0 0 75px 0;
  padding: 0;
  font-size: 48px;
  font-weight: 400;
  text-align: center;
}
@media all and (max-width: 1000px) {
  .customCampaigns .featuredProjects .featuredProjectsInt h2 {
    font-size: 34px;
  }
}
@media all and (max-width: 600px) {
  .customCampaigns .featuredProjects .featuredProjectsInt h2 {
    font-size: 26px;
  }
}
.customCampaigns .featuredProjects .featuredProjectsInt .projects {
  display: flex;
  flex-flow: row wrap;
  justify-content: space-evenly;
  cursor: pointer;
  gap: 20px;
}
.customCampaigns .featuredProjects .featuredProjectsInt .projects .project {
  margin-bottom: 30px;
  max-width: 300px;
  flex: 1 0 33%;
  display: flex;
  flex-direction: column;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.3);
}
.customCampaigns .featuredProjects .featuredProjectsInt .projects .project img {
  display: block;
  z-index: 5;
}
.customCampaigns .featuredProjects .featuredProjectsInt .projects .project .image {
  position: relative;
  z-index: 5;
}
.customCampaigns .featuredProjects .featuredProjectsInt .projects .project .name {
  font-size: 24px;
  font-weight: 500;
  color: black;
  text-align: center;
  padding: 24px 10px 14px 10px;
}
.customCampaigns .featuredProjects .featuredProjectsInt .projects .project .soldOut {
  z-index: 7;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.customCampaigns .featuredProjects .featuredProjectsInt .webLogos {
  margin-top: 80px;
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  align-items: center;
}
@media all and (max-width: 600px) {
  .customCampaigns .featuredProjects .featuredProjectsInt .webLogos img {
    max-width: 170px;
  }
}
@media all and (max-width: 600px) {
  .customCampaigns .featuredProjects .featuredProjectsInt .webLogos {
    margin-top: 30px;
    flex-direction: column;
    gap: 50px;
    margin-bottom: 20px;
  }
}
.customCampaigns .smartLuxe {
  width: 100%;
  max-width: 1512px;
  background-color: black;
  display: flex;
  flex-direction: column;
  padding: 95px 0 125px 0;
  align-items: center;
  font-weight: 400;
  font-size: 40px;
  color: white;
  gap: 65px;
  text-align: center;
  margin: 0 auto;
}
@media all and (max-width: 800px) {
  .customCampaigns .smartLuxe img {
    flex: 1;
    width: 90%;
  }
}
@media all and (max-width: 800px) {
  .customCampaigns .smartLuxe {
    font-size: 25px;
  }
}
.customCampaigns .formSecond {
  background-color: white;
  width: 100%;
  max-width: 1512px;
  margin: 0 auto;
  background-image: url('../../elements/images/invest/contact_form_2_bgr.jpg');
  background-repeat: no-repeat;
  background-size: cover;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: 125px 0;
}
@media all and (max-width: 700px) {
  .customCampaigns .formSecond {
    padding: 70px 0;
  }
}
.customCampaigns .formSecond .contactForm {
  margin: 0 20px;
  min-width: 492px;
  padding: 44px 60px 39px 60px;
  display: flex;
  flex-direction: column;
  max-width: 492px;
  background-color: rgba(255, 255, 255, 0.75);
}
.customCampaigns .formSecond .contactForm .contactFormHeader {
  font-size: 23px;
  font-weight: 400;
  color: black;
  max-width: 400px;
  margin-bottom: 11px;
}
.customCampaigns .formSecond .contactForm input,
.customCampaigns .formSecond .contactForm textarea {
  background: white;
  border: 1px solid #979797;
  padding: 9px;
  font-size: 20px;
  margin-bottom: 19px;
}
.customCampaigns .formSecond .contactForm input::placeholder,
.customCampaigns .formSecond .contactForm textarea::placeholder {
  color: #C8C8C8;
}
.customCampaigns .formSecond .contactForm .requestButton {
  cursor: pointer;
  background-color: #000000;
  padding: 14px;
  color: white;
  text-align: center;
  font-weight: 700;
  font-size: 19px;
}
@media all and (max-width: 700px) {
  .customCampaigns .formSecond .contactForm {
    min-width: auto;
    flex: 1;
    max-width: none;
    padding: 20px 30px 20px 30px;
  }
}
.customCampaigns .Footer {
  background-color: black;
  display: flex;
  flex-direction: column;
}
.customCampaigns .Footer .footerTop {
  padding: 40px 0 30px 0;
  flex: 1;
  max-width: 1432px;
  width: 100%;
  margin: 0 auto;
  display: flex;
  flex-direction: row;
  gap: 30px;
  justify-content: space-between;
  align-items: flex-start;
}
@media all and (max-width: 1200px) {
  .customCampaigns .Footer .footerTop {
    flex-direction: column;
    align-items: center;
  }
}
.customCampaigns .Footer .footerTop .logo {
  padding-left: 20px;
}
@media all and (max-width: 1200px) {
  .customCampaigns .Footer .footerTop .logo {
    padding-left: 0;
    width: 350px;
  }
}
.customCampaigns .Footer .footerTop .phone {
  color: white;
  font-size: 40px;
  align-self: flex-end;
}
@media all and (max-width: 1400px) {
  .customCampaigns .Footer .footerTop .phone {
    font-size: 26px;
  }
}
@media all and (max-width: 1200px) {
  .customCampaigns .Footer .footerTop .phone {
    align-self: center;
  }
}
.customCampaigns .Footer .footerTop .contacts {
  color: white;
  font-size: 18px;
  line-height: 28px;
  padding-right: 41px;
  position: relative;
}
@media all and (max-width: 1200px) {
  .customCampaigns .Footer .footerTop .contacts {
    text-align: center;
    padding-right: 0;
  }
}
@media all and (max-width: 700px) {
  .customCampaigns .Footer .footerTop .contacts {
    font-size: 14px;
    line-height: 20px;
  }
}
.customCampaigns .Footer .footerTop .contacts .vertline {
  position: absolute;
  right: 20px;
  top: 0;
  border: 1px solid white;
  height: 65px;
}
@media all and (max-width: 1200px) {
  .customCampaigns .Footer .footerTop .contacts .vertline {
    display: none;
  }
}
.customCampaigns .Footer .footerBottom {
  position: relative;
  padding: 40px 40px 30px 40px;
  flex: 1;
  max-width: 1432px;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  gap: 30px;
  justify-content: space-between;
  align-items: flex-start;
}
.customCampaigns .Footer .footerBottom .vertlineFooter {
  position: absolute;
  left: 0;
  bottom: 30px;
  border: 1px solid white;
  height: 80px;
}
.customCampaigns .Footer .footerBottom .disclaimer {
  color: #939393;
  font-size: 14px;
}
.customCampaigns .Footer .footerBottom .notes {
  color: #D9D9D9;
  font-size: 12px;
}
/*# sourceMappingURL=custom-langing-page-style.css.map */