.ShortRentalPage .Description {
  padding: 0 30px;
  font-size: 16px;
  line-height: 24px;
  padding-top: 25px;
}
.ShortRentalPage .Form {
  max-width: 660px;
  padding: 0 0 30px 0;
  margin: auto;
}
.ShortRentalPage .ShortTermIcon {
  position: absolute;
  right: 10px;
  top: 10px;
}
.ShortRentalPage .ShortTermIcon img {
  width: 120px;
}
@media all and (max-width: 1000px) {
  .ShortRentalPage .ShortTermIcon img {
    width: 90px;
  }
}
