.NotFound {
  font-size: 28px;
  font-weight: bold;
  text-align: center;
  display: block;
  padding: 20px;
}
@media screen and (max-width: 1000px) {
  .GridMain .Grid {
    width: auto;
  }
}
