.FeedbackBlock {
  text-align: right;
  flex: 1;
  position: absolute;
  top: 0;
  right: 0;
  z-index: 1000;
  padding: 6px;
}
.FeedbackBlock .FeedbackItem {
  margin: 0 5px;
  font-size: 26px;
  cursor: pointer;
}
.FeedBackOverlay {
  background-color: rgba(255, 255, 255, 0.79);
  border-radius: 0 0 0 10px;
}
.FeedbackModal .Form {
  margin-top: 20px;
}
.FeedbackModal .Form .Button {
  margin-top: 20px;
}
