/* .MyDealMain{
    background-color:white;
    position: fixed;
    z-index: 11000;
    top:30%;
    left:27%;
    padding: 40px;
    font-family: inherit;
    display:flex;
    width: 40%;
}



.MyDealMain:focus{
    opacity: 100%;
} */

.DealInput{
    font-family: inherit;
    padding: 8px!important;
}
.DealImg{
    width: 340px;
    height: 463px;
    padding-left: 60px;
    padding-right: 20px;
}

.ModalBody .DealBlocks {
    padding-top: 40px;
}

.ModalBody .HOne h2 {
    padding-top: 0;
    margin-top: 15px;
    font-size: 34px;
}

.DealTitle{
    margin: 0 0 20px 0;
}

.formLine {
    display: flex;
}

.DealSubmit{
    background-color:#D19F46;
    border: solid 1px #D19F46;
    border-radius: 30px;
    padding: 10px 20px 10px 20px;
    margin: 20px 45px 0 0px;
    font-weight: bolder;
    font-size: 17px;
    color: white;
    display: inline;
    cursor: pointer;
}

.DealBlocks {
    display: flex;
}

.CloseIcon{
    position: absolute;
    top:16px;
    cursor: pointer;
}

.DealLeftBlock{
    text-align: left;
}

.DealDesc{
    margin-bottom: 40px;
    margin-top: 40px;
    font-size: 16px;
    line-height: 23px;
}


@media all and (max-width: 800px){

    .DealRightBlock {
        display: flex;
    }


    .DealImg{
        display: none;
        /*padding: 40px 0 0 0;*/
        /*margin: 0 auto;*/
    }

    .DealBlocks {
        flex-direction: column;
    }
}